import '../../App.css';
import React, { useContext } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function ModalCadastrarUsuario({ closeModal }) {
    const { CadastrarUsuario, CadastroUsuario, setCadastroUsuario } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        if (event.target.name === "admin" || event.target.name === "supervisor" || event.target.name === "somentevisualizar") {
            setCadastroUsuario({ ...CadastroUsuario, [event.target.name]: event.target.checked })
        } else {
            setCadastroUsuario({ ...CadastroUsuario, [event.target.name]: event.target.value })
        }
    }

    const role = Cookies.get('Role')

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Cadastrar usuário</h1>
                    <div className="btn-close-modal" onClick={() => closeModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CadastrarUsuario}>
                    <div className="modal-content">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control onChange={handleChange} name="nome" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>E-mail</Form.Label>
                                    <Form.Control type="email" onChange={handleChange} name="email" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group className="mb-1">
                            <Form.Label>Função</Form.Label>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    {role == "admin" &&
                                        <Form.Check
                                            inline
                                            label="Admin"
                                            name="admin"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            onChange={handleChange}
                                        />
                                    }
                                    <Form.Check
                                        inline
                                        label="Supervisor"
                                        name="supervisor"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        onChange={handleChange}
                                    />
                                    <Form.Check
                                        inline
                                        label="Somente visualizar"
                                        name="somentevisualizar"
                                        type={type}
                                        id={`inline-${type}-3`}
                                        onChange={handleChange}
                                    />
                                </div>
                            ))}
                        </Form.Group>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => closeModal(false)}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCadastrarUsuario