import '../../App.css';
import React, { useContext, useState } from "react";
import { AuthContext } from '../../context/AuthContext';
import { IoIosWarning } from "react-icons/io";
import { FaTimes } from 'react-icons/fa';
import { Form, InputGroup } from 'react-bootstrap';
import { StyleContext } from '../../context/StyleContext';

function ModalResetarSenha() {

    const { ResetarSenha, setResetarSenhaModal, loading } = useContext(AuthContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [Email, setEmail] = useState('')

    const handleChange = (event) => {
        setEmail(event.target.value)
    }

    return (
        <>
            {loading ? (
                <div className="loading-screen" >
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                </div>
            ) : (
                <div className="modal-novo">
                    <div className="modal-interno-delete">
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '20px', padding: '16px' }} className='border-bottom'>
                            <div>
                                <IoIosWarning style={{ color: '#FACC15', marginRight: '5px' }} /> Esqueci minha senha
                            </div>
                            <button className='btn-fechar' onClick={() => setResetarSenhaModal(false)}>
                                <FaTimes />
                            </button>
                        </div>
                        <div style={{ padding: '16px' }} className='border-bottom'>
                            <div>
                                <h2 className="h2-modal">Insira o e-mail que será alterada a senha:</h2>
                                <Form.Group>
                                    <InputGroup>
                                        <Form.Control onChange={handleChange} name="email" />
                                    </InputGroup>
                                </Form.Group>
                            </div>
                        </div>
                        <div className="content-buttons">
                            <button type="submit" className="btn-dash btn-cancel" style={btnVer} onClick={() => setResetarSenhaModal(false)}>Cancelar</button>
                            <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => ResetarSenha(Email)}>Resetar senha</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default ModalResetarSenha