import '../../App.css'
import React, { useContext, useState } from 'react'
import ModalNovoCadastro from '../modal/ModalNovoCadastro';
import { FaPlus } from 'react-icons/fa'
import { StyleContext } from '../../context/StyleContext';

function BotaoNovoCadastro({ end }) {

    const [openModal, setopenModal] = useState(false)
    const { btnCriar } = useContext(StyleContext)

    return (
        <div>
            {openModal &&
                <ModalNovoCadastro closeModal={setopenModal} end={end} />
            }
            <button className='btn-dash btn-criar' style={btnCriar} onClick={setopenModal}><FaPlus style={{ marginRight: "10px" }} />Novo</button>
        </div>
    )
}

export default BotaoNovoCadastro