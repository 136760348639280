import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function ModalEditarPessoa() {
    const { EditarPessoa, EdicaoPessoa, setEdicaoPessoa, ListaPessoa, setModalEditarPessoa, Cargos, ListaCargo } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const [key, setKey] = useState(EdicaoPessoa?.cargo.key)

    const editar = () => {
        EditarPessoa(key)
            .then(() => {
                setModalEditarPessoa(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const handleChange = (event) => {
        setEdicaoPessoa({ ...EdicaoPessoa, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        ListaPessoa()
        ListaCargo()
    }, [])

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Editar pessoa</h1>
                    <div className="btn-close-modal" onClick={() => setModalEditarPessoa(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div>
                    <div className="modal-content">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control maxLength={100} defaultValue={EdicaoPessoa.nome} onChange={handleChange} name="nome" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={(e) => setKey(e.target.value)} name="cargo">
                                        <option value="0">Selecione</option>
                                        {Cargos && Cargos?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave} selected={item.chave == EdicaoPessoa?.cargo.key ? true : false}>
                                                    {item.descricao}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalEditarPessoa(false)}>Cancelar</button>
                        <button type='button' className="btn-dash btn-criar" style={btnCriar} onClick={() => editar()}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarPessoa