import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthContextProvider } from './context/AuthContext';
import { ProSidebarProvider } from 'react-pro-sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js'
import { ApiContextProvider } from './context/ApiContext';
import { registerLicense } from '@syncfusion/ej2-base'
import { StyleContextProvider } from './context/StyleContext';
import { AdminContextProvider } from './context/AdminContext';
import { DashboardContextProvider } from './context/DashboardContext';

if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.warn('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.warn(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this
        );
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  };
}

registerLicense(process.env.REACT_APP_SYNCFUSION_KEY)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <StyleContextProvider >
    <AdminContextProvider>
      <AuthContextProvider>
        <ApiContextProvider>
          <DashboardContextProvider>
            <ProSidebarProvider>
              <App />
            </ProSidebarProvider>
          </DashboardContextProvider>
        </ApiContextProvider>
      </AuthContextProvider>
    </AdminContextProvider>
  </StyleContextProvider >
);

