import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalCadastrarPlano({ closeModal }) {
    const { CadastrarPlanoNegocio, PlanoNegocio, setPlanoNegocio, ListaPlanoNegocio, PlanoNegocios, TipoPlanoNegocio, CadastrarPlanoNegocioAvaliacao, PlanoNegocioAvaliacao, setPlanoNegocioAvaliacao, Avaliacao } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [tipo, setTipo] = useState('')
    const [step, setStep] = useState("")

    const handleChange = (event) => {
        setPlanoNegocio({ ...PlanoNegocio, [event.target.name]: event.target.value })
    }

    const handleTipo = (event) => {
        setPlanoNegocio(prevState => ({
            ...prevState,
            [event.target.name]: event.target.value,
            valor: ""
        }));

        switch (event.target.value) {
            case "NUMERO":
                setTipo("number")
                setStep(1)
                break
            case "DATA":
                setTipo("date")
                setStep(1)
                break
            case "MOEDA":
                setTipo("number")
                setStep(".01")
                break
            case "LISTA":
                setTipo("lista")
                setStep(1)
                break
            case "TEXTO":
                setTipo("text")
                setStep(1)
                break
        }
    }

    useEffect(() => {
        ListaPlanoNegocio()
    }, [])

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Cadastrar Plano de Negócio</h1>
                    <div className="btn-close-modal" onClick={() => closeModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CadastrarPlanoNegocioAvaliacao}>
                    <div className='modal-content'>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Avaliação</Form.Label>
                                    <Form.Control maxLength={100} onChange={(e) => setPlanoNegocioAvaliacao(e.target.value)} value={PlanoNegocioAvaliacao} />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda" style={{ justifyContent: 'end' }}>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
                <form onSubmit={CadastrarPlanoNegocio}>
                    <div className="modal-content">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Indicador</Form.Label>
                                    <Form.Control maxLength={100} onChange={handleChange} name="indicador" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Tipo</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleTipo} name="tipo">
                                        <option value="0">Selecione</option>
                                        {TipoPlanoNegocio?.map((item, index) => {
                                            return (
                                                <option key={index} value={item}>{item}</option>
                                            )

                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col>
                                {tipo === "lista" ?
                                    <Form.Group className="mb-3">
                                        <Form.Label>Valor</Form.Label>
                                        <Form.Select aria-label="Default select example" value={PlanoNegocio.valor} onChange={handleChange} name="valor">
                                            <option value="">Selecione</option>
                                            <option value="Muito baixo">Muito baixo</option>
                                            <option value="Baixo">Baixo</option>
                                            <option value="Médio">Médio</option>
                                            <option value="Alto">Alto</option>
                                            <option value="Muito alto">Muito alto</option>
                                        </Form.Select>
                                    </Form.Group>
                                    :
                                    <Form.Group className="mb-3">
                                        <Form.Label>Valor</Form.Label>
                                        <Form.Control type={tipo} maxLength={100} step={step} value={PlanoNegocio.valor} onChange={handleChange} name="valor" />
                                    </Form.Group>
                                }
                            </Col>
                        </Row>
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label>Avaliação relacionada</Form.Label>
                                <Form.Select aria-label="Default select example" onChange={handleChange} name="avaliacaoKey">
                                    <option value="">Selecione</option>
                                    {Avaliacao?.map((item, index) => {
                                        return (
                                            <option key={index} value={item.chave}>{item.descricao}</option>
                                        )

                                    })}
                                </Form.Select>
                            </Form.Group>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => closeModal(false)}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCadastrarPlano