import '../../App.css';
import React, { useContext, useEffect } from 'react';
import { ApiContext } from "../../context/ApiContext";
import HeaderCompartilhar from '../../components/header/HeaderCompartilhar';
import Footer from '../../components/footer/Footer'
import moment from 'moment';
import { StyleContext } from '../../context/StyleContext';

function Compartilhar() {

    const { VerCompartilhada, DemCompartilhada } = useContext(ApiContext)
    const { loadingLayout } = useContext(StyleContext)


    useEffect(() => {
        const guide = window.location.search.slice(3)
        VerCompartilhada(guide)
    }, [])

    return (
        <>
            {loadingLayout ?
                <div className="loading-screen">
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                </div>
                :
                <>

                    <HeaderCompartilhar />
                    <div style={{ width: '50%', margin: 'auto', paddingTop: '100px' }} className='div-compartilhar'>
                        <h1 style={{ marginBottom: '20px', fontSize: '30px', textAlign: 'center' }}>{DemCompartilhada?.titulo}</h1>
                        <p><b>ID da Demanda:</b> {DemCompartilhada?.id}</p>
                        <p><b>Usuário:</b> {DemCompartilhada?.criacaoUsuarioNome}</p>
                        <hr style={{ borderTop: '1px dashed' }} />
                        <h2 style={{ fontSize: '22px', marginBottom: '15px' }}>Informações</h2>
                        <div style={{ display: 'flex' }}>
                            <p style={{ marginRight: '5px' }}><b>Descrição:</b></p>
                            <div dangerouslySetInnerHTML={{ __html: DemCompartilhada?.descricao }}></div>
                        </div>
                        <p><b>Fase:</b> {DemCompartilhada?.faseDescricao}</p>
                        <p><b>Gerência Solicitante:</b> {DemCompartilhada?.setorGerenciaDescricao}</p>
                        <p><b>Linha de Atendimento:</b> {DemCompartilhada?.linhaAtendimentoDescricao}</p>
                        <p><b>Solicitante:</b> {DemCompartilhada?.solicitante}</p>
                        <hr style={{ borderTop: '1px dashed' }} />
                        <h2 style={{ fontSize: '22px', marginBottom: '15px' }}>Parâmetros</h2>
                        <p><b>Expectativa de Entrega:</b> {DemCompartilhada?.expectativaEntrega ? moment(DemCompartilhada?.expectativaEntrega).format('lll') : 'Não informado'}</p>
                        <p><b>Data Limite:</b> {DemCompartilhada?.dataLimite ? moment(DemCompartilhada?.dataLimite).format('lll') : 'Não informado'}</p>
                        <p><b>Tipo:</b> {DemCompartilhada?.tipoDescricao ? DemCompartilhada?.tipoDescricao : 'Não informado'}</p>
                        <hr style={{ borderTop: '1px dashed' }} />
                        <h2 style={{ fontSize: '22px', marginBottom: '15px' }}>GUT/BSC</h2>
                        <p><b>Esforço Total:</b> {DemCompartilhada?.esforcoTotal ? DemCompartilhada?.esforcoTotal : 'Não informado'}</p>
                        <p><b>Diretriz Estratégica:</b> {DemCompartilhada?.diretrizEstrategicaDescricao ? DemCompartilhada?.diretrizEstrategicaDescricao : 'Não informado'}</p>
                        <p><b>GUT:</b> {DemCompartilhada?.guT_Pontuacao}</p>
                        <p><b>GUT/BSC:</b> {DemCompartilhada?.guT_BSC_Pontuacao}</p>
                        <hr style={{ borderTop: '1px dashed' }} />
                        <h2 style={{ fontSize: '22px', marginBottom: '15px' }}>Ciclos</h2>
                        <p><b>Ciclo:</b> {DemCompartilhada?.cicloDescricao ? DemCompartilhada?.cicloDescricao : 'Não informado'}</p>
                        <p><b>Ordem:</b> {DemCompartilhada?.cicloOrdem ? DemCompartilhada?.cicloOrdem : 'Não informado'}</p>
                        <hr style={{ borderTop: '1px dashed' }} />
                        <h2 style={{ fontSize: '22px', marginBottom: '15px' }}>Datas</h2>
                        <p><b>Data de Criação:</b> {DemCompartilhada?.criacaoDataHora ? moment(DemCompartilhada?.criacaoDataHora).format('lll') : 'Não informado'}</p>
                        <p><b>Data de Cancelamento:</b> {DemCompartilhada?.cancelamentoDataHora ? moment(DemCompartilhada?.cancelamentoDataHora).format('lll') : 'Não informado'}</p>
                        <p><b>Data de Conclusão:</b> {DemCompartilhada?.conclusaoDataHora ? moment(DemCompartilhada?.conclusaoDataHora).format('lll') : 'Não informado'}</p>
                    </div>
                    <Footer />
                </>
            }
        </>
    );
}

export default Compartilhar;