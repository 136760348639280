import '../../App.css';
import React, { useContext } from "react";
import { ApiContext } from '../../context/ApiContext';
import { Form } from 'react-bootstrap'
import { IoIosWarning } from "react-icons/io";
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalCancelarDemanda() {

    const { setCancelarDemandaModal, CancelarDemanda, MotivoCancelar, setMotivoCancelar } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setMotivoCancelar({ ...MotivoCancelar, [event.target.name]: event.target.value })
    }

    return (
        <div className="modal-novo">
            <div className="modal-interno-delete">
                <div className="modal-content-delete">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '20px', padding: '16px' }} className='border-bottom'>
                        <div>
                            <IoIosWarning style={{ color: '#FACC15', marginRight: '5px' }} /> Cancelar demanda
                        </div>
                        <button className='btn-fechar' onClick={() => setCancelarDemandaModal(false)}>
                            <FaTimes />
                        </button>
                    </div>
                    <div style={{ padding: '16px' }} className='border-bottom'>
                        Tem certeza que deseja cancelar essa demanda? Informe o motivo abaixo:
                        <Form.Group className="mt-2">
                            <Form.Control as="textarea" rows={3} onChange={handleChange} name="motivo" style={{ maxHeight: '500px' }} />
                        </Form.Group>
                    </div>
                    <div className="content-buttons">
                        <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => setCancelarDemandaModal(false)}>Cancelar</button>
                        <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => CancelarDemanda()}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCancelarDemanda