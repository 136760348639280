import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Col, Row, Form } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import { StyleContext } from '../../context/StyleContext';

function ModalEditarCiclo() {

    const { EditarCiclo, setEditarCiclo, ListaCiclos, setModalEditarCiclo, EditarCiclos } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setEditarCiclo({ ...EditarCiclo, [event.target.name]: event.target.value })
    }
    const handleChangeCheckbox = (event) => {
        setEditarCiclo({ ...EditarCiclo, [event.target.name]: event.target.checked })
    }

    useEffect(() => {
        ListaCiclos()
    }, [])

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-ciclo-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Editar ciclo</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setModalEditarCiclo(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content-criar-demanda">
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control as="textarea" rows={2} defaultValue={EditarCiclo.descricao} onChange={handleChange} name="descricao" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Observação</Form.Label>
                                <Form.Control as="textarea" rows={2} defaultValue={EditarCiclo.observacao} onChange={handleChange} name="observacao" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Data inicial</Form.Label>
                                <DatePickerComponent allowEdit={false} format='dd/MM/yyyy' value={new Date(EditarCiclo.dataInicial)} onChange={handleChange} name="dataInicial" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Data final</Form.Label>
                                <DatePickerComponent allowEdit={false} format='dd/MM/yyyy' value={new Date(EditarCiclo.dataFinal)} onChange={handleChange} name="dataFinal" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {['checkbox'].map((type) => (
                            <div className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    id={`default-${type}`}
                                    label="Não alterar BSC e SWOT"
                                    name="naoAlterarBSCSwot"
                                    checked={EditarCiclo.naoAlterarBSCSwot}
                                    onChange={handleChangeCheckbox}
                                />
                            </div>
                        ))}
                    </Row>
                </div>
                <div className="footer-modal-criar-demanda">
                    <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalEditarCiclo(false)}>Cancelar</button>
                    <button type="button" className="btn-dash btn-criar" style={btnCriar} onClick={() => EditarCiclos()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarCiclo