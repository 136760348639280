import React, { useContext, useEffect } from 'react';
import '../NotFound/style.css'
import { AuthContext } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

function NotFound() {

    const { Auth } = useContext(AuthContext)

    const navigate = useNavigate()


    return (
        <div className='container-notfound'>
            <div className='section-notfound'>
                <div className='notfound'>
                    <h1>4<span className='img-notfound'></span>4</h1>
                    <h2>Página não encontrada!</h2>
                    <p>O conteúdo que você está procurando não existe ou não está disponível</p>
                    <a href="/">Voltar à página inicial</a>
                </div>
            </div>
        </div>
    );
}

export default NotFound;