import { useContext, useState, useRef } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { FaStar, FaRegStar } from "react-icons/fa";
import { Inject, ChartComponent, Highlight, Tooltip, AreaSeries, PolarSeries, RadarSeries, Legend, Category, SeriesCollectionDirective, SeriesDirective } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';

export default function RadarAnalise({ corFavorito, tab }) {
    const { AdicionarGraficoFavorito, SwotRelatorio } = useContext(ApiContext)
    let data = [
        { x: 'Forças', y: SwotRelatorio?.quantidadeForca },
        { x: 'Fraquezas', y: SwotRelatorio?.quantidadeFraqueza },
        { x: 'Oportunidades', y: SwotRelatorio?.quantidadeOportunidade },
        { x: 'Ameaças', y: SwotRelatorio?.quantidadeAmeaca },
    ]
    const [type, setType] = useState('Polar');
    let chartInstance = useRef(null);
    const onChartLoad = (args) => {
        document.getElementById('inicio-radar').setAttribute('title', '');
    };
    const onChartLoad2 = (args) => {
        document.getElementById('swot-radar').setAttribute('title', '');
    };
    return (
        <>
            <h2 className="h2-bsc">
                <button onClick={() => AdicionarGraficoFavorito("Radar da Análise")} className='btn-grafico'>{corFavorito("Radar da Análise") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                <span style={{ fontWeight: '500' }}>Radar da Análise</span>
            </h2>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                <ChartComponent id={`${tab}-radar`} ref={chartInstance} primaryXAxis={{ valueType: 'Category', labelPlacement: 'OnTicks', interval: 1, coefficient: Browser.isDevice ? 80 : 100 }} legendSettings={{ visible: true, enableHighlight: true }} tooltip={{ enable: true }} loaded={tab === "inicio" ? onChartLoad.bind(this) : onChartLoad2.bind(this)} width='380' height='300'>
                    <Inject services={[AreaSeries, Legend, Category, PolarSeries, RadarSeries, Highlight, Tooltip]} />
                    <SeriesCollectionDirective>
                        <SeriesDirective dataSource={data} xName='x' yName='y' width={2} opacity={0.5} type={type} drawType='Area' border={{ color: 'transparent' }} />
                    </SeriesCollectionDirective>
                </ChartComponent>
            </div>
        </>
    )
}