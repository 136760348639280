import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from '../../context/ApiContext';
import { DiagramComponent, DiagramTools, HierarchicalTree, Inject, DataBinding, SnapConstraints } from "@syncfusion/ej2-react-diagrams"
import { DataManager } from "@syncfusion/ej2-data"
import { FaStar, FaRegStar } from "react-icons/fa";
import { Skeleton } from '@mui/material'
import { Form } from 'react-bootstrap'

export default function ArvoreCapacidade({ corFavorito, cicloDemandaKey, tab }) {
    const theme = localStorage.getItem('theme') || 'light'
    const { getArvoreCapacidades, ArvoreDeCapacidades, loading, AdicionarGraficoFavorito } = useContext(ApiContext)
    const [isLoading, setIsLoading] = useState(false)
    const [tipoCapacidade, setTipoCapacidade] = useState("1")

    const SAMPLE_CSS = `
            .e-diagram {
                display: block;
                ${theme == 'dark' ? "background-color: #fff;" : "background-color: #3f3f46;"}}
                `

    const retornaTipoCapacidade = (valor) => {
        if (valor === "1") {
            return (
                new DataManager(ArvoreDeCapacidades.filter(item => item.tipoSwot === "Forca" || item.tipoSwot === ""))
            )
        }
        if (valor === "2") {
            return (
                new DataManager(ArvoreDeCapacidades.filter(item => item.tipoSwot === "Fraqueza" || item.tipoSwot === ""))
            )
        }
        if (valor === "3") {
            return (
                new DataManager(ArvoreDeCapacidades.filter(item => item.tipoSwot === "Oportunidade" || item.tipoSwot === ""))
            )
        }
        if (valor === "4") {
            return (
                new DataManager(ArvoreDeCapacidades.filter(item => item.tipoSwot === "Ameaca" || item.tipoSwot === ""))
            )
        }
    }

    const dataSettings = {
        id: "chave",
        parentId: "referencia",
        descricao: "descricao",
        tipo: "tipo",
        dataManager: retornaTipoCapacidade(tipoCapacidade)
    };

    const layoutSetting = { type: 'HierarchicalTree', orientation: 'LeftToRight' }

    useEffect(() => {
        if (cicloDemandaKey) {
            setIsLoading(true)
            getArvoreCapacidades(cicloDemandaKey)
                .then(() => {
                    setIsLoading(false)
                })
        }
    }, [cicloDemandaKey])

    return (
        <>
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', padding: '0' }}>
                <h2 className="h2-bsc">
                    <button onClick={() => AdicionarGraficoFavorito("Árvore de Capacidades")} className='btn-grafico'>{corFavorito("Árvore de Capacidades") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                    <span style={{ fontWeight: '500' }}>Árvore de Capacidades</span>
                </h2>
                <Form.Select style={{ width: '200px', marginRight: '15px' }} value={tipoCapacidade} onChange={(e) => setTipoCapacidade(e.target.value)} size="sm">
                    <option value="1">Forças</option>
                    <option value="2">Fraquezas</option>
                    <option value="3">Oportunidades</option>
                    <option value="4">Ameaças</option>
                </Form.Select>
            </div>
            <div style={{ width: '100%', height: '70vh' }}>
                <style>
                    {SAMPLE_CSS}
                </style>
                {isLoading ?
                    <Skeleton variant="rounded" width="100%" height={350} />
                    :
                    <DiagramComponent snapSettings={{ constraints: SnapConstraints.None }} id={`${tab}-arvore`} width={"100%"} height={"100%"} scrollSettings={{ scrollLimit: "Infinity" }} dataSourceSettings={dataSettings} layout={layoutSetting} getNodeDefaults={(node) => {
                        node.width = 200;
                        node.height = 150;
                        let cor = ""
                        let corTipo = ""
                        if (node.data.tipoSwot == "Forca") {
                            cor = '#d0ecab'
                            corTipo = "#0F6631"
                        } else if (node.data.tipoSwot == "Fraqueza") {
                            cor = '#FFC5BD'
                            corTipo = "#661612"
                        } else if (node.data.tipoSwot == "Oportunidade") {
                            cor = '#addbff'
                            corTipo = "#002851"
                        } else if (node.data.tipoSwot == "Ameaca") {
                            cor = '#FEF9C3'
                            corTipo = "#713F12"
                        }
                        else {
                            cor = '#FFD4B1'
                            corTipo = "#A84D2C"
                        }
                        let style =
                            `
                                    display: flex;
                                    flex-direction: column;
                                    height: 100%;
                                    width: 100%;
                                    border: solid 0px;
                                    border-left-width: 15px;
                                    border-color: ${cor};
                                    border-radius: 8px;
                                    box-shadow: 2px 3px 8px 3px #00000024;
                                    `

                        let styleTipo =
                            `
                                    background:${cor};
                                    font-size: 12px;
                                    padding: 5px;
                                    border-radius: 8px;
                                    color: ${corTipo};
                                    `

                        let styleDescricao = `
                                    padding: 5px;
                                `
                        node.shape = {
                            type: 'HTML',
                            content: `<div style="${style}">
                                                <div style="padding: 10px 5px;"><span style="${styleTipo}">${node.data.tipo}</span></div>
                                                <p style="${styleDescricao}">${node.data.descricao}</p>
                                                ${node.data.descricaoSwot ? `<p style="${styleDescricao}"><strong>SWOT: </strong> ${node.data.descricaoSwot}</p>` : ''}
                                              </div>`,
                        }
                        return node;
                    }}
                        tool={
                            DiagramTools.ZoomPan
                        }

                        getConnectorDefaults={(connector) => {
                            connector.type = "Orthogonal";
                            connector.cornerRadius = 7;
                            connector.targetDecorator.height = 0;
                            connector.targetDecorator.width = 0;
                            connector.style.strokeColor = "#ccc"
                            connector.style.strokeWidth = 5
                            return connector;
                        }}>
                        <Inject services={[HierarchicalTree, DataBinding]} />
                    </DiagramComponent>
                }
            </div>
        </>
    )
}