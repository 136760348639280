import { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { FaStar, FaRegStar } from "react-icons/fa";
import { ProgressBar } from 'react-bootstrap';

export default function SemTitulo({ corFavorito }) {
    const { AdicionarGraficoFavorito, SwotRelatorio } = useContext(ApiContext)
    return (
        <>
            <h2 className="h2-bsc">
                <button onClick={() => AdicionarGraficoFavorito("Análise Capacidades")} className='btn-grafico'>{corFavorito("Análise Capacidades") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                <span style={{ fontWeight: '500' }}>Análise Capacidades</span>
            </h2>
            <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', gap: '25px' }}>
                <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                    <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                        <span className="span-swot span-swot-green">Análise das forças</span>
                    </div>
                    <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {SwotRelatorio && SwotRelatorio?.forcas.length > 0 ?
                            SwotRelatorio?.forcas
                                .slice()
                                .sort((a, b) => b.gut - a.gut)
                                .map((item, index) => {
                                    return (
                                        <div style={{ width: '100%' }} key={index}>
                                            <div className='span-bsc' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo}</span>
                                                <span>{item.gut}</span>
                                            </div>
                                            <ProgressBar min={0} max={125} id='progress-forca' now={item.gut} style={{ width: '100%' }} />
                                        </div>
                                    )
                                })
                            :
                            <p>Não foram adicionadas forças a esse SWOT.</p>
                        }
                    </div>
                </div>
                <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                    <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                        <span className="span-swot span-swot-red">Análise das fraquezas</span>
                    </div>
                    <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {SwotRelatorio && SwotRelatorio?.fraquezas.length > 0 ?
                            SwotRelatorio?.fraquezas
                                .slice()
                                .sort((a, b) => b.gut - a.gut)
                                .map((item, index) => {
                                    return (
                                        <div style={{ width: '100%' }} key={index}>
                                            <div className='span-bsc' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                <span>{item.gut}</span>
                                            </div>
                                            <ProgressBar min={0} max={125} id='progress-fraqueza' now={item.gut} style={{ width: '100%' }} />
                                        </div>
                                    )
                                })
                            :
                            <p>Não foram adicionadas fraquezas a esse SWOT.</p>
                        }
                    </div>
                </div>
                <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                    <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                        <span className="span-swot span-swot-blue">Análise das oportunidade</span>
                    </div>
                    <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {SwotRelatorio && SwotRelatorio?.oportunidades.length > 0 ?
                            SwotRelatorio?.oportunidades
                                .slice()
                                .sort((a, b) => b.gut - a.gut)
                                .map((item, index) => {
                                    return (
                                        <div style={{ width: '100%' }} key={index}>
                                            <div className='span-bsc' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                <span>{item.gut}</span>
                                            </div>
                                            <ProgressBar min={0} max={125} id='progress-oportunidade' now={item.gut} style={{ width: '100%' }} />
                                        </div>
                                    )
                                })
                            :
                            <p>Não foram adicionadas oportunidades a esse SWOT.</p>
                        }
                    </div>
                </div>

                <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                    <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                        <span className="span-swot span-swot-yellow">Análise das ameaças</span>
                    </div>
                    <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                        {SwotRelatorio && SwotRelatorio?.ameacas.length > 0 ?
                            SwotRelatorio?.ameacas
                                .slice()
                                .sort((a, b) => b.gut - a.gut)
                                .map((item, index) => {
                                    return (
                                        <div style={{ width: '100%' }} key={index}>
                                            <div className='span-bsc' style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                <span>{item.gut}</span>
                                            </div>
                                            <ProgressBar min={0} max={125} id='progress-ameaca' now={item.gut} style={{ width: '100%' }} />
                                        </div>
                                    )
                                })
                            :
                            <p>Não foram adicionadas ameaças a esse SWOT.</p>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}