import '../../App.css';
import React, { useContext, useRef, useLayoutEffect, useState, useEffect } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { Tab, Tabs, Row, Col } from 'react-bootstrap'
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral';
import Footer from '../../components/footer/Footer'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import Perspectivas from '../../components/graficos/Perspectivas';
import Impacto from '../../components/graficos/Impacto';
import Swot from '../../components/graficos/Swot';
import Indice from '../../components/graficos/Indice';
import AnaliseGeral from '../../components/graficos/AnaliseGeral';
import RadarAnalise from '../../components/graficos/RadarAnalise';
import Principais from '../../components/graficos/Principais';
import SemTitulo from '../../components/graficos/SemTitulo';
import DemandasXCapacidades from '../../components/graficos/DemandasXCapacidades';
import SistemasXDemandas from '../../components/graficos/SistemasXDemandas';
import ArvoreCapacidade from '../../components/graficos/ArvoreCapacidade';
import BscXSwotXGut from '../../components/graficos/BscXSwotXGut';
import CustoXDemandas from '../../components/graficos/CustoXDemandas';
import EmptyFolder from '../../components/EmptyFolder';

function Dashboard() {
    const { ObterBSC, SwotRelatorio, ListaRelatorio, ListaGraficosFavoritos, Favoritos, ListaCiclos, Ciclos, Success, Error, ListaBSC, BSCs, ListaMatriz, ListaSwotPorCiclo, SwotPorCiclo, DashboardSwot, DiretrizEstrategica, EmpresaKey } = useContext(ApiContext)
    const [cicloDemandaKey, setCicloDemandaKey] = useState("")
    const [bscKey, setBscKey] = useState("")
    const [swotKey, setSwotKey] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const corFavorito = (nome) => {
        return Favoritos?.some((item) => item.nomeGrafico === nome) ? false : true;
    };

    useEffect(() => {
        if (EmpresaKey) {
            setIsLoading(true)
            ListaCiclos()
                .then(() => {
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
            ListaGraficosFavoritos()
                .then(() => {
                    setIsLoading(false)
                })
        }
    }, [])
    useEffect(() => {
        if (cicloDemandaKey !== "") {
            setIsLoading(true)
            ListaBSC(JSON.parse(cicloDemandaKey).key)
                .then(() => {
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        }
    }, [cicloDemandaKey])
    useEffect(() => {
        if (cicloDemandaKey !== "") {
            setIsLoading(true)
            ListaSwotPorCiclo(JSON.parse(cicloDemandaKey).id)
                .then(() => {
                    setIsLoading(false)
                })
                .catch(() => {
                    setIsLoading(false)
                })
        }
    }, [cicloDemandaKey])
    useEffect(() => {
        if (bscKey !== "") {
            ObterBSC(bscKey)
        }
    }, [bscKey])
    useEffect(() => {
        if (swotKey !== "") {
            ListaRelatorio(swotKey)
            ListaMatriz(swotKey)
            DashboardSwot(swotKey)
        }
    }, [swotKey])

    useEffect(() => {
        setBscKey("")
        setSwotKey("")
    }, [cicloDemandaKey])

    return (
        <div>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <Header pagina={'Dashboard'}></Header>
            <MenuLateral></MenuLateral>

            <div className='container-dashboard' style={{ paddingTop: '30px' }}>
                <div style={{ display: 'flex', alignItems: 'start', marginBottom: '20px', gap: '20px' }}>
                    <Col lg={3} md={6}>
                        <FormControl fullWidth size="small">
                            <InputLabel id="demo-simple-select-label">Ciclo</InputLabel>
                            <Select
                                className='span-bsc'
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={cicloDemandaKey}
                                label="Ciclo"
                                onChange={(e) => setCicloDemandaKey(e.target.value)}
                            >
                                <MenuItem value="0">Selecionar...</MenuItem>
                                {Ciclos?.map((item) => {
                                    return (
                                        <MenuItem key={item.id} value={JSON.stringify({ id: item.id, key: item.chave })}>
                                            {item.descricao}
                                        </MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Col>
                </div>
                {isLoading ?
                    <div className="loading-screen" >
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                    :
                    <Tabs
                        defaultActiveKey="inicio"
                        className="mb-3"
                        justify
                    >
                        <Tab eventKey="inicio" title="Início">
                            {cicloDemandaKey ?
                                <Row>
                                    <Col lg={3} className='mb-3'>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">BSC</InputLabel>
                                            <Select
                                                className='span-bsc'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={bscKey}
                                                label="Ciclo"
                                                onChange={(e) => setBscKey(e.target.value)}
                                            >
                                                <MenuItem value="">Selecionar...</MenuItem>
                                                {cicloDemandaKey !== "0" && BSCs?.map((item) => {
                                                    return (
                                                        <MenuItem key={item.id} value={item.chave}>
                                                            {item.descricao ? item.descricao : "-"}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col lg={3} className='mb-3'>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">SWOT</InputLabel>
                                            <Select
                                                className='span-bsc'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={swotKey}
                                                label="SWOT"
                                                onChange={(e) => setSwotKey(e.target.value)}
                                            >
                                                <MenuItem value="">Selecionar...</MenuItem>
                                                {cicloDemandaKey !== "0" && SwotPorCiclo && SwotPorCiclo?.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.key}>
                                                            {item.descricao ? item.descricao : "-"}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                </Row>
                                :
                                <EmptyFolder texto={"ciclo"} />
                            }
                            {cicloDemandaKey &&
                                <>
                                    {bscKey && swotKey ?
                                        <>
                                            {Favoritos.length > 0 ?
                                                <>
                                                    {Favoritos?.map((item, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <Row>
                                                                    {item.nomeGrafico == "Perspectivas" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <Perspectivas corFavorito={corFavorito} DiretrizEstrategica={DiretrizEstrategica} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {item.nomeGrafico == "Impacto Objetivos do Negócio" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <Impacto corFavorito={corFavorito} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {/* {item.nomeGrafico == "Perspectiva Aprendizado e Crescimento" &&
                                                                <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                    <Aprendizado corFavorito={corFavorito} />
                                                                </Col>
                                                            } */}
                                                                    {SwotRelatorio && item.nomeGrafico == "SWOT" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <Swot corFavorito={corFavorito} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {SwotRelatorio && item.nomeGrafico == "Índice de favorabilidade" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px', padding: '0' }}>
                                                                            <Indice corFavorito={corFavorito} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {SwotRelatorio && item.nomeGrafico == "Análise Geral" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <AnaliseGeral corFavorito={corFavorito} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {SwotRelatorio && item.nomeGrafico == "Radar da Análise" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <RadarAnalise corFavorito={corFavorito} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {SwotRelatorio && item.nomeGrafico == "Principais ameaças" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <Principais corFavorito={corFavorito} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {SwotRelatorio && item.nomeGrafico == "Análise Capacidades" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <SemTitulo corFavorito={corFavorito} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {item.nomeGrafico == "Demandas x Capacidades de negócios" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <DemandasXCapacidades corFavorito={corFavorito} swotKey={swotKey} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {item.nomeGrafico == "Sistema x Demandas" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <SistemasXDemandas corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {/* {item.nomeGrafico == "Capacidades de negócios por Demanda e Área" &&
                                                                <Col lg={6} className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                    <CapacidadeXDemandaXArea corFavorito={corFavorito} />
                                                                </Col>
                                                            } */}
                                                                    {item.nomeGrafico == "Árvore de Capacidades" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <ArvoreCapacidade corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {item.nomeGrafico == "BSC" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <BscXSwotXGut corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} swotKey={swotKey ? swotKey : ""} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {/* {item.nomeGrafico == "Demandas dependentes de outras demandas" &&
                                                                <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                    <DemandaDependente corFavorito={corFavorito} />
                                                                </Col>
                                                            } */}
                                                                    {item.nomeGrafico == "Custo x Demandas" &&
                                                                        <Col className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                            <CustoXDemandas corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} tab={"inicio"} />
                                                                        </Col>
                                                                    }
                                                                    {/* {item.nomeGrafico == "Budget de demandas" &&
                                                                <Col lg={6} className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                                                    <BudgetDemandas corFavorito={corFavorito} />
                                                                </Col>
                                                            } */}
                                                                </Row>
                                                            </div>
                                                        )
                                                    })}
                                                </>
                                                :
                                                <div style={{ marginTop: "90px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                                    <h2 className='h2-sem-demandas'>Nenhum gráfico adicionado como favorito.</h2>
                                                </div>
                                            }
                                        </>
                                        :
                                        <EmptyFolder texto={"BSC e um SWOT"} />
                                    }
                                </>
                            }
                        </Tab>
                        <Tab eventKey="bsc" title="BSC">
                            {cicloDemandaKey ? (
                                <>
                                    <Col lg={3} className='mb-3'>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">BSC</InputLabel>
                                            <Select
                                                className='span-bsc'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={bscKey}
                                                label="Ciclo"
                                                onChange={(e) => setBscKey(e.target.value)}
                                            >
                                                <MenuItem value="0">Selecionar...</MenuItem>
                                                {cicloDemandaKey !== "0" && BSCs?.map((item) => (
                                                    <MenuItem key={item.id} value={item.chave}>
                                                        {item.descricao ? item.descricao : "-"}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>

                                    {bscKey ? (
                                        DiretrizEstrategica && (
                                            <>
                                                <Row style={{ gap: '20px', marginBottom: '20px' }}>
                                                    <Col className='section-bsc-grafico'>
                                                        <Perspectivas corFavorito={corFavorito} DiretrizEstrategica={DiretrizEstrategica} tab={"bsc"} />
                                                    </Col>
                                                    <Col className='section-bsc-grafico'>
                                                        <Impacto corFavorito={corFavorito} tab={"bsc"} />
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    ) : (
                                        <EmptyFolder texto={"BSC"} />
                                    )}
                                </>
                            ) : (
                                <EmptyFolder texto={"ciclo"} />
                            )}
                        </Tab>
                        <Tab eventKey="swot" title="SWOT">
                        {cicloDemandaKey ? (
    <>
        {/* Primeiro bloco: Seleção do SWOT */}
        <Col lg={3} className='mb-3'>
            <FormControl fullWidth size="small">
                <InputLabel id="demo-simple-select-label">SWOT</InputLabel>
                <Select
                    className='span-bsc'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={swotKey}
                    label="SWOT"
                    onChange={(e) => setSwotKey(e.target.value)}
                >
                    <MenuItem value="0">Selecionar...</MenuItem>
                    {cicloDemandaKey !== "0" && SwotPorCiclo && SwotPorCiclo?.map((item, index) => (
                        <MenuItem key={index} value={item.key}>
                            {item.descricao ? item.descricao : "-"}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Col>

        {/* Segundo bloco: Renderizar conteúdo adicional se swotKey estiver presente */}
        {swotKey ? (
            SwotRelatorio && (
                <>
                    <Row className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                        <Swot corFavorito={corFavorito} tab={"swot"} />
                    </Row>
                    <Row className='section-bsc-grafico' style={{ marginBottom: '20px', padding: '0' }}>
                        <Indice corFavorito={corFavorito} tab={"swot"} />
                    </Row>
                    <Row style={{ display: 'flex', height: '100%', justifyContent: 'space-between', gap: '20px', marginBottom: '20px' }}>
                        <Col className='section-bsc-grafico'>
                            <AnaliseGeral corFavorito={corFavorito} />
                        </Col>
                        <Col className='section-bsc-grafico'>
                            <RadarAnalise corFavorito={corFavorito} tab={"swot"} />
                        </Col>
                    </Row>
                    <Row style={{ display: 'flex', height: '100%', justifyContent: 'space-between', gap: '20px', marginBottom: '20px' }}>
                        <Col className='section-bsc-grafico'>
                            <Principais corFavorito={corFavorito} />
                        </Col>
                        <Col className='section-bsc-grafico'>
                            <SemTitulo corFavorito={corFavorito} />
                        </Col>
                    </Row>
                </>
            )
        ) : (
            <EmptyFolder texto={"SWOT"} />
        )}
    </>
) : (
    <EmptyFolder texto={"ciclo"} />
)}
                        </Tab>
                        <Tab eventKey="capacidades" title="Capacidades">
                            {cicloDemandaKey ?
                                <>
                                    <Row style={{ display: 'flex', height: '100%', justifyContent: 'space-between', gap: '20px', marginBottom: '20px' }}>
                                        <Col className='section-bsc-grafico'>
                                            <DemandasXCapacidades corFavorito={corFavorito} swotKey={swotKey} tab={"capacidade"} />
                                        </Col>
                                        <Col className='section-bsc-grafico'>
                                            <SistemasXDemandas corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} tab={"capacidade"} />
                                        </Col>
                                    </Row>
                                    <Row style={{ display: 'flex', height: '100%', justifyContent: 'space-between', gap: '20px', marginBottom: '20px' }}>
                                        {/* <Col className='section-bsc-grafico'>
                                            <CapacidadeXDemandaXArea corFavorito={corFavorito} />
                                        </Col> */}
                                        <Col className='section-bsc-grafico'>
                                            <ArvoreCapacidade corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} tab={"capacidade"} />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <EmptyFolder texto={"ciclo"} />
                            }
                        </Tab>
                        <Tab eventKey="demandas" title="Demandas">
                            {cicloDemandaKey ?
                                <>
                                    <Col lg={3} className='mb-3'>
                                        <FormControl fullWidth size="small">
                                            <InputLabel id="demo-simple-select-label">SWOT</InputLabel>
                                            <Select
                                                className='span-bsc'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={swotKey}
                                                label="SWOT"
                                                onChange={(e) => setSwotKey(e.target.value)}
                                            >
                                                <MenuItem value="0">Selecionar...</MenuItem>
                                                {SwotPorCiclo && SwotPorCiclo?.map((item, index) => {
                                                    return (
                                                        <MenuItem key={index} value={item.key}>
                                                            {item.descricao ? item.descricao : "-"}
                                                        </MenuItem>
                                                    )
                                                })}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Row className='section-bsc-grafico' style={{ marginBottom: '20px' }}>
                                        <BscXSwotXGut corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} swotKey={swotKey ? swotKey : ""} tab={"demandas"} />
                                    </Row>
                                    {/* <Row className='section-bsc-grafico'>
                                        <DemandaDependente corFavorito={corFavorito} />
                                    </Row> */}
                                </>
                                :
                                <EmptyFolder texto={"ciclo"} />
                            }
                        </Tab>
                        <Tab eventKey="custos" title="Custos">
                            {cicloDemandaKey ?
                                <Row style={{ display: 'flex', height: '100%', justifyContent: 'space-between', gap: '20px', marginBottom: '20px' }}>
                                    <Col className='section-bsc-grafico'>
                                        <CustoXDemandas corFavorito={corFavorito} cicloDemandaKey={cicloDemandaKey ? JSON.parse(cicloDemandaKey).key : ""} tab={"custos"} />
                                    </Col>
                                    {/* <Col className='section-bsc-grafico'>
                                        <BudgetDemandas corFavorito={corFavorito} />
                                    </Col> */}
                                </Row>
                                :
                                <EmptyFolder texto={"ciclo"} />
                            }
                        </Tab>
                    </Tabs>
                }
            </div>
            <Footer></Footer>
        </div>
    );

}

export default Dashboard;
