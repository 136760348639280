import React, { useContext, useState, useEffect, useRef } from "react";
import { ApiContext } from '../../context/ApiContext';
import { DashboardContext } from '../../context/DashboardContext'
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, AreaSeries, PolarSeries, RadarSeries, Highlight, Tooltip } from '@syncfusion/ej2-react-charts';
import { InputLabel, MenuItem, FormControl, Select, Skeleton } from '@mui/material'
import { FaStar, FaRegStar } from "react-icons/fa";

export default function BscXSwotXGut({ corFavorito, cicloDemandaKey, swotKey, tab }) {
    const { AdicionarGraficoFavorito, getBscXSwotXGut, BscXSwotXGut, DemandaPorCiclo, ListaDemandaPorCiclo } = useContext(ApiContext)
    const { demandaKey, setDemandaKey } = useContext(DashboardContext)
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (demandaKey !== "0" && swotKey !== "") {
            getBscXSwotXGut(demandaKey, swotKey)
        }
    }, [demandaKey, swotKey])
    useEffect(() => {
        if (cicloDemandaKey !== "") {
            setIsLoading(true)
            ListaDemandaPorCiclo(cicloDemandaKey)
                .then(() => {
                    setIsLoading(false)
                })
        }
    }, [cicloDemandaKey])

    const [type, setType] = useState('Polar');
    const onChartLoad = (args) => {
        document.getElementById('inicio-bscxswotxgut').setAttribute('title', '');
    };
    const onChartLoad2 = (args) => {
        document.getElementById('demandas-bscxswotxgut').setAttribute('title', '');
    };

    const handleChange = (event) => {
        setDemandaKey(event)
    }
    
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', padding: '0' }}>
                <h2 className="h2-bsc">
                    <button onClick={() => AdicionarGraficoFavorito("BSC")} className='btn-grafico'>{corFavorito("BSC") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                    <span style={{ fontWeight: '500' }}>BSC + SWOT + GUT</span>
                </h2>
                <FormControl size="small" sx={{ m: 1, minWidth: 400 }}>
                    <InputLabel id="demo-simple-select-label">Demanda</InputLabel>
                    <Select
                    className='span-bsc'
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={demandaKey}
                        label="Demanda"
                        onChange={(e) => handleChange(e.target.value)}
                    >
                        <MenuItem value="0">Selecionar...</MenuItem>
                        {DemandaPorCiclo && DemandaPorCiclo?.map((item, index) => {
                            return (
                                <MenuItem key={index} value={item.key}>
                                    {item.titulo ? item.titulo : "-"}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
            </div>

            <div className="modal-content">
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '400px' }}>
                    {isLoading ?
                        <Skeleton variant="rounded" width="100%" height={550} />
                        :
                        <ChartComponent id={`${tab}-bscxswotxgut`} primaryXAxis={{ valueType: 'Category', labelPlacement: 'OnTicks', interval: 1 }} legendSettings={{ visible: true, enableHighlight: true }} tooltip={{ enable: true }} loaded={tab === "inicio" ? onChartLoad.bind(this) : onChartLoad2.bind(this)} width='380' height='300'>
                            <Inject services={[AreaSeries, Legend, Category, PolarSeries, RadarSeries, Highlight, Tooltip]} />
                            <SeriesCollectionDirective>
                                <SeriesDirective dataSource={BscXSwotXGut} xName='x' yName='y' width={2} opacity={0.5} type={type} drawType='Area' border={{ color: 'transparent' }} />
                            </SeriesCollectionDirective>
                        </ChartComponent>
                    }
                </div>
            </div>
        </>
    )
}