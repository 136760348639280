import '../../App.css';
import React, { useContext } from "react";
import { ApiContext } from '../../context/ApiContext';

function ModalExcluirDemandaCiclo() {

    const { setExcluirDemandaCicloModal, ExcluirDemandaCiclo, demandaCicloAtual } = useContext(ApiContext)

    return (
        <div className="modal-novo">
            <div className="modal-interno-delete">
                <div className="modal-content-delete">
                    <div>
                        <h2 className="h2-modal" style={{textAlign: "center", paddingBottom: "10px"}}>Deseja realmente excluir esta demanda do grupo de demandas?</h2>
                        <div className="content-buttons">
                            <button type="submit" className="btn-modal-delete btn-cancelar" onClick={() => setExcluirDemandaCicloModal(false)}>Cancelar</button>
                            <button type="submit" className="btn-modal-delete btn-salvar" onClick={() => ExcluirDemandaCiclo(demandaCicloAtual)}>Excluir</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalExcluirDemandaCiclo