import '../../App.css';
import React, { useContext } from "react";
import { ApiContext } from '../../context/ApiContext';
import { IoIosWarning } from "react-icons/io";
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalExcluirCiclo() {

    const { setModalExcluirCiclo, ExcluirCiclo, CicloAtualKey } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    return (
        <div className="modal-novo">
            <div className="modal-interno-delete">
                <div className="modal-content-delete">
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '20px', padding: '16px' }} className='border-bottom'>
                        <div>
                            <IoIosWarning style={{ color: '#FACC15', marginRight: '5px' }} /> Excluir ciclo
                        </div>
                        <button className='btn-fechar' onClick={() => setModalExcluirCiclo(false)}>
                            <FaTimes />
                        </button>
                    </div>
                    <div style={{ padding: '16px' }} className='border-bottom'>
                        Tem certeza que deseja excluir esse ciclo?
                    </div>
                    <div className="content-buttons">
                        <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalExcluirCiclo(false)}>Cancelar</button>
                        <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => ExcluirCiclo(CicloAtualKey)}>Excluir</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalExcluirCiclo