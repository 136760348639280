import '../../App.css';
import React, { useContext, useState } from "react";
import { ApiContext } from '../../context/ApiContext';
import { Form } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function ModalTrocarRole() {

    const { setModalTrocarRole, EditarRole, AdminUser, SupervisorUser, NomeUser, LeitorUser } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const [Admin, setAdmin] = useState(AdminUser)
    const [Supervisor, setSupervisor] = useState(SupervisorUser)
    const [Nome, setNome] = useState(NomeUser)
    const [Leitor, setLeitor] = useState(LeitorUser)

    const role = Cookies.get('Role')

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <div>
                        <h1 className="h1-modal">Editar usuário</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setModalTrocarRole(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content">
                    <Form.Group className="mb-3 mt-2">
                        <Form.Label>Nome</Form.Label>
                        <Form.Control defaultValue={NomeUser} onChange={(e) => setNome(e.target.value)} name="nome" />
                    </Form.Group>
                    <Form.Group className="mb-3 mt-2">
                        <Form.Label>Escolha a nova função do usuário</Form.Label>
                        <div>
                            {['checkbox'].map((type) => (
                                <div key={`inline-${type}`} className="mb-3">
                                    {role == "admin" &&
                                        <Form.Check
                                            inline
                                            label="Admin"
                                            name="admin"
                                            type={type}
                                            id={`inline-${type}-2`}
                                            onChange={(e) => setAdmin(e.target.checked)}
                                            defaultChecked={AdminUser ? true : false}
                                        />
                                    }
                                    <Form.Check
                                        inline
                                        label="Supervisor"
                                        name="supervisor"
                                        type={type}
                                        id={`inline-${type}-1`}
                                        onChange={(e) => setSupervisor(e.target.checked)}
                                        defaultChecked={SupervisorUser ? true : false}
                                    />
                                    <Form.Check
                                        inline
                                        label="Somente visualizar"
                                        name="somenteVisualizar"
                                        type={type}
                                        id={`inline-${type}-3`}
                                        onChange={(e) => setLeitor(e.target.checked)}
                                        defaultChecked={LeitorUser ? true : false}
                                    />
                                </div>
                            ))}
                        </div>
                    </Form.Group>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalTrocarRole(false)}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar} onClick={() => EditarRole(Admin, Supervisor, Leitor, Nome)}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalTrocarRole