import '../../App.css';
import React, { useContext, useEffect } from 'react';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import ModalCadastrarUsuario from '../../components/modal/ModalCadastrarUsuario'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import { FaPlus } from 'react-icons/fa'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import moment from 'moment/moment';
import 'moment/locale/pt-br'
import ModalTrocarRole from '../../components/modal/ModalTrocarRole';
import { TbPencil } from "react-icons/tb";
import { StyleContext } from '../../context/StyleContext';

function CadastroUsuario() {
    const { Success, Error, openModal, setOpenModal, Usuarios, ListaUsuarios, loading, StatusUsuario, TrocarRoleModal, setModalTrocarRole, setIdUser, setNomeUser, setAdminUser, setSupervisorUser, setLeitorUser } = useContext(ApiContext)
    const { h3Demanda, btnCriar, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });

    const verificaUsuario = (props) => {
        if (props.admin) {
            return "Admin"
        }
        else if (props.supervisor) {
            return "Supervisor"
        }
        else if (props.somenteVisualizar) {
            return "Leitor"
        }
        else {
            return "Outro"
        }
    }

    const editarRole = (id, nome, admin, supervisor, leitor) => {
        setIdUser(id)
        setNomeUser(nome)
        setAdminUser(admin)
        setSupervisorUser(supervisor)
        setLeitorUser(leitor)
        setModalTrocarRole(true)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-ciclo'>
            <td className="td-ciclo">{props.nome}</td>
            <td className="td-ciclo">{props.email}</td>
            <td className="td-ciclo">
                {(props.ativo) ? (
                    <div className="item-ativo">⬤</div>
                ) : (
                    <div className="item-inativo">⬤</div>
                )}
            </td>
            <td className='td-ciclo'>
                <div className="checkbox-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input type="checkbox" label=" Ativo" className="toggleBtn" defaultChecked={props.ativo} />
                </div>
            </td>
            <td className="td-ciclo">
                {verificaUsuario(props)}
            </td>
            <td className='td-ciclo'>{moment(props.ultimoLogin).format('lll')}</td>
            <td className='td-ciclo'><button onClick={() => editarRole(props.id, props.nome, props.admin, props.supervisor, props.somenteVisualizar)} className='btn-dem' style={{color: `${corTerciaria}`}} ><TbPencil /></button></td>
        </tr>);
    };

    useEffect(() => {
        ListaUsuarios("")
    }, [])

    return (
        <div>
            <Header pagina={'Cadastros'} subpagina={'Cadastrar usuário'}></Header>
            <MenuLateral></MenuLateral>
            {TrocarRoleModal &&
                <ModalTrocarRole />
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <div className='container-principal'>
                <div className='demanda-header'>
                    {openModal &&
                        <ModalCadastrarUsuario closeModal={setOpenModal} />
                    }
                    <button className='btn-dash btn-criar' style={btnCriar} onClick={() => setOpenModal(true)}><FaPlus style={{ marginRight: "10px" }} />Cadastrar usuário</button>
                </div>
                {loading ? (
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : (
                    <div className='card card-table'>
                        <h3 className='h3-demanda' style={h3Demanda}>Usuários cadastrados</h3>
                        <GridComponent locale='pt-BR' id="user" dataSource={Usuarios} rowTemplate={gridTemplate} toolbar={toolbarOptions} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                            <ColumnsDirective>
                                <ColumnDirective field='nome' headerText='Nome' />
                                <ColumnDirective field='email' headerText='E-mail' />
                                <ColumnDirective field='ativo' headerText='Status' />
                                <ColumnDirective headerText='Ativo' />
                                <ColumnDirective headerText='Função' />
                                <ColumnDirective field='ultimoLogin' headerText='Último login' />
                                <ColumnDirective headerText='Opções' />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Toolbar]} />
                        </GridComponent>
                    </div>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
}

export default CadastroUsuario;