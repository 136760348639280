import '../../App.css';
import React, { useContext } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalCadastrarCompetencia({ closeModal }) {
    const { CadastrarCompetencia, CadastroCompetencia, setCadastroCompetencia } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setCadastroCompetencia({ ...CadastroCompetencia, [event.target.name]: event.target.value })
    }

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Cadastrar competência</h1>
                    <div className="btn-close-modal" onClick={() => closeModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CadastrarCompetencia}>
                    <div className="modal-content">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome da competência</Form.Label>
                                    <Form.Control maxLength={100} onChange={handleChange} name="descricao" />
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => closeModal(false)}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCadastrarCompetencia