import React, { useContext, useState } from "react";
import { RichTextEditorComponent, Inject, QuickToolbar, Link, HtmlEditor, Toolbar, Count } from '@syncfusion/ej2-react-richtexteditor';
import { ApiContext } from "../../context/ApiContext";
import { FaTimes } from 'react-icons/fa'
import { StyleContext } from "../../context/StyleContext";

function ModalEditorTexto({ tipoEdicao, valor }) {
    let rteObj;

    const { setRTFModal, FormDemanda } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const [descricaoEditor, setDescricaoEditor] = useState(valor != '' ? valor : '')
    const [cenarioAtualEditor, setCenarioAtualEditor] = useState(valor != '' ? valor : '')
    const [problemaCenarioAtualEditor, setProblemaCenarioAtualEditor] = useState(valor != '' ? valor : '')
    const [objetivoEditor, setObjetivoEditor] = useState(valor != '' ? valor : '')
    const [resultadoEditor, setResultadoEditor] = useState(valor != '' ? valor : '')
    const [necessidadeEditor, setNecessidadeEditor] = useState(valor != '' ? valor : '')
    const [oportunidadeEditor, setOportunidadeEditor] = useState(valor != '' ? valor : '')
    const [dataLimiteEditor, setDataLimiteEditor] = useState(valor != '' ? valor : '')
    const [esforcoEditor, setEsforcoEditor] = useState(valor != '' ? valor : '')

    const toolbarSettings = {
        items: ['Bold', 'Italic', 'Underline', 'StrikeThrough',
            'FontName', 'FontSize', 'FontColor', 'BackgroundColor',
            'LowerCase', 'UpperCase', '|',
            'Alignments', 'NumberFormatList', 'BulletFormatList',
            '|', 'CreateLink', '|', 'Undo', 'Redo'
        ]
    };

    const handleChange = () => {
        if (tipoEdicao == 'descricao') {
            setDescricaoEditor(rteObj.value)
        } else if (tipoEdicao == 'cenarioAtual') {
            setCenarioAtualEditor(rteObj.value)
        } else if (tipoEdicao == 'problemaIdentificadoCenarioAtual') {
            setProblemaCenarioAtualEditor(rteObj.value)
        } else if (tipoEdicao == 'objetivo') {
            setObjetivoEditor(rteObj.value)
        } else if (tipoEdicao == 'resultadoEsperado') {
            setResultadoEditor(rteObj.value)
        } else if (tipoEdicao == 'necessidadeNegocio') {
            setNecessidadeEditor(rteObj.value)
        } else if (tipoEdicao == 'oportunidade') {
            setOportunidadeEditor(rteObj.value)
        } else if (tipoEdicao == 'justificativaDataLimite') {
            setDataLimiteEditor(rteObj.value)
        } else if (tipoEdicao == 'justificativaEsforcoTotal') {
            setEsforcoEditor(rteObj.value)
        }
    }

    const salvar = () => {
        if (tipoEdicao == 'descricao') {
            FormDemanda.descricao = descricaoEditor
        } else if (tipoEdicao == 'cenarioAtual') {
            FormDemanda.cenarioAtual = cenarioAtualEditor
        } else if (tipoEdicao == 'problemaIdentificadoCenarioAtual') {
            FormDemanda.problemaIdentificadoCenarioAtual = problemaCenarioAtualEditor
        } else if (tipoEdicao == 'objetivo') {
            FormDemanda.objetivo = objetivoEditor
        } else if (tipoEdicao == 'resultadoEsperado') {
            FormDemanda.resultadoEsperado = resultadoEditor
        } else if (tipoEdicao == 'necessidadeNegocio') {
            FormDemanda.necessidadeNegocio = necessidadeEditor
        } else if (tipoEdicao == 'oportunidade') {
            FormDemanda.oportunidade = oportunidadeEditor
        } else if (tipoEdicao == 'justificativaDataLimite') {
            FormDemanda.justificativaDataLimite = dataLimiteEditor
        } else if (tipoEdicao == 'justificativaEsforcoTotal') {
            FormDemanda.justificativaEsforcoTotal = esforcoEditor
        }
        setRTFModal(false)
    }

    const fechar = () => {
        setRTFModal(false)
    }

    return (
        <div className="modal-rtf">
            <div className="modal-rtf-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Editor de texto</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => fechar()}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-rtf-content">
                    <RichTextEditorComponent value={valor} id="API" ref={(richtexteditor) => { rteObj = richtexteditor; }} toolbarSettings={toolbarSettings} onBlur={handleChange.bind(this)}>
                        <Inject services={[Toolbar, Link, HtmlEditor, Count, QuickToolbar]} />
                    </RichTextEditorComponent>
                </div>
                <div className="footer-modal-criar-demanda">
                    <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fechar()}>Cancelar</button>
                    <button className="btn-dash btn-criar" style={btnCriar} onClick={() => salvar()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalEditorTexto