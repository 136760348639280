import '../../App.css';
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { FaTimes, FaPlus } from 'react-icons/fa';
import { TbTrash, TbPencil } from "react-icons/tb";
import { SlOptionsVertical } from "react-icons/sl";
import { Tab, Tabs, Form, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';
import ModalEditorTexto from './ModalEditorTexto';
import { Stepper, Step, StepLabel, Box } from '@mui/material';

const steps = ['Visão Geral', 'Informações', 'Plano de Negócios', 'Área/Capacidades Atendidas', 'Competências', 'GUT/BSC', 'Comentários/Anexos'];

function ModalCriarDemanda({ closeModal, priorizacao }) {
    const { Tipos, Linhas, Fases, Categorias, Areas, ListaTipoDemanda, ListaLinhaAtendimento, ListaCategorizacao, ListaCapacidadePorAreaSwot,
        ListaArea, ListaFases, CriarDemanda, setForm, FormDemanda, setGut, Gut, ObterBSCPorCiclo, setgutBSC, GutBSC,
        setlinhaAtendimento, FormLink, setFormLink, FormComentario, setFormComentario, openModal, loading, setFormRisco, setError,
        Ciclos, ListaCiclos, ListaPlanoNegocio, PlanoNegocios, setPlanoNegocios, ObterDiretrizesItemsDoCiclo, setFormPlano, setStringDiretrizesItems, DiretrizesItemsDoCiclo, CapacidadePorAreaSwot, GutSwot, setGutSwot, ObterGutSwot, RTFModal, setRTFModal, ListaCompetencia, Competencias, setCapacidadePorAreaSwot, ListaAreaPorCiclo, AreaPorCiclo, delibComite, setDelibComite
    } = useContext(ApiContext)
    const { btnVer, btnCriar, btnPesquisar, corTerciaria, corPrimaria } = useContext(StyleContext)

    const selectCapRef = useRef()
    const selectCompRef = useRef()
    const selectAreaRef = useRef()

    const [GutGravidade, setGutGravidade] = useState(0)
    const [GutUrgencia, setGutUrgencia] = useState(0)
    const [GutTendencia, setGutTendencia] = useState(0)
    const [LinkAdd, setLinkAdd] = useState('')
    const [DescricaoAdd, setDescricaoAdd] = useState('')
    const [ComentarioAdd, setComentarioAdd] = useState('')
    const [BSC, setBSC] = useState(0)
    const [attLista, setattLista] = useState(false)
    const [obrigatorio, setObrigatorio] = useState('')
    const [cicloDemandaKey, setCicloDemandaKey] = useState("")
    const [cicloId, setCicloId] = useState("")
    const [areaDemandaKey, setAreaDemandaKey] = useState("")
    const [selectedItems, setSelectedItems] = useState([]);
    const [selectedCapacidades, setSelectedCapacidades] = useState([]);
    const [selectedCompetencias, setSelectedCompetencias] = useState([]);
    const [editorTexto, setEditorTexto] = useState('')
    const [valorTexto, setValorTexto] = useState('')
    const [areaKey, setAreaKey] = useState("")
    const [competenciaKey, setCompetenciaKey] = useState("")
    const [quantidade, setQuantidade] = useState("")
    const [capacidadeKey, setCapacidadeKey] = useState("")

    const [activeStep, setActiveStep] = useState(0)
    const [completedSteps, setCompletedSteps] = useState(new Set());

    const handleChange = (event) => {
        setForm({ ...FormDemanda, [event.target.name]: event.target.value })
        const texto = parseInt(event.target.value);
        if (event.target.name == "areaId") {
            setForm({ ...FormDemanda, [event.target.name]: JSON.parse(event.target.value).id })
            setAreaDemandaKey(JSON.parse(event.target.value).key)
        }
        if (event.target.name == "faseDemandaId") {
            setForm({ ...FormDemanda, [event.target.name]: JSON.parse(event.target.value).id })
        }
        if (event.target.name === "esforcoTotal" && texto > 100000) {
            setError('Esforço total não pode ser maior que 100000')
            setTimeout(() => {
                setError("");
            }, 3000);
            event.target.value = ""
            return;
        } else if (event.target.name === "esforcoTotal" && texto < 0) {
            setError('Esforço total não pode ser negativo.')
            setTimeout(() => {
                setError("");
            }, 3000);
            event.target.value = ""
            return;
        }

        if (event.target.name == "guT_Gravidade") {
            setGutGravidade(event.target.value)
        }
        if (event.target.name == "guT_Urgencia") {
            setGutUrgencia(event.target.value)
        }
        if (event.target.name == "guT_Tendencia") {
            setGutTendencia(event.target.value)
        }
        if (event.target.name == "diretrizEstrategicaId") {
            const valor = JSON.parse(event.target.value)
            setBSC(valor.peso)
        }
        if (event.target.name == "linhaAtendimento") {
            setlinhaAtendimento(event.target.value)
        }

    }

    const handleChangeEditor = (event) => {
        if (event.target.innerHTML === "<p><br></p>") {
            setForm({ ...FormDemanda, [event.target.id]: '' })
        }
        else {
            setForm({ ...FormDemanda, [event.target.id]: event.target.innerHTML })
        }
    }

    const handleCheckCapacidade = () => {
        const capacidade = JSON.parse(capacidadeKey)
        if (selectedCapacidades.some(item => item.id === capacidade.id)) {
            setSelectedCapacidades(selectedCapacidades.filter(item => item.id !== capacidade.id));
        } else {
            setSelectedCapacidades([...selectedCapacidades, { id: capacidade.id, descricao: capacidade.titulo, capacidadeNegocioKey: capacidade.key, areaDescricao: capacidade.area.descricao, areaKey: capacidade.area.key }]);
        }
        selectCapRef.current.value = 0
        selectAreaRef.current.value = 0
        setAreaKey("")
        setCapacidadeKey("")
    }

    const handleCheckCompetencia = () => {
        const competencia = JSON.parse(competenciaKey)
        if (selectedCompetencias.some(item => item.id === competencia.id)) {
            setSelectedCompetencias(selectedCompetencias.filter(item => item.id !== competencia.id));
        } else {
            setSelectedCompetencias([...selectedCompetencias, { id: competencia.id, descricao: competencia.descricao, competenciaKey: competencia.chave, quantidade: quantidade }]);
        }
        selectCompRef.current.value = 0
        setCompetenciaKey("")
        setQuantidade("")
    }

    const handleValorChange = (index, itemIndex, newValue) => {
        const updatedData = [...PlanoNegocios];
        const groupedDataCopy = groupDataByAvaliacaoPlanoNegocioId(updatedData);

        groupedDataCopy[index][itemIndex].valor = newValue;

        const formattedData = groupedDataCopy.flat().map(item => ({
            demandaKey: "",
            planoNegocioKey: item.chave,
            valor: item.valor
        }));

        setFormPlano(formattedData);
    };

    const handleCheckboxChange = (parcial, id) => {
        if (selectedItems.some(item => item.id === id)) {
            setSelectedItems(selectedItems.filter(item => item.id !== id));
        } else {
            setSelectedItems([...selectedItems, { id, parcial }]);
        }
    };

    const handleSelectChange = (e) => {
        const selectedChave = e.target.selectedOptions[0].getAttribute('data-chave');
        const selectedValue = e.target.selectedOptions[0].getAttribute('value');
        setCicloDemandaKey(selectedChave);
        setCicloId(selectedValue)
    };

    const AdicionarLink = () => {
        if (typeof LinkAdd === 'undefined' || LinkAdd === '') {
            setError("Campo link em branco.")
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        }
        let data = {
            nome: Cookies.get("Username"),
            link: LinkAdd,
            descricao: DescricaoAdd,
        }
        setFormLink(FormLink => [...FormLink, data])
        setLinkAdd("")
        setDescricaoAdd("")
        setattLista(!attLista)
    }

    const AdicionarComentario = () => {
        if (typeof ComentarioAdd === 'undefined' || ComentarioAdd === '') {
            setError("Campo comentário em branco.")
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        }
        const linkRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[a-zA-Z]{2,})/g;
        const isLink = linkRegex.test(ComentarioAdd);

        let comentarioRenderizado = ComentarioAdd;
        if (isLink) {
            comentarioRenderizado = ComentarioAdd.replace(linkRegex, (match) => {
                if (match.startsWith("http") || match.startsWith("www")) {
                    if (match.startsWith("www")) {
                        return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
                    }
                    return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
                } else {
                    return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
                }
            });
        }

        let data = {
            nome: Cookies.get("Username"),
            comentario: comentarioRenderizado,
        };

        if (typeof comentarioRenderizado === 'undefined') {
            setError("Vazio")
        }

        setFormComentario((FormComentario) => [...FormComentario, data]);
        setComentarioAdd("");
        setattLista(!attLista);
    }

    const ExcluirComentario = (props) => {
        FormComentario.splice(props, 1)
        setComentarioAdd("")
        setattLista(!attLista)
    }

    const ExcluirLink = (props) => {
        FormLink.splice(props, 1)
        setattLista(!attLista)
    }

    const ExcluirAvaliacao = (chave) => {
        const novosPlanoNegocios = PlanoNegocios.filter(item => item.chave !== chave);
        setPlanoNegocios(novosPlanoNegocios);
    };

    const abrirEditor = (tipo, valor) => {
        setRTFModal(true)
        setEditorTexto(tipo)
        setValorTexto(valor)
    }

    const ExcluirCapacidade = (id) => {
        if (selectedCapacidades.some(item => item.id === id)) {
            setSelectedCapacidades(selectedCapacidades.filter(item => item.id !== id));
        }
    }

    const ExcluirCompetencia = (id) => {
        if (selectedCompetencias.some(item => item.id === id)) {
            setSelectedCompetencias(selectedCompetencias.filter(item => item.id !== id));
        }
    }

    const groupDataByAvaliacaoPlanoNegocioId = (data) => {
        if (data != null && data != 'undefined') {
            const grouped = data?.reduce((acc, item) => {
                const id = item.avaliacaoPlanoNegocioId;
                if (!acc[id]) {
                    acc[id] = [];
                }
                acc[id].push(item);
                return acc;
            }, {});

            return Object.keys(grouped)
                .sort((a, b) => a - b)
                .map((key) => grouped[key]);
        }
    };

    const groupedData = groupDataByAvaliacaoPlanoNegocioId(PlanoNegocios);

    const fechar = () => {
        closeModal(false)
        setForm({})
        setGutSwot(0)
        setStringDiretrizesItems("")
    }

    const handleNext = () => {
        setCompletedSteps((prevCompleted) => {
            const newCompleted = new Set(prevCompleted);
            newCompleted.add(activeStep);
            return newCompleted;
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => {
        setActiveStep(step);
    };

    useEffect(() => {
        ListaTipoDemanda('S')
        ListaFases()
        ListaLinhaAtendimento('S')
        ListaCategorizacao('S')
        // ListaCapacidades('S')
        ListaArea()
        ListaCiclos()
        ListaPlanoNegocio()
        ListaCompetencia()
    }, [])

    useEffect(() => {
        if (cicloDemandaKey !== "") {
            ObterBSCPorCiclo(cicloDemandaKey)
            ObterDiretrizesItemsDoCiclo(cicloDemandaKey)
        }
    }, [cicloDemandaKey])

    useEffect(() => {
        if (cicloId !== "") {
            ListaAreaPorCiclo(cicloId)
        }
    }, [cicloId])

    useEffect(() => {
        if (selectedItems.length > 0) {
            let soma = 0
            const stringItens = selectedItems.map(item => item.id).join(",");
            soma = selectedItems.reduce((accumulator, item) => accumulator + item.parcial, 0);
            setStringDiretrizesItems(stringItens)
            setBSC(soma);
        } else {
            setStringDiretrizesItems("")
            setgutBSC(0)
        }
    }, [selectedItems])

    useEffect(() => {
        if (DiretrizesItemsDoCiclo.length > 0 && cicloDemandaKey !== "") {
            setSelectedItems([])
            let array = [];
            selectedItems.forEach(element => {
                const novaDiretriz = DiretrizesItemsDoCiclo.filter(item => item.id === element.id)
                if (novaDiretriz) {
                    element.parcial = novaDiretriz[0].parcial;
                    array.push(element)
                }
            })
            setSelectedItems(array);
        }
    }, [DiretrizesItemsDoCiclo])

    useEffect(() => {
        setGut(GutGravidade * GutUrgencia * GutTendencia)
    }, [GutGravidade, GutUrgencia, GutTendencia, Gut])

    useEffect(() => {
        if (openModal && PlanoNegocios && PlanoNegocios.length > 0) {
            setFormPlano(PlanoNegocios.map(item => ({
                demandaKey: "",
                planoNegocioKey: item.chave,
                valor: item.valor
            })));
            setFormComentario([]);
            setFormLink([]);
            setFormRisco([]);
        }
    }, [openModal, PlanoNegocios]);

    useEffect(() => {
        setForm({ ...FormDemanda, ["capacidades"]: selectedCapacidades })
    }, [selectedCapacidades])

    useEffect(() => {
        setForm({ ...FormDemanda, ["competencias"]: selectedCompetencias })
    }, [selectedCompetencias])

    useEffect(() => {
        if (areaDemandaKey != "" && cicloDemandaKey != "") {
            ObterGutSwot(areaDemandaKey, cicloDemandaKey)
        }
    }, [areaDemandaKey, cicloDemandaKey])

    useEffect(() => {
        setgutBSC(Gut / BSC || 0)
    }, [Gut, BSC])

    useEffect(() => {
        if (areaKey !== "" && cicloId !== "") {
            ListaCapacidadePorAreaSwot(cicloId, areaKey)
        } else {
            setCapacidadePorAreaSwot([]);
        }
    }, [areaKey])

    const StepIcon = ({ backgroundColor, icon, borderColor, color }) => {
        return (
            <div style={{
                backgroundColor: backgroundColor,
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: color,
                fontWeight: 'bold',
                border: '1px solid',
                borderColor: borderColor
            }}>
                {icon}
            </div>
        );
    };

    const handleTeste = (event) => {
        setDelibComite(event.target.value === "true"); // converte para booleano
    };

    return (
        <>
            {loading &&
                <div className="loading-screen">
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                </div>
            }
            {RTFModal &&
                <ModalEditorTexto tipoEdicao={editorTexto} valor={valorTexto} />
            }
            <div className="modal-nova">
                <div className="modal-nova-interno">
                    <div className="header-modal-nova">
                        <div>
                            <h1 className="h1-modal">Criar nova demanda</h1>
                        </div>
                        <div className="btn-close-modal" onClick={() => fechar()}>
                            <FaTimes />
                        </div>
                    </div>
                    <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ margin: '0px 40px 20px 40px' }}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const isActive = index === activeStep;
                                        const isCompleted = completedSteps.has(index);
                                        const isPreviouslyActivated = completedSteps.has(index) || index < activeStep;
                                        const isInactiveButWasActive = completedSteps.has(index) && index > activeStep;

                                        const backgroundColor = isInactiveButWasActive
                                            ? 'white'
                                            : isActive
                                                ? 'white'
                                                : isCompleted || isPreviouslyActivated
                                                    ? corPrimaria
                                                    : 'white';

                                        const borderColor = isInactiveButWasActive
                                            ? 'white'
                                            : isActive || isCompleted
                                                ? corPrimaria
                                                : '#D4D4D8';

                                        const color = isInactiveButWasActive
                                            ? 'white'
                                            : isActive
                                                ? corPrimaria
                                                : isCompleted || isPreviouslyActivated
                                                    ? 'white'
                                                    : '#D4D4D8';

                                        return (
                                            <Step key={label}>
                                                <StepLabel
                                                    onClick={() => handleStep(index)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: isActive || isPreviouslyActivated ? 'white' : 'inherit',
                                                        padding: '10px',
                                                    }}
                                                    StepIconComponent={({ icon }) => (
                                                        <StepIcon backgroundColor={backgroundColor} color={color} borderColor={borderColor} icon={icon} />
                                                    )}
                                                >
                                                    <span className='titulo-fases'>{label}</span>
                                                </StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </div>
                            <div>
                                {activeStep === 0 &&
                                    <div className='modal-swot'>
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Visão Geral</h1>
                                            <p>Preencha as informações gerais relacionadas a sua demanda:</p>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Título</Form.Label>
                                                        <Form.Control onChange={handleChange} value={FormDemanda?.titulo || ""} name="titulo" />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Área Solicitante</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="areaId">
                                                            <option value="0">Selecione</option>
                                                            {Areas &&
                                                                Areas?.map((s) => {
                                                                    return (
                                                                        <option key={s.id} selected={s.id == FormDemanda?.areaId ? true : false} value={JSON.stringify({ id: s.id, key: s.chave })}>{s.descricao}</option>
                                                                    )

                                                                })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Solicitante</Form.Label>
                                                        <Form.Control onChange={handleChange} value={FormDemanda?.solicitante || ""} name="solicitante" maxLength={100} />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Ciclos</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleSelectChange} onBlur={handleChange} value={FormDemanda?.cicloDemandaId} name="cicloDemandaId">
                                                            <option value="0">Selecione</option>
                                                            {
                                                                Ciclos?.filter(item => item.ativo === true)?.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} data-chave={item.chave}>{item.descricao}</option>
                                                                    )
                                                                })

                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={2}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Demanda Dependente ID</Form.Label>
                                                        <Form.Control onChange={handleChange} value={FormDemanda?.demandaDependenteId || ""} type="number" name="demandaDependenteId" maxLength={100}
                                                            onKeyDown={(e) => {
                                                                if (['e', 'E', '+', '-'].includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                                <p>Perspectivas estratégicas e Drivers</p>
                                                <Col md={9}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Perspectivas Estratégicas</Form.Label>
                                                        {cicloDemandaKey && DiretrizesItemsDoCiclo?.map((item, index) => {
                                                            const isChecked = selectedItems.some(selectedItem => selectedItem.id === item.id);
                                                            return (
                                                                <div style={{ display: 'flex', alignItems: 'center' }} key={index}>
                                                                    <input type='checkbox' style={{ marginRight: '10px' }} checked={isChecked}
                                                                        onChange={() => handleCheckboxChange(item.parcial, item.id)} />
                                                                    <p style={{ margin: 0 }}>{item.diretrizEstrategica.descricao} - {item.descricao}</p>
                                                                </div>
                                                            )
                                                        })}
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 1 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Informações</h1>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Time/Squad</Form.Label>
                                                        <Form.Select aria-label="Default select example" onBlur={handleChange} value={FormDemanda?.linhaAtendimentoId} name="linhaAtendimentoId">
                                                            <option value="0">Selecione</option>
                                                            {
                                                                Linhas?.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id}>{item.descricao}</option>
                                                                    )
                                                                })

                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Fase</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="faseDemandaId">
                                                            <option value="0">Selecione</option>
                                                            {Fases &&
                                                                Fases?.map((s) => {
                                                                    return (
                                                                        <option key={s.id} selected={s.id == FormDemanda?.faseDemandaId ? true : false} value={JSON.stringify({ id: s.id, descricao: s.descricao })}>{s.descricao}</option>
                                                                    )
                                                                })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col><Form.Group className="mb-3">
                                                    <Form.Label>Tipo da Demanda</Form.Label>
                                                    <Form.Select aria-label="Default select example" onBlur={handleChange} value={FormDemanda?.tipoDemandaId} name="tipoDemandaId">
                                                        <option value="0">Selecione</option>
                                                        {Tipos?.map((item) => {
                                                            return (
                                                                <option key={item.id} value={item.id} >
                                                                    {item.descricao}
                                                                </option>
                                                            )
                                                        })}
                                                    </Form.Select>
                                                </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Categorização estratégica</Form.Label>
                                                        <Form.Select aria-label="Default select example" onBlur={handleChange} value={FormDemanda?.categorizacaoEstrategicaDemandaId} name="categorizacaoEstrategicaDemandaId">
                                                            <option value="0">Selecione</option>
                                                            {Categorias?.map((item) => {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.descricao}
                                                                    </option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Label>Deliberação pelo comitê</Form.Label>
                                                {['radio'].map((type) => (
                                                    <div key={`inline-${type}`} className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            label="Sim"
                                                            value={true}
                                                            type="radio"
                                                            id="inline-radio-1"
                                                            onChange={handleTeste}
                                                            name="deliberacaoComite"
                                                            checked={delibComite === true}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Não"
                                                            value={false}
                                                            type="radio"
                                                            id="inline-radio-2"
                                                            onChange={handleTeste}
                                                            name="deliberacaoComite"
                                                            checked={delibComite === false}
                                                        />
                                                    </div>
                                                ))}
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Descrição
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={<Tooltip id="button-tooltip">
                                                                    Editor de texto
                                                                </Tooltip>}
                                                            ><button type='button' className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => abrirEditor('descricao', FormDemanda?.descricao)}><TbPencil /></button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <div
                                                            id="descricao"
                                                            contentEditable="true"
                                                            className="input-descricao"
                                                            onBlur={handleChangeEditor}
                                                            dangerouslySetInnerHTML={{ __html: FormDemanda?.descricao }}>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Resultado Esperado
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={<Tooltip id="button-tooltip">
                                                                    Editor de texto
                                                                </Tooltip>}
                                                            ><button type='button' className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => abrirEditor('resultadoEsperado', FormDemanda?.resultadoEsperado)}><TbPencil /></button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <div
                                                            id="resultadoEsperado"
                                                            contentEditable="true"
                                                            className="input-descricao"
                                                            onBlur={handleChangeEditor}
                                                            dangerouslySetInnerHTML={{ __html: FormDemanda?.resultadoEsperado }}>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Problema Identificado
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={<Tooltip id="button-tooltip">
                                                                    Editor de texto
                                                                </Tooltip>}
                                                            ><button type='button' className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => abrirEditor('problemaIdentificadoCenarioAtual', FormDemanda?.problemaIdentificadoCenarioAtual)}><TbPencil /></button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <div
                                                            id="problemaIdentificadoCenarioAtual"
                                                            contentEditable="true"
                                                            className="input-descricao"
                                                            onBlur={handleChangeEditor}
                                                            dangerouslySetInnerHTML={{ __html: FormDemanda?.problemaIdentificadoCenarioAtual }}>
                                                        </div>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 2 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Plano de Negócios</h1>
                                            <Row style={{ padding: '5px 0', borderBottom: '1px solid #ccc' }}>
                                                <Col md={4}><Form.Label>Avaliação</Form.Label></Col>
                                                <Col md={4}><Form.Label>Indicador</Form.Label></Col>
                                                <Col md={3}><Form.Label>Valor</Form.Label></Col>
                                                <Col md={1}></Col>
                                            </Row>
                                            {groupedData?.map((group, index) => (
                                                <div key={index}>
                                                    {group?.map((item, itemIndex) => {
                                                        let formControl;

                                                        switch (item.tipo) {
                                                            case 'DATA':
                                                                formControl = (
                                                                    <Form.Control
                                                                        type='date'
                                                                        onChange={(e) => handleValorChange(index, itemIndex, e.target.value)}
                                                                        value={item.valor}
                                                                    />
                                                                );
                                                                break;
                                                            case 'NUMERO':
                                                                formControl = (
                                                                    <Form.Control
                                                                        maxLength={20}
                                                                        type='number'
                                                                        onChange={(e) => handleValorChange(index, itemIndex, e.target.value)}
                                                                        value={item.valor}
                                                                        onKeyDown={(e) => {
                                                                            if (['e', 'E', '+', '-', ','].includes(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }} />
                                                                );
                                                                break;
                                                            case 'MOEDA':
                                                                formControl = (
                                                                    <Form.Control
                                                                        type='number'
                                                                        maxLength={20}
                                                                        step="0.01"
                                                                        onChange={(e) => handleValorChange(index, itemIndex, e.target.value)}
                                                                        value={item.valor}
                                                                        onKeyDown={(e) => {
                                                                            if (['e', 'E', '+', '-'].includes(e.key)) {
                                                                                e.preventDefault();
                                                                            }
                                                                        }} />
                                                                );
                                                                break;
                                                            case 'TEXTO':
                                                                formControl = (
                                                                    <Form.Control
                                                                        type='text'
                                                                        maxLength={100}
                                                                        onChange={(e) => handleValorChange(index, itemIndex, e.target.value)}
                                                                        value={item.valor}
                                                                    />
                                                                );
                                                                break;
                                                            case 'LISTA':
                                                                formControl = (
                                                                    <Form.Group>
                                                                        <Form.Select
                                                                            aria-label="Default select example"
                                                                            onChange={(e) => handleValorChange(index, itemIndex, e.target.value)}
                                                                            name="valor"
                                                                            value={item.valor}
                                                                        >
                                                                            <option value="-">Selecione</option>
                                                                            <option value="Muito baixo">Muito baixo</option>
                                                                            <option value="Baixo">Baixo</option>
                                                                            <option value="Médio">Médio</option>
                                                                            <option value="Alto">Alto</option>
                                                                            <option value="Muito alto">Muito alto</option>
                                                                        </Form.Select>
                                                                    </Form.Group>
                                                                );
                                                                break;
                                                            default:
                                                                formControl = null;
                                                                break;
                                                        }
                                                        return (
                                                            <Row key={item.chave} style={{ padding: '5px 0', borderBottom: '1px solid #ccc', alignItems: 'center' }}>
                                                                <Col md={4}>
                                                                    {itemIndex === 0 && (
                                                                        <div>{item.avaliacaoPlanoNegocio.descricao}</div>
                                                                    )}
                                                                </Col>
                                                                <Col md={4}>
                                                                    {item.indicador}
                                                                </Col>
                                                                <Col md={3}>
                                                                    {formControl}
                                                                </Col>
                                                                <Col md={1}>
                                                                    <div className="div-button">
                                                                        <button type="button" className="btn-del" onClick={() => ExcluirAvaliacao(item.chave)}><TbTrash /></button>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        );
                                                    })}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {activeStep === 3 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Áreas/Capacidades Atendidas</h1>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '25px' }}>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Área</Form.Label>
                                                        <Form.Select ref={selectAreaRef} value={areaKey} aria-label="Default select example" onChange={(e) => setAreaKey(e.target.value)}>
                                                            <option value={0}>Selecione</option>
                                                            {AreaPorCiclo &&
                                                                AreaPorCiclo?.map((s) => {
                                                                    return (
                                                                        <option key={s.id} value={s.key}>{s.descricao}</option>
                                                                    )

                                                                })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Capacidade de negócio</Form.Label>
                                                        <Form.Select ref={selectCapRef} aria-label="Default select example" onChange={(e) => setCapacidadeKey(e.target.value)}>
                                                            <option value="0">Selecione</option>
                                                            {CapacidadePorAreaSwot &&
                                                                CapacidadePorAreaSwot?.map((c) => {
                                                                    return (
                                                                        <option key={c.id} value={JSON.stringify(c)}>{c.titulo}</option>
                                                                    )

                                                                })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <button type="button" className="btn-dash btn-criar btn-add" style={btnCriar} onClick={() => handleCheckCapacidade()}><FaPlus /></button>
                                            </div>
                                            <hr />
                                            <Row>
                                                <div style={{ minHeight: '30vh', width: '100%' }}>
                                                    {selectedCapacidades.map((cap, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div key={index} className="content-comentario">
                                                                    <div className="div-comentario">
                                                                        <SlOptionsVertical style={{ marginRight: '15px' }} />
                                                                        <span><div dangerouslySetInnerHTML={{ __html: cap.areaDescricao }} /></span>
                                                                    </div>
                                                                    <div className="div-comentario">
                                                                        <span><div dangerouslySetInnerHTML={{ __html: cap.descricao }} /></span>
                                                                    </div>
                                                                    <div className="div-button">
                                                                        <button type="button" className="btn-del" onClick={() => ExcluirCapacidade(cap.id)}><TbTrash /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 4 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Competências</h1>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '25px' }}>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Competências</Form.Label>
                                                        <Form.Select ref={selectCompRef} aria-label="Default select example" onChange={(e) => setCompetenciaKey(e.target.value)}>
                                                            <option value="0">Selecione</option>
                                                            {Competencias &&
                                                                Competencias?.map((c) => {
                                                                    return (
                                                                        <option key={c.id} value={JSON.stringify(c)}>{c.descricao}</option>
                                                                    )

                                                                })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Quantidade</Form.Label>
                                                        <Form.Control type='number' value={quantidade} onChange={(e) => setQuantidade(e.target.value)} name="titulo"
                                                            onKeyDown={(e) => {
                                                                if (['e', 'E', '+', '-'].includes(e.key)) {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <button type="button" className="btn-dash btn-criar btn-add" style={btnCriar} onClick={() => handleCheckCompetencia()}><FaPlus /></button>
                                            </div>
                                            <hr />
                                            <Row>
                                                <div style={{ minHeight: '30vh', width: '100%' }}>
                                                    {selectedCompetencias.map((cap, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div key={index} className="content-comentario">
                                                                    <div className="div-comentario">
                                                                        <SlOptionsVertical style={{ marginRight: '15px' }} />
                                                                        <span><div dangerouslySetInnerHTML={{ __html: cap.descricao }} /></span>
                                                                    </div>
                                                                    <div className="div-comentario">
                                                                        <span><div dangerouslySetInnerHTML={{ __html: cap.quantidade }} /></span>
                                                                    </div>
                                                                    <div className="div-button">
                                                                        <button type="button" className="btn-del" onClick={() => ExcluirCompetencia(cap.id)}><TbTrash /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 5 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className="h1-modal">GUT/BSC</h1>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Card style={{ width: '50%' }}>
                                                    <Row style={{ alignItems: 'center' }}>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Gravidade</Form.Label>
                                                                <Form.Select aria-label="Default select example" onChange={handleChange} name="guT_Gravidade" className="mb-3">
                                                                    <option value={0}>Selecione</option>
                                                                    <option value="1">Sem gravidade</option>
                                                                    <option value="2">Pouco grave</option>
                                                                    <option value="3">Grave</option>
                                                                    <option value="4">Muito grave</option>
                                                                    <option value="5">Extremamente grave</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>Urgência</Form.Label>
                                                                <Form.Select aria-label="Default select example" onChange={handleChange} name="guT_Urgencia" className="mb-3">
                                                                    <option value={0}>Selecione</option>
                                                                    <option value="1">Longuíssimo prazo</option>
                                                                    <option value="2">Longo prazo</option>
                                                                    <option value="3">Médio prazo</option>
                                                                    <option value="4">Curto</option>
                                                                    <option value="5">Imediatamente</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                            <Form.Group>
                                                                <Form.Label>Tendência</Form.Label>
                                                                <Form.Select aria-label="Default select example" onChange={handleChange} name="guT_Tendencia">
                                                                    <option value={0}>Selecione</option>
                                                                    <option value="1">Desaparece</option>
                                                                    <option value="2">Reduz-se ligeiramente</option>
                                                                    <option value="3">Permanece</option>
                                                                    <option value="4">Aumenta</option>
                                                                    <option value="5">Piora muito</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <div className="container-gut mb-3">
                                                                <div>
                                                                    <p className='title-gut'>GUT</p>
                                                                </div>
                                                                <div>
                                                                    <p className='number-gut'>{Gut.toString()}</p>
                                                                </div>
                                                            </div>
                                                            <div className="container-gut mb-3">
                                                                <div>
                                                                    <p className='title-gut'>GUT/SWOT</p>
                                                                </div>
                                                                <div>
                                                                    <p className='number-gut'>{GutSwot.toString()}</p>
                                                                </div>
                                                            </div>
                                                            <div className="container-gut">
                                                                <div>
                                                                    <p className='title-gut'>GUT/BSC</p>
                                                                </div>
                                                                <div>
                                                                    <p className='number-gut'>{GutBSC.toFixed(2).toString()}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {activeStep === 6 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Comentários/Anexos</h1>
                                            <p>Adicione comentários ou links na sua demanda:</p>
                                            <div>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Comentário</Form.Label>
                                                    <Form.Control value={ComentarioAdd} as="textarea" rows={2} onChange={(e) => setComentarioAdd(e.target.value)} name="comentario" />
                                                </Form.Group>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarComentario()}><FaPlus style={{ marginRight: "5px" }} />Adicionar</button>
                                                </div>
                                                <div className="secao-comentario">
                                                    {
                                                        FormComentario?.map((item, index) => {
                                                            return (
                                                                <div key={index} className="content-comentario">
                                                                    <div>
                                                                        {/* <p className="p-title">{item.nome}</p> */}
                                                                        <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                                    </div>
                                                                    <div className="div-comentario">
                                                                        <p className="p-title">Comentário: </p>
                                                                        <span><div dangerouslySetInnerHTML={{ __html: item.comentario }} /></span>
                                                                    </div>
                                                                    <div className="div-button">
                                                                        <button type="button" className="btn-del" onClick={() => ExcluirComentario(index)}><TbTrash /></button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                            <div>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Link</Form.Label>
                                                    <Form.Control value={LinkAdd} onChange={(e) => setLinkAdd(e.target.value)} name="links" />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Descrição</Form.Label>
                                                    <Form.Control value={DescricaoAdd} as="textarea" rows={2} onChange={(e) => setDescricaoAdd(e.target.value)} name="descricaoLink" />
                                                </Form.Group>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <button className="btn-dash btn-pesquisar" style={btnPesquisar} type="button" onClick={() => AdicionarLink()}><FaPlus style={{ marginRight: "5px" }} />Adicionar</button>
                                                </div>
                                            </div>
                                            <div className="secao-link">
                                                {FormLink?.map((item, index) => {
                                                    return (
                                                        <div key={item.chaveLink} className="content-comentario">
                                                            <div>
                                                                {/* <p className="p-title">{item.nome}</p> */}
                                                                <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                            </div>
                                                            <div className="div-link">
                                                                <p className="p-title">Link: </p>
                                                                <span><p className="p-content"><a href={item.link} target="_blank">{item.link}</a></p></span>
                                                            </div>
                                                            <div className="div-comentario">
                                                                <p className="p-title">Comentário: </p>
                                                                <span><p>{item.descricao}</p></span>
                                                            </div>
                                                            <div className="div-button">
                                                                <button type="button" className="btn-del" onClick={() => ExcluirLink(index)}><TbTrash /></button>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <React.Fragment>
                            <Box className="footer-modal-nova">
                                {activeStep === 0 ?
                                    <button
                                        type="button" className="btn-dash btn-ver" style={btnVer}
                                        onClick={() => fechar()}
                                        sx={{ mr: 1 }}
                                    >
                                        Cancelar
                                    </button>
                                    :
                                    <button
                                        type="button" className="btn-dash btn-ver" style={btnVer}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Voltar
                                    </button>
                                }
                                <Box sx={{ flex: '1 1 auto' }} />
                                {activeStep === steps.length - 1 ? (
                                    <button
                                        type="button"
                                        className="btn-dash btn-criar"
                                        style={btnCriar}
                                        onClick={() => CriarDemanda(priorizacao)}
                                    >
                                        Salvar
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn-dash btn-criar"
                                        style={btnCriar}
                                        onClick={handleNext}
                                    >
                                        Próximo
                                    </button>
                                )}
                            </Box>
                        </React.Fragment>
                    </Box>
                </div>
            </div >
        </>
    )
}

export default ModalCriarDemanda