import { useContext } from 'react';
import { Col } from 'react-bootstrap'
import { FaStar, FaRegStar } from "react-icons/fa";
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, PieSeries, AccumulationTooltip, AccumulationDataLabel } from '@syncfusion/ej2-react-charts';
import { ApiContext } from '../../context/ApiContext';

export default function Impacto({ corFavorito, tab }) {
    const { ResumoPrioridades, AdicionarGraficoFavorito } = useContext(ApiContext)
    const onPointRender = (args) => {
        if (args.point.x.indexOf('Normal') > -1) {
            args.fill = '#16A34A';
        } else if (args.point.x.indexOf('Importante') > -1) {
            args.fill = '#FACC15';
        } else if (args.point.x.indexOf('Prioritária') > -1) {
            args.fill = '#F87171';
        }
        else {
            args.fill = '#7f84e8';
        }
    };
    return (
        <>
            <h2 className="h2-bsc">
                <button onClick={() => AdicionarGraficoFavorito("Impacto Objetivos do Negócio")} className='btn-grafico'>{corFavorito("Impacto Objetivos do Negócio") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                <span style={{ fontWeight: '500' }}>Impacto Objetivos do Negócio</span>
            </h2>
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'start' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                    <AccumulationChartComponent
                        pointRender={onPointRender}
                        tooltip={{ enable: true }}
                        width='700'
                        height='270'
                        id={`${tab}-impacto`}
                    >
                        <Inject services={[PieSeries, AccumulationTooltip, AccumulationDataLabel]} />
                        <AccumulationSeriesCollectionDirective>
                            <AccumulationSeriesDirective
                                dataSource={ResumoPrioridades}
                                yName='y'
                                xName='x'
                                type='Pie'
                                pointColorMapping='fill'
                                border={{ width: "5px" }}
                                innerRadius='60%'
                                dataLabel={{ visible: true, position: 'Outside', name: 'text', font: { fontWeight: '600' }, connectorStyle: { type: 'Curve' } }}
                            >
                            </AccumulationSeriesDirective>
                        </AccumulationSeriesCollectionDirective>
                    </AccumulationChartComponent>
                </div>
                <div className='span-bsc'>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div className='status-concluido' /> Normal
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className='status-prioritario'></span> Importante
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <span className='status-atraso'></span> Prioritária
                    </div>
                </div>
            </div>
        </>
    )
}