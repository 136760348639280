import '../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { Form, Col, Row, Modal } from 'react-bootstrap'
import { ApiContext } from '../../context/ApiContext';
import { FaPlus } from "react-icons/fa";
import { TbPencil, TbTrash } from "react-icons/tb";
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';
import moment from 'moment';
import ModalCadastrarBSC from '../../components/modal/ModalCadastrarBSC';
import BSC from '../BSC/BSC'

function ListaBSC() {

    const { loading, Success, Error, ListaBSC, BSCs, ListaCiclos, Ciclos, openModal, setOpenModal, setModalEditarBSC, modalEditarBSC, BSCPadrao } = useContext(ApiContext)
    const [cicloDemandaKey, setCicloDemandaKey] = useState("")
    const { btnCriar, h3Demanda, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];
    const [BSCkey, setBSCkey] = useState("")
    const [alterar, setAlterar] = useState("")

    L10n.load({
        'pt-BR': {
            grid: {
                EmptyRecord: 'Nenhum registro encontrado',
            },
        },
    });

    const editarBSC = (props) => {
        setModalEditarBSC(true)
        setBSCkey(props.chave)
        setAlterar(props.cicloDemanda.naoAlterarBSCSwot)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-ciclo'>
            <td className="td-ciclo"><input type="checkbox" onClick={() => BSCPadrao(props.chave, props.cicloDemanda.key)} checked={props.padrao}/></td>
            <td className="td-ciclo">{props.descricao ? props.descricao : "-"}</td>
            <td className="td-ciclo">{props.criacaoDataHora ? moment(props.criacaoDataHora).format("DD/MM/YYYY") : "-"}</td>
            <td className="td-ciclo">{props.alteracaoDataHora ? moment(props.alteracaoDataHora).format('L') + " às " + moment(props.alteracaoDataHora).format('LT') : "-"}</td>
            <td className='td-ciclo'>
                <button className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => editarBSC(props)}><TbPencil /></button>
            </td>
        </tr>);
    };

    useEffect(() => {
        setModalEditarBSC(false)
        ListaCiclos()
    }, [])

    useEffect(() => {
        if (cicloDemandaKey) {
            ListaBSC(cicloDemandaKey)
        }
    }, [cicloDemandaKey])

    return (
        <>
            <Header pagina={'BSC'}></Header>
            <MenuLateral></MenuLateral>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            {openModal &&
                <ModalCadastrarBSC closeModal={setOpenModal} cicloDemandaKey={cicloDemandaKey} />
            }
            {modalEditarBSC &&
                <BSC BSCkey={BSCkey} naoAlterarBSCSwot={alterar} />
            }
            {!modalEditarBSC &&
                <>
                    <div className="container-pages">
                        {loading &&
                            <div className="loading-screen">
                                <div className="loader-container">
                                    <div className="spinner"></div>
                                </div>
                            </div>
                        }

                        <div style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                            <Col lg={3}>
                                <Form.Group className="mb-3">
                                    <Form.Select onChange={(e) => setCicloDemandaKey(e.target.value)}>
                                        <option value="0">Selecionar ciclo</option>
                                        {Ciclos?.map((item) => {
                                            return (
                                                <option key={item.id} value={item.chave}>
                                                    {item.descricao}
                                                </option>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            {cicloDemandaKey &&
                                <button className='btn-dash btn-criar' style={btnCriar} onClick={() => setOpenModal(true)}><FaPlus style={{ marginRight: "10px" }} />Cadastrar</button>
                            }
                        </div>


                        {cicloDemandaKey ?
                            <div className='card card-bsc'>
                                <h3 className='h3-demanda' style={h3Demanda}>BSC cadastrados</h3>
                                <GridComponent locale='pt-BR' id="user" dataSource={BSCs} rowTemplate={gridTemplate} toolbar={toolbarOptions} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                                    <ColumnsDirective>
                                        <ColumnDirective headerText='BSC padrão' />
                                        <ColumnDirective field='descricao' headerText='BSCs' />
                                        <ColumnDirective field='alteracaoDataHora' headerText='Data de criação' />
                                        <ColumnDirective field='criacaoDataHora' headerText='Última atualização' />
                                        <ColumnDirective headerText='Opções' />
                                    </ColumnsDirective>
                                    <Inject services={[Page, Sort, Toolbar]} />
                                </GridComponent>
                            </div>
                            :
                            <div style={{ marginTop: "90px", display: "flex", flexDirection: "column", alignItems: "center" }}>
                                <h1 className='h1-sem-demandas'>Selecione um ciclo para o BSC</h1>
                                <h2 className='h2-sem-demandas'>Crie um novo ciclo ou contate seu supervisor.</h2>
                            </div>
                        }
                    </div>
                    <Footer></Footer>
                </>
            }
        </>
    );
}

export default ListaBSC;