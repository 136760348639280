import { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { StyleContext } from '../../context/StyleContext';
import { FaStar, FaRegStar } from "react-icons/fa";
import {
    Chart as ChartJS,
    ScatterController,
    LinearScale,
    PointElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { Scatter } from 'react-chartjs-2';
import { IoMdSad } from "react-icons/io";
import { IoWarningOutline, IoAlertCircleOutline } from "react-icons/io5";
import { CiDumbbell } from "react-icons/ci";

export default function Swot({ corFavorito, tab }) {
    const { AdicionarGraficoFavorito, GraficoSwotCiclo } = useContext(ApiContext)
    const { theme, chartRef, toggleTheme  } = useContext(StyleContext)
    
    // const theme = localStorage.getItem('theme') || 'light'

    // const theme = localStorage.getItem('theme') || 'light'

    // const pointRender1 = (args) => {
    //     args.fill = GraficoSwotCiclo?.forca.map((item) => item.cor)[args.point.index % 10];
    // };
    // const pointRender2 = (args) => {
    //     args.fill = GraficoSwotCiclo?.fraqueza.map((item) => item.cor)[args.point.index % 10];
    // };
    // const pointRender3 = (args) => {
    //     args.fill = GraficoSwotCiclo?.oportunidade.map((item) => item.cor)[args.point.index % 10];
    // };
    // const pointRender4 = (args) => {
    //     args.fill = GraficoSwotCiclo?.ameaca.map((item) => item.cor)[args.point.index % 10];
    // };

    const quadrants = {
        id: 'quadrants',
        beforeDraw(chart, args, options) {
            const { ctx, chartArea: { left, top, right, bottom }, scales: { x, y } } = chart;

            const midX = x.getPixelForValue((x.min + x.max) / 2);
            const midY = y.getPixelForValue((y.min + y.max) / 2);

            ctx.save();

            ctx.fillStyle = options.topLeft;
            ctx.fillRect(left, top, midX - left, midY - top);
            ctx.fillStyle = options.topRight;
            ctx.fillRect(midX, top, right - midX, midY - top);
            ctx.fillStyle = options.bottomRight;
            ctx.fillRect(midX, midY, right - midX, bottom - midY);
            ctx.fillStyle = options.bottomLeft;
            ctx.fillRect(left, midY, midX - left, bottom - midY);  

            ctx.fillStyle = options.textColor || '#000000';
            ctx.font = '12px sans-serif';

            ctx.fillText("Força", left + 10, top + 20);
            ctx.fillText("Oportunidade", midX + 10, top + 20);
            ctx.fillText("Fraqueza", midX + 10, midY + 20);
            ctx.fillText("Ameaça", left + 10, midY + 20);
    
            ctx.restore();
        },
    };

    ChartJS.register(ScatterController, LinearScale, PointElement, Tooltip, Legend, quadrants);

    function hexToRgba(hex, opacity = 1) {
        hex = hex.replace('#', '');

        let r = parseInt(hex.substring(0, 2), 16);
        let g = parseInt(hex.substring(2, 4), 16);
        let b = parseInt(hex.substring(4, 6), 16);

        return `rgba(${r}, ${g}, ${b}, ${opacity})`;
    }

    const gerarDatasets = (categoria, nome) => {
        return (categoria ?? []).map((item) => ({
            label: item.text,
            data: [
                { x: item.x, y: item.y },
            ],
            borderColor: item.cor,
            backgroundColor: hexToRgba(item.cor, 0.5),
            borderWidth: 2,
            pointRadius: 8,
        }));
    };

    const datasets = [
        ...gerarDatasets(GraficoSwotCiclo?.forca, "Força"),
        ...gerarDatasets(GraficoSwotCiclo?.fraqueza, "Fraqueza"),
        ...gerarDatasets(GraficoSwotCiclo?.oportunidade, "Oportunidade"),
        ...gerarDatasets(GraficoSwotCiclo?.ameaca, "Ameaça"),
    ];

    const data = {
        datasets,
    };

    const options = {
        plugins: {
            quadrants: {
                topLeft: "rgba(138, 181, 78, 0.1)",
                topRight: "rgba(254, 242, 242, 0.2)",
                bottomLeft: "rgba(219, 244, 255, 0.2)",
                bottomRight: "rgba(254, 252, 232, 0.2)",
                textColor: theme == 'dark' ? '#52525b' : '#ffffff'
            },
            legend: {
                position: 'right',
                align: 'start',
                labels: {
                    usePointStyle: true,
                    padding: 25,
                    color: theme == 'dark' ? '#52525b' : '#ffffff',
                },
            },
        },
        scales: {
            x: {
                display: false,
                min: 0,
                max: 5,
            },
            y: {
                display: false,
                min: 0,
                max: 10,
            },
        },
    };



    return (
        <>
            {GraficoSwotCiclo &&
                <>
                    <h2 className="h2-bsc">
                        <button onClick={() => AdicionarGraficoFavorito("SWOT")} className='btn-grafico'>{corFavorito("SWOT") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                        <span style={{ fontWeight: '500' }}>SWOT</span>
                    </h2>
                    <div style={{ display: 'flex', gap: '50px' }}>
                        <div style={{ width: '1000px' }}>
                            <Scatter ref={chartRef} data={data} options={options} plugins={[quadrants]} />
                        </div>
                        {/* <div style={{ width: '800px', height: '400px', position: 'absolute' }}>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '400px', height: '200px', padding: '15px 20px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><CiDumbbell style={{ fontSize: '25px', color: '#8AB54E' }} /> Forças</h2>
                                </div>
                                <div style={{ width: '400px', height: '200px', padding: '15px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><IoMdSad style={{ fontSize: '25px', color: '#EF4444' }} /> Fraquezas</h2>
                                </div>
                            </div>
                            <div style={{ display: 'flex' }}>
                                <div style={{ width: '400px', height: '200px', padding: '10px 20px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><IoAlertCircleOutline style={{ fontSize: '25px', color: '#0077E6' }} /> Oportunidades</h2>
                                </div>
                                <div style={{ width: '400px', height: '200px', padding: '10px 15px' }}>
                                    <h2 className='span-bsc' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><IoWarningOutline style={{ fontSize: '25px', color: '#F97316' }} /> Ameaças</h2>
                                </div>
                            </div>
                        </div> */}
                        {/* <div className='control-section'>
                            <div style={{ display: 'flex' }}>
                                <ChartComponent id={`${tab}-swot-1`} pointRender={pointRender1} background='#FEFCE833' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200' tooltip={{ enable: true, header: "<b>${point.tooltip}</b>", format: '<b>GUT: ${point.size}</b>'}}>
                                    <Inject services={[BubbleSeries, Tooltip]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective tooltipMappingName='toolTipMappingName' dataSource={GraficoSwotCiclo?.forca} type='Bubble' minRadius={3} maxRadius={3} border={{ width: 2 }} xName='x' yName='y' size='size'/>
                                    </SeriesCollectionDirective>
                                </ChartComponent>

                                <ChartComponent id={`${tab}-swot-2`} pointRender={pointRender2} background='#FFF7ED33' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200' tooltip={{ enable: true, header: "<b>${point.tooltip}</b>", format: '<b>GUT: ${point.size}</b>'}}>
                                    <Inject services={[BubbleSeries, Tooltip]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective tooltipMappingName='toolTipMappingName' dataSource={GraficoSwotCiclo?.fraqueza} type='Bubble' minRadius={3} maxRadius={3} border={{ width: 2 }} xName='x' yName='y' size='size'/>
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>

                            <div style={{ display: 'flex' }}>
                                <ChartComponent id={`${tab}-swot-3`} pointRender={pointRender3} background='#DBF4FF33' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200' tooltip={{ enable: true, header: "<b>${point.tooltip}</b>", format: '<b>GUT: ${point.size}</b>'}}>
                                    <Inject services={[BubbleSeries, Tooltip]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective tooltipMappingName='toolTipMappingName' dataSource={GraficoSwotCiclo?.oportunidade} type='Bubble' minRadius={3} maxRadius={3} border={{ width: 2 }} xName='x' yName='y' size='size'/>
                                    </SeriesCollectionDirective>
                                </ChartComponent>

                                <ChartComponent id={`${tab}-swot-4`} pointRender={pointRender4} background='#FEF2F233' primaryXAxis={{ minimum: 0, maximum: 5, interval: 5, visible: false }} primaryYAxis={{ minimum: 0, maximum: 10, interval: 5, visible: false }} width='400' height='200' tooltip={{ enable: true, header: "<b>${point.tooltip}</b>", format: '<b>GUT: ${point.size}</b>'}}>
                                    <Inject services={[BubbleSeries, Tooltip]} />
                                    <SeriesCollectionDirective width='400' height='200'>
                                        <SeriesDirective tooltipMappingName='toolTipMappingName' dataSource={GraficoSwotCiclo?.ameaca} type='Bubble' minRadius={3} maxRadius={3} border={{ width: 2 }} xName='x' yName='y' size='size' />
                                    </SeriesCollectionDirective>
                                </ChartComponent>
                            </div>
                        </div> */}
                        {/* <div>
                            {GraficoSwotCiclo.legendas && GraficoSwotCiclo.legendas.length > 0 &&
                                GraficoSwotCiclo?.legendas.map((item, index) => {
                                    return (
                                        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '15px' }}>
                                            <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: `${item.cor}` }}></div>
                                            <div className='span-bsc'>{item.legenda}</div>
                                        </div>
                                    )
                                })
                            }
                        </div> */}
                    </div>
                </>
            }
        </>
    )
}