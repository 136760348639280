import React, { createContext, useContext, useEffect, useState, useRef, useCallback } from "react";
import axios from "axios";
import baseurl from "../services/empresa/baseurl";
import { AuthContext } from "./AuthContext";
import Cookies from 'js-cookie'
import { StyleContext } from "./StyleContext";

export const ApiContext = createContext();

export const ApiContextProvider = ({ children }) => {

  const { Auth, setAuth } = useContext(AuthContext)

  const { loadingLayout, setLoadingLayout } = useContext(StyleContext)

  const [staticKey, setKey] = useState("");
  const [Empresa, setEmpresa] = useState();
  const [Empresas, setEmpresas] = useState();
  const [Cadastro, setCadastro] = useState("");
  const [Usuarios, setUsuarios] = useState();
  const [CadastroUsuario, setCadastroUsuario] = useState("");
  const [Ciclos, setCiclos] = useState();
  const [CadastroCiclo, setCadastroCiclo] = useState("");
  const [EditarCiclo, setEditarCiclo] = useState();
  const [modalEditarCiclo, setModalEditarCiclo] = useState(false);
  const [modalEnviarCiclo, setModalEnviarCiclo] = useState(false);
  const [CicloAtualKey, setCicloAtualKey] = useState("");
  const [Capacidades, setCapacidades] = useState();
  const [CapacidadesPorArea, setCapacidadesPorArea] = useState();
  const [ArvoreCapacidades, setArvoreCapacidades] = useState();
  const [Categorias, setCategorias] = useState();
  const [Fases, setFases] = useState();
  const [Linhas, setLinhas] = useState();
  const [linhaAtendimento, setlinhaAtendimento] = useState([]);
  const [Setores, setSetores] = useState();
  const [Tipos, setTipos] = useState();
  const [Dashboard, setDashboard] = useState();
  const [DashboardDados, setDashboardDados] = useState();
  const [DashboardFases, setDashboardFases] = useState();
  const [DashboardLinhas, setDashboardLinhas] = useState();
  const [DashboardImpacto, setDashboardImpacto] = useState();
  const [DemandaDadoDash, setDemandaDadoDash] = useState()
  const [DemandaAtualKey, setDemandaAtualKey] = useState("");
  const [FormDemanda, setForm] = useState("");
  const [Demandas, setDemandas] = useState();
  const [DemandaAtual, setDemandaAtual] = useState();
  const [modalEditarOpen, setModalEditar] = useState(false);
  const [EditarDemandaModal, setModalEditarDemanda] = useState(false);
  const [alterarGutBSC, setAlterarGutBSC] = useState(false)
  const [ExcluirDemandaModal, setModalExcluirDemanda] = useState(false);
  const [ExcluirCicloModal, setModalExcluirCiclo] = useState(false);
  const [TrocarRoleModal, setModalTrocarRole] = useState(false);
  const [DiretrizEstrategica, setDiretrizEstrategica] = useState();
  const [Prioridades, setPrioridades] = useState();
  const [ResumoPrioridades, setResumoPrioridades] = useState();
  const [Gut, setGut] = useState(0);
  const [GutBSC, setgutBSC] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalCriarDemanda, setModalCriarDemanda] = useState(false);
  const [GutGravidade, setGutGravidade] = useState(0);
  const [GutUrgencia, setGutUrgencia] = useState(0);
  const [GutTendencia, setGutTendencia] = useState(0);
  const [BSC, setBSC] = useState(0);
  const [BSCFiltro, setBSCFiltro] = useState(0);
  const [DemandasCiclo, setDemandasCiclo] = useState([]);
  const [FormSelecionarCiclo, setFormSelecionarCiclo] = useState("");
  const [AddComentario, setAddComentario] = useState("");
  const [Comentario, setComentario] = useState();
  const [AddComentarioModal, setAddComentarioModal] = useState(false);
  const [AddLink, setAddLink] = useState("");
  const [Link, setLink] = useState();
  const [AddRisco, setAddRisco] = useState("");
  const [Risco, setRisco] = useState();
  const [RiscoEditar, setRiscoEditar] = useState(false)
  const [FiltroDemanda, setFiltroDemanda] = useState('')
  const [Error, setError] = useState("");
  const [Success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [BSCDados, setBSCDados] = useState('')
  const [BSCPorCiclo, setBSCPorCiclo] = useState('')
  const [DiretrizEstrategicaPorCiclo, setDiretrizEstrategicaPorCiclo] = useState('')
  const [PrioridadesPorCiclo, setPrioridadesPorCiclo] = useState('')
  const [ResumoPrioridadesPorCiclo, setResumoPrioridadesPorCiclo] = useState('')
  const [FormLink, setFormLink] = useState([]);
  const [FormComentario, setFormComentario] = useState([]);
  const [FormRisco, setFormRisco] = useState([]);
  const [UserId, setIdUser] = useState('')
  const [NomeUser, setNomeUser] = useState('')
  const [AdminUser, setAdminUser] = useState(false)
  const [SupervisorUser, setSupervisorUser] = useState(false)
  const [LeitorUser, setLeitorUser] = useState(false)
  const [show, setShow] = useState(true)
  const comentarioRef = useRef(null);
  const linkRef = useRef(null);
  const descricaoRef = useRef(null);
  const riscoJustificativaRef = useRef(null);
  const mitigacaoJustificativaRef = useRef(null);
  const [arraySelecionarDemandaCiclo, setArraySelecionarDemandaCiclo] = useState([])
  const [Descricao, setDescricao] = useState('')
  const [CenarioAtual, setCenarioAtual] = useState('')
  const [ProblemaCenarioAtual, setProblemaCenarioAtual] = useState('')
  const [Objetivo, setObjetivo] = useState('')
  const [Resultado, setResultado] = useState('')
  const [Necessidade, setNecessidade] = useState('')
  const [Oportunidade, setOportunidade] = useState('')
  const [DataLimite, setDataLimite] = useState('')
  const [Esforco, setEsforco] = useState('')
  const [ExcluirDemandaCicloModal, setExcluirDemandaCicloModal] = useState(false)
  const [demandaCicloAtual, setDemandaCicloAtual] = useState('')
  const [AddDemandaCiclo, setAddDemandaCiclo] = useState(false)
  const [RTFModal, setRTFModal] = useState(false)
  const [CicloDem, setCicloDem] = useState()
  const [Dem, setDem] = useState()
  const [CancelarDemandaModal, setCancelarDemandaModal] = useState(false)
  const [MotivoCancelar, setMotivoCancelar] = useState('')
  const [ConcluirDemandaModal, setConcluirDemandaModal] = useState(false)
  const [Compartilhar, setCompartilhar] = useState('')
  const [CompartilharDemandaModal, setCompartilharDemandaModal] = useState(false)
  const [DemCompartilhada, setDemCompartilhada] = useState()
  const [ListaDemCompartilhada, setListaDemCompartilhada] = useState()
  const [KeyCompartilhar, setKeyCompartilhar] = useState('')
  const [UrlCompartilhar, setUrlCompartilhar] = useState("")
  const [Swot, setSwot] = useState('')
  const [ForcaSwot, setForcaSwot] = useState([])
  const [FraquezaSwot, setFraquezaSwot] = useState([])
  const [OportunidadeSwot, setOportunidadeSwot] = useState([])
  const [AmeacaSwot, setAmeacaSwot] = useState([])
  const [SwotLista, setSwotLista] = useState('')
  const [SwotItemLista, setSwotItemLista] = useState([])
  const [SwotItem, setSwotItem] = useState('')
  const [SwotAtualKey, setSwotAtualKey] = useState('')
  const [SwotObter, setSwotObter] = useState()
  const [AddSwotItem, setAddSwotItem] = useState()
  const [SwotMatriz, setSwotMatriz] = useState('')
  const [SwotRelatorio, setSwotRelatorio] = useState('')
  const [delibComite, setDelibComite] = useState(false)
  const [forcaItem, setForcaItem] = useState({
    capacidadeNegocioDemandaKey: "",
    gravidade: "",
    urgencia: "",
    tendencia: "",
    gut: "",
    tipo: "0"
  });
  const [fraquezaItem, setFraquezaItem] = useState({
    capacidadeNegocioDemandaKey: "",
    gravidade: "",
    urgencia: "",
    tendencia: "",
    gut: "",
    tipo: "0"
  });
  const [oportunidadeItem, setOportunidadeItem] = useState({
    capacidadeNegocioDemandaKey: "",
    gravidade: "",
    urgencia: "",
    tendencia: "",
    gut: "",
    tipo: "0"
  });
  const [ameacaItem, setAmeacaItem] = useState({
    capacidadeNegocioDemandaKey: "",
    gravidade: "",
    urgencia: "",
    tendencia: "",
    gut: "",
    tipo: "0"
  });
  const [CadastroSistema, setCadastroSistema] = useState("")
  const [Sistemas, setSistemas] = useState("")
  const [EdicaoSistema, setEdicaoSistema] = useState("")
  const [SistemaAtualKey, setSistemaAtualKey] = useState("")
  const [modalEditarSistema, setModalEditarSistema] = useState(false);
  const [modalExcluirSistema, setModalExcluirSistema] = useState(false);

  const [CadastroArea, setCadastroArea] = useState("")
  const [Areas, setAreas] = useState("")
  const [AreaItens, setAreaItens] = useState("")
  const [EdicaoArea, setEdicaoArea] = useState("")
  const [AreaAtualKey, setAreaAtualKey] = useState("")
  const [modalEditarArea, setModalEditarArea] = useState(false);
  const [modalExcluirArea, setModalExcluirArea] = useState(false);
  const [CadastroCargo, setCadastroCargo] = useState("")
  const [Cargos, setCargos] = useState("")
  const [EdicaoCargo, setEdicaoCargo] = useState("")
  const [CargoAtualKey, setCargoAtualKey] = useState("")
  const [modalEditarCargo, setModalEditarCargo] = useState(false);
  const [modalExcluirCargo, setModalExcluirCargo] = useState(false);
  const [CadastroPessoa, setCadastroPessoa] = useState("")
  const [Pessoas, setPessoas] = useState("")
  const [PessoasPorCargo, setPessoasPorCargo] = useState("")
  const [EdicaoPessoa, setEdicaoPessoa] = useState("")
  const [PessoaAtualKey, setPessoaAtualKey] = useState("")
  const [modalEditarPessoa, setModalEditarPessoa] = useState(false);
  const [modalExcluirPessoa, setModalExcluirPessoa] = useState(false);
  const [CadastroCompetencia, setCadastroCompetencia] = useState("")
  const [Competencias, setCompetencias] = useState("")
  const [EdicaoCompetencia, setEdicaoCompetencia] = useState("")
  const [CompetenciaAtualKey, setCompetenciaAtualKey] = useState("")
  const [modalEditarCompetencia, setModalEditarCompetencia] = useState(false);
  const [modalExcluirCompetencia, setModalExcluirCompetencia] = useState(false);

  const [CicloCadastro, setCicloCadastro] = useState(false);
  const [modalCriarCiclo, setModalCriarCiclo] = useState(false);

  const [SistemaCapacidade, setSistemaCapacidade] = useState("")
  const [CapacidadeAtualKey, setCapacidadeAtualKey] = useState("")

  const [PlanoNegocio, setPlanoNegocio] = useState("")
  const [PlanoNegocios, setPlanoNegocios] = useState()
  const [TipoPlanoNegocio, setTipoPlanoNegocio] = useState()
  const [EdicaoPlanoNegocio, setEdicaoPlanoNegocio] = useState("")
  const [PlanoNegocioAtualKey, setPlanoNegocioAtualKey] = useState("")
  const [modalEditarPlanoNegocio, setModalEditarPlanoNegocio] = useState(false);
  const [modalExcluirPlanoNegocio, setModalExcluirPlanoNegocio] = useState(false);

  const [PlanoNegocioAvaliacao, setPlanoNegocioAvaliacao] = useState("")
  const [Avaliacao, setAvaliacao] = useState()
  const [PlanoNegocioAvaliacaoAtualKey, setPlanoNegocioAvaliacaoAtualKey] = useState("")

  const [PlanoDemanda, setPlanoDemanda] = useState()

  const [FormPlano, setFormPlano] = useState([])
  const [ItensArea, setItensArea] = useState([])

  const [DiretrizesItemsDoCiclo, setDiretrizesItemsDoCiclo] = useState([])

  const [stringDiretrizesItems, setStringDiretrizesItems] = useState("")

  const [GutSwot, setGutSwot] = useState(0)
  const [CapacidadeDemanda, setCapacidadeDemanda] = useState([])
  const [Capacidade, setCapacidade] = useState([])
  const [EdicaoCapacidade, setEdicaoCapacidade] = useState([])

  const [CompetenciaDemanda, setCompetenciaDemanda] = useState([])

  const [BSCs, setBSCs] = useState([])
  const [CadastroBSC, setCadastroBSC] = useState([])
  const [modalEditarBSC, setModalEditarBSC] = useState(false)
  const [showModalArvoreDeCapacidades, setModalArvoreDeCapacidades] = useState(false)
  const [showModalCustoxdemanda, setModalCustoxdemanda] = useState(false)
  const [ArvoreDeCapacidades, setArvoreDeCapacidades] = useState([])
  const [CustoXDemanda, setCustoXDemanda] = useState([])
  const [DemandaXCapacidade, setDemandaXCapacidade] = useState([])
  const [SistemaXDemanda, setSistemaXDemanda] = useState([])
  const [BscXSwotXGut, setBscXSwotXGut] = useState([])

  const [SwotPorCiclo, setSwotPorCiclo] = useState([])
  const [DemandaPorCiclo, setDemandaPorCiclo] = useState([])
  const [CapacidadePorAreaSwot, setCapacidadePorAreaSwot] = useState([])
  const [AreaPorCiclo, setAreaPorCiclo] = useState([])

  const [GraficoSwotCiclo, setGraficoSwotCiclo] = useState([])

  const [Favoritos, setFavoritos] = useState([])

  const EmpresaKey = staticKey == "" ? Cookies.get("Key") : staticKey;

  const getEmpresa = () => {
    setEmpresa(Cookies.get("Empresa"));
  }

  const CadastrarEmpresa = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    try {
      if (Cadastro === "") {
        setError("Formulário vazio.");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.razaoSocial === "undefined") {
        setError("Razão social não informada");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.fantasia === "undefined") {
        setError("Fantasia não informada");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.cnpj === "undefined") {
        setError("CNPJ não informado");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.telefone === "undefined") {
        setError("Telefone não informado");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.email === "undefined") {
        setError("E-mail não informado");
        setTimeout(() => {
          setError("");
        }, 2000);
        return;
      }
      if (typeof Cadastro.endereco === "undefined") {
        setError("Endereço não informado");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.enderecoNumero === "undefined") {
        setError("Número não informado");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.enderecoBairro === "undefined") {
        setError("Bairro não informado");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.enderecoCidade === "undefined") {
        setError("Cidade não informada");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.enderecoUF === "undefined") {
        setError("Estado não informado");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof Cadastro.enderecoCEP === "undefined") {
        setError("CEP não informado");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
    } catch (error) {
      error.response.data.map((er) => {
        setError(`${er.campo}: ${er.mensagem}`)
      })
    }
    const data = {
      key: Cadastro.key,
      razaoSocial: Cadastro.razaoSocial,
      fantasia: Cadastro.fantasia,
      cnpj: Cadastro.cnpj,
      telefone: Cadastro.telefone,
      emailEmpresa: Cadastro.emailEmpresa,
      emailLogin: Cadastro.emailLogin,
      nome: Cadastro.nome,
      endereco: Cadastro.endereco,
      enderecoNumero: Cadastro.enderecoNumero,
      enderecoBairro: Cadastro.enderecoBairro,
      enderecoCidade: Cadastro.enderecoCidade,
      enderecoUF: Cadastro.enderecoUF,
      enderecoCEP: Cadastro.enderecoCEP,
    };
    return axios({
      url: baseurl + "Empresa/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        setSuccess("Empresa cadastrada com sucesso.");
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
        ListaEmpresas()
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setAuth(false)
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaEmpresas = useCallback(async () => {
    let arrayEmpresas = [];
    let objEmpresas = {};
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Empresa/Listar?somenteAtivas=S",
      method: "GET",
      timeout: 15000,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const emprises = eval(response.data);
        emprises.forEach((e) => {
          objEmpresas = {
            id: e.id,
            value: e.fantasia,
            label: e.razaoSocial,
            key: e.key,
            cnpj: e.cnpj,
            telefone: e.telefone,
            emailEmpresa: e.emailEmpresa,
            emailLogin: e.emailLogin,
            endereco: e.endereco,
            nome: e.nome,
            enderecoNumero: e.enderecoNumero,
            enderecoBairro: e.enderecoBairro,
            enderecoCidade: e.enderecoCidade,
            enderecoUF: e.enderecoUF,
            enderecoCEP: e.enderecoCEP,
            ativo: e.ativo,
          };
          arrayEmpresas.push(objEmpresas);
        })
        setEmpresas(arrayEmpresas);
        if (arrayEmpresas?.length == 1) {
          Cookies.set('Empresa', (arrayEmpresas.map(item => item.value))[0])
          Cookies.set("Key", (arrayEmpresas.map(item => item.key))[0])
          setKey((arrayEmpresas.map(item => item.key))[0])
        }
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setAuth(false)
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          return
        }
        return Promise.reject(error);
      })

  })

  const CadastrarUsuario = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (CadastroUsuario === "") {
      setLoading(false)
      setError("Formulário vazio.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroUsuario.nome === "undefined") {
      setLoading(false)
      setError("Nome não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroUsuario.email === "undefined") {
      setLoading(false)
      setError("E-mail não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroUsuario.admin === "undefined" && typeof CadastroUsuario.supervisor === "undefined" && CadastroUsuario.somentevisualizar === "undefined") {
      setLoading(false)
      setError("Função não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      EmpresaKey: EmpresaKey,
      nome: CadastroUsuario.nome,
      email: CadastroUsuario.email,
      senha: CadastroUsuario.senha,
      ativo: CadastroUsuario.ativo,
      supervisor: CadastroUsuario.supervisor,
      somentevisualizar: CadastroUsuario.somentevisualizar,
      admin: CadastroUsuario.admin
    };
    return axios({
      url: baseurl + "Autenticacao/CriarUsuario",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaUsuarios()
        setSuccess("Usuário cadastrado com sucesso!");
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaUsuarios = useCallback(async () => {
    let objUsuarios = {};
    let arrayUsuarios = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Empresa/ListarUsuarios?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          objUsuarios = {
            nome: e.nome,
            dataCadastro: e.dataCadastro,
            ultimoLogin: e.ultimoLogin,
            ativo: e.ativo,
            admin: e.admin,
            supervisor: e.supervisor,
            somenteVisualizar: e.somenteVisualizar,
            politicaPrivacidadeDataAceite: e.politicaPrivacidadeDataAceite,
            politicaPrivacidadeVersao: e.politicaPrivacidadeVersao,
            id: e.id,
            userName: e.userName,
            normalizedUserName: e.normalizedUserName,
            email: e.email,
            normalizedEmail: e.normalizedEmail,
            emailConfirmed: e.emailConfirmed,
            passwordHash: e.passwordHash,
            securityStamp: e.securityStamp,
            concurrencyStamp: e.concurrencyStamp,
            phoneNumber: e.phoneNumber,
            phoneNumberConfirmed: e.phoneNumberConfirmed,
            twoFactorEnabled: e.twoFactorEnabled,
            lockoutEnd: e.lockoutEnd,
            lockoutEnabled: e.lockoutEnabled,
            accessFailedCount: e.accessFailedCount
          };
          arrayUsuarios.push(objUsuarios);
        });
        setUsuarios(arrayUsuarios);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const StatusUsuario = async (id, status) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Autenticacao/Ativo?UsuarioId=" + id + "&status=" + status,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        ListaUsuarios()
        if (status) {
          setSuccess("Usuário ativado com sucesso.")
        } else {
          setSuccess("Usuário inativado com sucesso.")
        }
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const CadastrarCiclo = async () => {
    const token = Cookies.get("Token")
    let data = {
      demandaKey: DemandaAtualKey,
      cicloDemandaKey: CadastroCiclo.cicloDemandaKey,
      cicloOrdem: CadastroCiclo.cicloOrdem != '' ? CadastroCiclo.cicloOrdem : 0,
    }
    try {
      if (CadastroCiclo === '') {
        setError("Formulário vazio.");
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      }
      if (!CadastroCiclo.cicloDemandaKey) {
        setError("Ciclo obrigatório.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      }
    } catch (error) {
    }
    return axios({
      url: baseurl + "Demanda/AdicionarCiclo",
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      data: data
    })
      .then((response) => {
        ListaDemanda(true, "PRIORIZACAO")
        setAddDemandaCiclo(false)
        setSuccess("Ciclo adicionado com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        setAddDemandaCiclo(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const EditarCiclos = async () => {
    let dataInicialISO = '';
    let dataFinalISO = '';
    const token = Cookies.get("Token");
    setLoading(true)
    if (typeof EditarCiclo.dataInicial != "undefined") {
      dataInicialISO = new Date(EditarCiclo.dataInicial).toISOString();
    }
    if (typeof EditarCiclo.dataFinal != "undefined") {
      dataFinalISO = new Date(EditarCiclo.dataFinal).toISOString();
    }
    const data = {
      empresaKey: EmpresaKey,
      descricao: EditarCiclo.descricao,
      dataInicial: dataInicialISO,
      dataFinal: dataFinalISO,
      observacao: EditarCiclo.observacao,
      naoAlterarBSCSwot: EditarCiclo.naoAlterarBSCSwot
    };
    return axios({
      url: baseurl + "CicloDemanda/Alterar?key=" + CicloAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCiclos();
        setSuccess("Ciclo editado com sucesso!");
        setModalEditarCiclo(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ListaCiclos = useCallback(async () => {
    setLoading(true)
    let arrayCiclos = [];
    let objCiclos = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "CicloDemanda/Listar?empresaKey=" + `${EmpresaKey}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const ciclos = eval(response.data);
        ciclos.forEach((e) => {
          objCiclos = {
            id: e.id,
            descricao: e.descricao,
            dataInicial: e.dataInicial,
            dataFinal: e.dataFinal,
            observacao: e.observacao,
            usuarioNome: e.usuario != null ? e.usuario.nome : '-',
            chave: e.key,
            ativo: e.ativo,
            naoAlterarBSCSwot: e.naoAlterarBSCSwot
          };
          arrayCiclos.push(objCiclos);
        });
        setCiclos(arrayCiclos);
        return Promise.resolve()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaDemandaCiclo = useCallback(async () => {
    let arrayDemandasCiclo = [];
    let objDemandasCiclo = {};
    const token = Cookies.get("Token");
    let data = {
      empresaKey: EmpresaKey,
      linhaAtendimentoId: FormSelecionarCiclo.linhaAtendimentoId,
      setorGerenciaDemandaId: FormSelecionarCiclo.setorGerenciaDemandaId,
      somenteAtrasadas: FormSelecionarCiclo.somenteAtrasadas,
      guT_BSC_Pontuacao_Inicial: FormSelecionarCiclo.guT_BSC_Pontuacao_Inicial,
      guT_BSC_Pontuacao_Final: FormSelecionarCiclo.guT_BSC_Pontuacao_Final,
    };

    return axios({
      url: baseurl + "Demanda/SelecionarDemandasCiclo",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data
    })
      .then((response) => {
        const ciclos = eval(response.data);
        ciclos.forEach((e) => {
          objDemandasCiclo = {
            keyDemandaCiclo: e.key,
            descricao: e.descricao,
            titulo: e.titulo,
            linhaAtendimentoId: e.linhaAtendimentoId,
            linhaAtendimentoDescricao: e.linhaAtendimentoDemanda.descricao,
            setorGerenciaDemandaId: e.setorGerenciaDemandaId,
            setorGerenciaDemandaDescricao: e.setorGerenciaDemanda.descricao,
            guT_BSC_Pontuacao: e.guT_BSC_Pontuacao,
          };
          arrayDemandasCiclo.push(objDemandasCiclo);
        });
        setDemandasCiclo(arrayDemandasCiclo);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaCapacidades = useCallback(async (ativo) => {
    let arrayCapacidades = [];
    let objCapacidades = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "CapacidadeNegocioDemanda/Listar?empresaKey=" + `${EmpresaKey}` + "&somenteAtivas=" + ativo,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const capacities = eval(response.data);
        capacities.forEach((e) => {
          objCapacidades = {
            id: e.id,
            descricao: e.descricao,
            ativo: e.ativo,
            chaveCadastro: e.key,
            capacidadeNegocioDemandaPai: e.capacidadeNegocioDemandaPai == null ? "" : e.capacidadeNegocioDemandaPai,
            titulo: e.titulo,
            nivel: e.nivel,
            relevancia: e.relevancia,
            area: e.area,
            pessoa: e.pessoa,
            arquiteto: e.arquiteto,
          };
          arrayCapacidades.push(objCapacidades);
        });
        setCapacidades(arrayCapacidades);
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaCapacidadesPorArea = useCallback(async (ativo, key) => {
    let arrayCapacidades = [];
    let objCapacidades = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "CapacidadeNegocioDemanda/ListarPorArea?empresaKey=" + `${EmpresaKey}` + "&areaKey=" + key + "&somenteAtivas=" + ativo,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const capacities = eval(response.data);
        capacities.forEach((e) => {
          objCapacidades = {
            id: e.id,
            descricao: e.descricao,
            ativo: e.ativo,
            chaveCadastro: e.key,
            capacidadeNegocioDemandaPai: e.capacidadeNegocioDemandaPai == null ? "" : e.capacidadeNegocioDemandaPai,
            titulo: e.titulo,
            nivel: e.nivel,
            relevancia: e.relevancia,
            area: e.area,
            pessoa: e.pessoa,
            arquiteto: e.arquiteto,
          };
          arrayCapacidades.push(objCapacidades);
        });
        setCapacidadesPorArea(arrayCapacidades);
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaCapacidadePorAreaSwot = useCallback(async (cicloId, areaKey) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Swot/ListarPorArea?empresaKey=" + `${EmpresaKey}` + "&cicloId=" + cicloId + "&areaKey=" + areaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setCapacidadePorAreaSwot(response.data);
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaArvoreCapacidades = useCallback(async (ativo) => {
    setLoading(true)
    let arrayCapacidades = [];
    let objCapacidades = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "CapacidadeNegocioDemanda/Arvore?empresaKey=" + `${EmpresaKey}` + "&somenteAtivas=" + ativo,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const capacities = eval(response.data);
        capacities.forEach((e) => {
          objCapacidades = {
            id: e.id,
            descricao: e.descricao,
            filhos: e.filhos,
            key: e.key,
            titulo: e.titulo, 
            nivel: e.nivel
          };
          arrayCapacidades.push(objCapacidades);
        });
        setArvoreCapacidades(arrayCapacidades);
      })
      .catch((error) => {
        setLoading(false)
        console.log(error)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaCategorizacao = useCallback(async (ativo) => {
    let arrayCategorias = [];
    let objCategorias = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "CategorizacaoEstrategicaDemanda/Listar?empresaKey=" + `${EmpresaKey}` + "&somenteAtivas=" + ativo,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const categories = eval(response.data);
        categories.forEach((e) => {
          objCategorias = {
            id: e.id,
            descricao: e.descricao,
            ativo: e.ativo,
            chaveCadastro: e.key,
          };
          arrayCategorias.push(objCategorias);
        });
        setCategorias(arrayCategorias);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaFases = useCallback(async () => {
    let arrayFases = [];
    let objFases = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "FasesDemanda/Listar?empresaKey=" + `${EmpresaKey}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const phases = eval(response.data);
        phases.forEach((e) => {
          objFases = {
            id: e.id,
            descricao: e.descricao,
            ativo: e.ativo,
            chaveCadastro: e.key,
          };
          arrayFases.push(objFases);
        });
        setFases(arrayFases);
        return Promise.resolve(arrayFases)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setAuth(false)
          return
        }
        // error.response.data.map((er) => {
        //   setError(`${er.campo}: ${er.mensagem}`)
        // })
        console.log(error)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaLinhaAtendimento = useCallback(async (ativo) => {
    let arrayLinhas = [];
    let objLinhas = {};
    const token = Cookies.get("Token");
    return axios({
      url:
        baseurl +
        "LinhaAtendimentoDemanda/Listar?empresaKey=" + `${EmpresaKey}` + "&somenteAtivas=" + ativo,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const lines = eval(response.data);
        lines.forEach((e) => {
          objLinhas = {
            id: e.id,
            descricao: e.descricao,
            ativo: e.ativo,
            chaveCadastro: e.key,
            responsavelId: e.responsavelId,
            responsavel: e.responsavel == null ? '' : e.responsavel.nome,
            verTodasLinhas: e.verTodasLinhas
          };
          arrayLinhas.push(objLinhas);
        });
        setLinhas(arrayLinhas);
        return Promise.resolve(arrayLinhas)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const AdicionarLinha = async (descricao, status, responsavelId) => {
    const token = Cookies.get("Token");
    let dados = {
      empresaKey: EmpresaKey,
      descricao: descricao,
      responsavelId: responsavelId,
      ativo: status,
    };
    return axios({
      url: baseurl + "LinhaAtendimentoDemanda/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: dados,
    })
      .then(() => {
        ListaLinhaAtendimento()
        setSuccess("Linha de atendimento adicionada com sucesso!");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const EditarLinha = async (descricao, status, responsavelId, key) => {
    const token = Cookies.get("Token");
    let dados = {
      empresaKey: EmpresaKey,
      descricao: descricao,
      ativo: status,
      responsavelId: responsavelId,
    };
    return axios({
      url: baseurl + "LinhaAtendimentoDemanda/Alterar?key=" + key,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: dados,
    })
      .then(() => {
        ListaLinhaAtendimento()
        ListaUsuarios()
        setSuccess("Linha de Atendimento alterada com sucesso.")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ListaSetorGerencia = useCallback(async (ativo) => {
    let arraySetores = [];
    let objSetores = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "SetorGerenciaDemanda/Listar?empresaKey=" + `${EmpresaKey}` + "&somenteAtivas=" + ativo,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const sectors = eval(response.data);
        sectors.forEach((e) => {
          objSetores = {
            id: e.id,
            descricao: e.descricao,
            ativo: e.ativo,
            chaveCadastro: e.key,
          };
          arraySetores.push(objSetores);
        });
        setSetores(arraySetores);
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaTipoDemanda = useCallback(async (ativo) => {
    let arrayTipos = [];
    let objTipos = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "TipoDemanda/Listar?empresaKey=" + `${EmpresaKey}` + "&somenteAtivas=" + ativo,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const types = eval(response.data);
        types.forEach((e) => {
          objTipos = {
            id: e.id,
            descricao: e.descricao,
            ativo: e.ativo,
            chaveCadastro: e.key,
          };
          arrayTipos.push(objTipos);
        });
        setTipos(arrayTipos);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ListaDashboard = useCallback(async () => {
    let arrayDashLinhas = [];
    let objDashLinhas = {};
    const token = Cookies.get("Token");
    setLoading(true);
    if (EmpresaKey == "" || typeof EmpresaKey == 'undefined') {
      setLoading(false)
      return
    }
    return axios({
      url: baseurl + "Empresa/Dashboard?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    }).then((response) => {
      setLoading(false)
      arrayDashLinhas = []
      const dashboard = eval(response.data);
      const dashboardFases = eval(response.data.demandaFaseDados)
      const dashboardLinhas = eval(response.data.demandaLinhasDados)
      const dashboardImpacto = eval(response.data.demandaImpactos)
      const demandaDados = eval(response.data.demandaDados)
      let demandaDadosArray = []
      demandaDadosArray.push(demandaDados)
      let count = 1;
      dashboardLinhas.forEach((item) => {
        objDashLinhas = {
          letra: count++,
          descricao: item.descricao,
          quantidade: item.quantidade,
          percentual: item.percentual,
          id: item.id,
          responsavel: item.responsavel
        };
        arrayDashLinhas.push(objDashLinhas)
      })
      setDashboardDados(dashboard);
      setDashboardFases(dashboardFases);
      setDashboardLinhas(arrayDashLinhas);
      setDashboardImpacto(dashboardImpacto)
      setDemandaDadoDash(demandaDadosArray)
      setLoading(false);
      return Promise.resolve([demandaDadosArray, arrayDashLinhas, dashboardFases])
    })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }, [EmpresaKey])

  const CriarDemanda = async (priorizacao) => {
    setLoading(true)
    const token = Cookies.get("Token");
    let dataEntregaISO = '';
    let dataLimiteISO = '';

    if (typeof FormDemanda.expectativaEntrega != "undefined" && FormDemanda.expectativaEntrega != "") {
      dataEntregaISO = new Date(FormDemanda.expectativaEntrega).toISOString();
    }
    if (typeof FormDemanda.dataLimite != "undefined" && FormDemanda.dataLimite != "") {
      dataLimiteISO = new Date(FormDemanda.dataLimite).toISOString();
    }
    if (FormDemanda.cicloDemandaId === 0 || typeof FormDemanda.cicloDemandaId === "undefined") {
      setLoading(false)
      setError("Campo ciclo obrigatório, na aba Informações.")
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      titulo: FormDemanda.titulo,
      descricao: FormDemanda.descricao,
      cenarioAtual: FormDemanda.cenarioAtual,
      problemaIdentificadoCenarioAtual: FormDemanda.problemaIdentificadoCenarioAtual,
      objetivo: FormDemanda.objetivo,
      resultadoEsperado: FormDemanda.resultadoEsperado,
      necessidadeNegocio: FormDemanda.necessidadeNegocio,
      oportunidade: FormDemanda.oportunidade,
      prazoRetornoEsperado: FormDemanda.prazoRetornoEsperado,
      faseDemandaId: parseInt(FormDemanda.faseDemandaId),
      mitigacaoRisco: FormDemanda.mitigacaoRisco,
      mitigacaoRiscoJustificativa: FormDemanda.mitigacaoRiscoJustificativa,
      expectativaEntrega: dataEntregaISO,
      dataLimite: dataLimiteISO,
      justificativaDataLimite: FormDemanda.justificativaDataLimite,
      setorGerenciaDemandaId: parseInt(FormDemanda.setorGerenciaDemandaId),
      solicitante: FormDemanda.solicitante,
      tipoDemandaId: parseInt(FormDemanda.tipoDemandaId),
      classificacaoDemanda: FormDemanda.classificacaoDemanda,
      linhaAtendimentoId: parseInt(FormDemanda.linhaAtendimentoId),
      deliberacaoComite: delibComite,
      esforcoTotal: FormDemanda.esforcoTotal,
      justificativaEsforcoTotal: FormDemanda.justificativaEsforcoTotal,
      categorizacaoEstrategicaDemandaId: parseInt(
        FormDemanda.categorizacaoEstrategicaDemandaId
      ),
      capacidadeNegocioDemandaId: parseInt(
        FormDemanda.capacidadeNegocioDemandaId
      ),
      diretrizEstrategicaId: typeof FormDemanda.diretrizEstrategicaId === "undefined" ? "" : JSON.parse(FormDemanda.diretrizEstrategicaId).id,
      guT_Gravidade: FormDemanda.guT_Gravidade,
      guT_Urgencia: FormDemanda.guT_Urgencia,
      guT_Tendencia: FormDemanda.guT_Tendencia,
      guT_Pontuacao: Gut,
      guT_BSC_Pontuacao: parseFloat(GutBSC),
      guT_Swot: GutSwot,
      valor: FormDemanda.valor,
      recursosHumanosInternosQuantidade: FormDemanda.recursosHumanosInternosQuantidade,
      recursosHumanosInternosCompetencias: FormDemanda.recursosHumanosInternosCompetencias,
      recursosHumanosExternosCusto: FormDemanda.recursosHumanosExternosCusto,
      recursosHumanosExternosHospedagem: FormDemanda.recursosHumanosExternosHospedagem,
      recursosHumanosExternosAlimentacao: FormDemanda.recursosHumanosExternosAlimentacao,
      recursosHumanosExternosLocomocao: FormDemanda.recursosHumanosExternosLocomocao,
      recursosHumanosExternosTempo: FormDemanda.recursosHumanosExternosTempo,
      recursosHumanosExternosTipoAtendimento: FormDemanda.recursosHumanosExternosTipoAtendimento,
      infraestruturaNecessariaExecucao: FormDemanda.infraestruturaNecessariaExecucao,
      anoBaseOrcamento: FormDemanda.anoBaseOrcamento,
      comentarios: FormComentario,
      links: FormLink,
      riscos: FormRisco,
      planoNegocio: FormPlano,
      cicloDemandaId: FormDemanda.cicloDemandaId,
      areaId: parseInt(FormDemanda.areaId),
      diretrizesItensBSC: stringDiretrizesItems,
      demandaDependenteId: parseInt(FormDemanda.demandaDependenteId),
      capacidades: FormDemanda.capacidades,
      competencias: FormDemanda.competencias
    };
    return axios({
      url: baseurl + "Demanda/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        setLoading(false);
        setSuccess("Demanda adicionada com sucesso.");
        setModalCriarDemanda(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        ListaDemanda(true, priorizacao)
        setForm({})
        setDescricao('')
        setCenarioAtual('')
        setProblemaCenarioAtual('')
        setObjetivo('')
        setResultado('')
        setNecessidade('')
        setOportunidade('')
        setDataLimite('')
        setEsforco('')
        setGutSwot(0)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ListaDemanda = useCallback(async (filtrar, priorizacao) => {
    let arrayDemandas = [];
    let objDemandas = {};
    const token = Cookies.get("Token");
    let data = {}
    if (filtrar) {
      data = {
        empresaKey: EmpresaKey || '',
        linhaAtendimentoId: FiltroDemanda.linhaAtendimentoId || 0,
        setorGerenciaDemandaId: FiltroDemanda.setorGerenciaDemandaId || 0,
        cicloId: FiltroDemanda.cicloId || 0,
        somenteAtrasadas: FiltroDemanda.somenteAtrasadas,
        guT_BSC_Pontuacao_Inicial: parseFloat(FiltroDemanda.guT_BSC_Pontuacao_Inicial) || 0,
        guT_BSC_Pontuacao_Final: parseFloat(FiltroDemanda.guT_BSC_Pontuacao_Final) || 0,
        faseId: FiltroDemanda.faseId || 0,
        numero: FiltroDemanda.numero || 0,
        somenteSemCiclo: FiltroDemanda.somenteSemCiclo,
        faseDemanda: priorizacao,
        somenteCanceladas: FiltroDemanda.somenteCanceladas,
        somenteConcluidas: FiltroDemanda.somenteConcluidas,
        descricao: FiltroDemanda.descricao,
      }
    }
    else {
      data = {
        empresaKey: EmpresaKey,
        linhaAtendimentoId: 0,
        setorGerenciaDemandaId: 0,
        cicloId: 0,
        somenteAtrasadas: false,
        guT_BSC_Pontuacao_Inicial: 0,
        guT_BSC_Pontuacao_Final: 0,
        faseId: 0,
        numero: 0,
        somenteSemCiclo: false,
        faseDemanda: priorizacao,
        somenteCanceladas: false,
        somenteConcluidas: false,
        descricao: '',
      }
    }
    return axios({
      url: baseurl + "Demanda/ListarTodasDaEmpresa",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data
    })
      .then((response) => {
        const demandas = eval(response.data);
        demandas.forEach((e) => {
          objDemandas = {
            chave: e.key,
            id: e.id,
            titulo: e.titulo,
            criacaoDataHora: e.criacaoDataHora,
            criacaoUsuarioNome: e.criacaoUsuarioNome,
            descricao: e.descricao,
            faseDemandaId: e.faseDemandaId,
            faseDescricao: e.faseDescricao,
            expectativaEntrega: e.expectativaEntrega,
            dataLimite: e.dataLimite,
            setorGerenciaDescricao: e.setorGerenciaDescricao,
            solicitante: e.solicitante,
            tipoDescricao: e.tipoDescricao,
            linhaAtendimentoId: e.linhaAtendimentoId,
            linhaAtendimentoDescricao: e.linhaAtendimentoDescricao,
            diretrizEstrategicaDescricao: e.diretrizEstrategicaDescricao,
            diretrizEstrategicaId: e.diretrizEstrategicaId,
            pesoBSC: e.pesoBSC,
            guT_Gravidade: e.guT_Gravidade,
            guT_Urgencia: e.guT_Urgencia,
            guT_Tendencia: e.guT_Tendencia,
            guT_Pontuacao: e.guT_Pontuacao,
            guT_BSC_Pontuacao: e.guT_BSC_Pontuacao,
            guT_Swot: e.guT_Swot,
            esforcoTotal: parseInt(e.esforcoTotal),
            cancelamentoDataHora: e.cancelamentoDataHora,
            conclusaoDataHora: e.conclusaoDataHora,
            cicloOrdem: e.cicloOrdem,
            cicloDescricao: e.cicloDescricao,
            areaId: e.areaId,
            areaDescricao: e.areaDescricao
          };
          arrayDemandas.push(objDemandas);
        });
        setDemandas(arrayDemandas);
        return Promise.resolve()
      })
      .catch(async (error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        if (error.response) {
          setTimeout(() => {
            setError("");
          }, 3000);
          return Promise.reject(error.response.data)
        } else if (error.request) {
          setTimeout(() => {
            setError("");
          }, 3000);
          return Promise.reject(error.request)
        } else {
          setTimeout(() => {
            setError("");
          }, 3000);
          return Promise.reject(error.message)
        }
      })
  })

  const ObterDemanda = useCallback(async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ObterKey?key=" + key,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const dem = response.data;
        const objDemandas = {
          empresaId: dem.empresaId,
          empresa: dem.empresa,
          titulo: dem.titulo,
          descricao: dem.descricao,
          cenarioAtual: dem.cenarioAtual,
          problemaIdentificadoCenarioAtual: dem.problemaIdentificadoCenarioAtual,
          objetivo: dem.objetivo,
          resultadoEsperado: dem.resultadoEsperado,
          necessidadeNegocio: dem.necessidadeNegocio,
          oportunidade: dem.oportunidade,
          prazoRetornoEsperado: dem.prazoRetornoEsperado,
          faseDemandaId: dem.faseDemandaId,
          faseDemanda: dem.faseDemanda,
          expectativaEntrega: dem.expectativaEntrega,
          dataLimite: dem.dataLimite,
          justificativaDataLimite: dem.justificativaDataLimite,
          setorGerenciaDemandaId: dem.setorGerenciaDemandaId,
          setorGerenciaDemanda: dem.setorGerenciaDemanda,
          solicitante: dem.solicitante,
          tipoDemandaId: dem.tipoDemandaId,
          tipoDemanda: dem.tipoDemanda,
          classificacaoDemanda: dem.classificacaoDemanda,
          linhaAtendimentoId: dem.linhaAtendimentoId,
          linhaAtendimentoDemanda: dem.linhaAtendimentoDemanda,
          deliberacaoComite: dem.deliberacaoComite,
          esforcoTotal: dem.esforcoTotal,
          justificativaEsforcoTotal: dem.justificativaEsforcoTotal,
          categorizacaoEstrategicaDemandaId: dem.categorizacaoEstrategicaDemandaId,
          categorizacaoEstrategicaDemanda: dem.categorizacaoEstrategicaDemanda,
          capacidadeNegocioDemandaId: dem.capacidadeNegocioDemandaId,
          capacidadeNegocioDemanda: dem.capacidadeNegocioDemanda,
          diretrizEstrategicaId: dem.diretrizEstrategicaId,
          diretrizEstrategica: dem.diretrizEstrategica,
          guT_Gravidade: dem.guT_Gravidade,
          guT_Urgencia: dem.guT_Urgencia,
          guT_Tendencia: dem.guT_Tendencia,
          guT_Pontuacao: dem.guT_Pontuacao,
          guT_BSC_Pontuacao: dem.guT_BSC_Pontuacao,
          guT_Swot: dem.guT_Swot,
          aprovacaoUsuarioId: dem.aprovacaoUsuarioId,
          aprovacaoDataHora: dem.aprovacaoDataHora,
          usuarioAprovacao: dem.usuarioAprovacao,
          cicloDemandaId: dem.cicloDemandaId,
          cicloDemanda: dem.cicloDemanda,
          cicloDemandaOrdem: dem.cicloDemandaOrdem,
          criacaoUsuarioId: dem.criacaoUsuarioId,
          criacaoDataHora: dem.criacaoDataHora,
          usuarioCriacao: dem.usuarioCriacao,
          alteracaoDataHora: dem.alteracaoDataHora,
          cancelamentoDataHora: dem.cancelamentoDataHora,
          conclusaoDataHora: dem.conclusaoDataHora,
          valor: dem.valor,
          recursosHumanosInternosQuantidade: dem.recursosHumanosInternosQuantidade,
          recursosHumanosInternosCompetencias: dem.recursosHumanosInternosCompetencias,
          recursosHumanosExternosCusto: dem.recursosHumanosExternosCusto,
          recursosHumanosExternosHospedagem: dem.recursosHumanosExternosHospedagem,
          recursosHumanosExternosAlimentacao: dem.recursosHumanosExternosAlimentacao,
          recursosHumanosExternosLocomocao: dem.recursosHumanosExternosLocomocao,
          recursosHumanosExternosTempo: dem.recursosHumanosExternosTempo,
          recursosHumanosExternosTipoAtendimento: dem.recursosHumanosExternosTipoAtendimento,
          infraestruturaNecessariaExecucao: dem.infraestruturaNecessariaExecucao,
          anoBaseOrcamento: dem.anoBaseOrcamento,
          id: dem.id,
          key: dem.key,
          podeVerValores: dem.podeVerValores,
          areaId: dem.areaId,
          diretrizesItensBSC: dem.diretrizesItensBSC,
          area: dem.area,
          demandaDependenteId: dem.demandaDependenteId
        }
        setDem(objDemandas);
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        return Promise.reject()
      })
  })

  const EditarDemanda = async (priorizacao) => {
    let dataEntregaISO = '';
    let dataLimiteISO = '';
    const token = Cookies.get("Token");
    try {
      if (Dem?.expectativaEntrega) {
        dataEntregaISO = new Date(Dem.expectativaEntrega).toISOString();
      }
      if (Dem?.dataLimite) {
        dataLimiteISO = new Date(Dem.dataLimite).toISOString();
      }
      if (Dem.esforcoTotal < 0) {
        setError("Esforço total não pode ser negativo.");
        setTimeout(() => {
          setError("");
        }, 3000);
        setLoading(false);
        return;
      }
    } catch (error) {
    }
    const data = {
      empresaKey: EmpresaKey,
      titulo: Dem.titulo,
      descricao: Dem.descricao,
      cenarioAtual: Dem.cenarioAtual,
      problemaIdentificadoCenarioAtual: Dem.problemaIdentificadoCenarioAtual,
      objetivo: Dem.objetivo,
      resultadoEsperado: Dem.resultadoEsperado,
      necessidadeNegocio: Dem.necessidadeNegocio,
      oportunidade: Dem.oportunidade,
      prazoRetornoEsperado: Dem.prazoRetornoEsperado,
      faseDemandaId: JSON.parse(Dem.faseDemandaId).id ? JSON.parse(Dem.faseDemandaId).id : Dem.faseDemandaId,
      riscoAtual: Dem.riscoAtual,
      riscoAtualJustificativa: Dem.riscoAtualJustificativa,
      mitigacaoRisco: Dem.mitigacaoRisco,
      mitigacaoRiscoJustificativa: Dem.mitigacaoRiscoJustificativa,
      expectativaEntrega: dataEntregaISO,
      dataLimite: dataLimiteISO,
      justificativaDataLimite: Dem.justificativaDataLimite,
      setorGerenciaDemandaId: Dem.setorGerenciaDemandaId ? parseInt(Dem.setorGerenciaDemandaId) : 0,
      solicitante: Dem.solicitante,
      tipoDemandaId: parseInt(Dem.tipoDemandaId),
      classificacaoDemanda: Dem.classificacaoDemanda,
      linhaAtendimentoId: Dem.linhaAtendimentoId ? parseInt(Dem.linhaAtendimentoId) : Dem.linhaAtendimentoId,
      deliberacaoComite: eval(Dem.deliberacaoComite),
      esforcoTotal: Dem.esforcoTotal,
      justificativaEsforcoTotal: Dem.justificativaEsforcoTotal,
      categorizacaoEstrategicaDemandaId: parseInt(
        Dem.categorizacaoEstrategicaDemandaId
      ),
      capacidadeNegocioDemandaId: parseInt(
        Dem.capacidadeNegocioDemandaId
      ),
      diretrizEstrategicaId: Dem.diretrizEstrategicaId,
      guT_Gravidade: Dem.guT_Gravidade,
      guT_Urgencia: Dem.guT_Urgencia,
      guT_Tendencia: Dem.guT_Tendencia,
      guT_Pontuacao: Dem.guT_Pontuacao,
      guT_BSC_Pontuacao: Dem.guT_BSC_Pontuacao,
      guT_Swot: Dem.guT_Swot,
      valor: Dem.valor,
      recursosHumanosInternosQuantidade: Dem.recursosHumanosInternosQuantidade,
      recursosHumanosInternosCompetencias: Dem.recursosHumanosInternosCompetencias,
      recursosHumanosExternosCusto: Dem.recursosHumanosExternosCusto,
      recursosHumanosExternosHospedagem: Dem.recursosHumanosExternosHospedagem,
      recursosHumanosExternosAlimentacao: Dem.recursosHumanosExternosAlimentacao,
      recursosHumanosExternosLocomocao: Dem.recursosHumanosExternosLocomocao,
      recursosHumanosExternosTempo: Dem.recursosHumanosExternosTempo,
      recursosHumanosExternosTipoAtendimento: Dem.recursosHumanosExternosTipoAtendimento,
      infraestruturaNecessariaExecucao: Dem.infraestruturaNecessariaExecucao,
      anoBaseOrcamento: Dem.anoBaseOrcamento,
      planoNegocio: Dem.planoNegocio,
      areaId: Dem.areaId ? parseInt(Dem.areaId) : 0,
      cicloDemandaId: Dem.cicloDemandaId ? parseInt(Dem.cicloDemandaId) : 0,
      diretrizesItensBSC: Dem.diretrizesItensBSC,
      demandaDependenteId: Dem.demandaDependenteId
    };
    return axios({
      url: baseurl + "Demanda/Alterar?key=" + DemandaAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaDemanda(true, priorizacao);
        setSuccess("Demanda editada com sucesso!");
        setModalEditarDemanda(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setDescricao('')
        setCenarioAtual('')
        setProblemaCenarioAtual('')
        setObjetivo('')
        setResultado('')
        setNecessidade('')
        setOportunidade('')
        setDataLimite('')
        setEsforco('')
        setGutSwot(0)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirDemanda = async () => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/Excluir?key=" + DemandaAtualKey,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        ListaDemanda(true, '');
        setSuccess("Demanda excluída com sucesso!");
        setModalExcluirDemanda(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarCapacidade = async (capacidadeNegocioPaiKey, sistemaKeys) => {
    setLoading(true)
    const token = Cookies.get("Token");
    let dados = {
      empresaKey: EmpresaKey,
      descricao: Capacidade.descricao,
      ativo: true,
      capacidadeNegocioPaiKey: capacidadeNegocioPaiKey,
      sistemaKeys: sistemaKeys,
      titulo: Capacidade.titulo,
      nivel: Capacidade.nivel,
      relevancia: Capacidade.relevancia,
      areaKey: Capacidade.areaKey,
      pessoaKey: Capacidade.pessoaKey,
      arquitetoKey: Capacidade.arquitetoKey,
    };
    return axios({
      url: baseurl + "CapacidadeNegocioDemanda/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: dados,
    })
      .then(() => {
        setLoading(false)
        ListaCapacidades("N")
        ListaArvoreCapacidades()
        setSuccess("Capacidade de Negócio adicionada com sucesso!");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        console.log(error)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const EditarCapacidade = async (key, keyPai, sistemaKeys, areaKey, pessoaKey, arquitetoKey) => {
    const token = Cookies.get("Token");
    setLoading(true)
    const data = {
      empresaKey: EmpresaKey,
      descricao: EdicaoCapacidade.descricao,
      ativo: true,
      capacidadeNegocioPaiKey: keyPai,
      sistemaKeys: sistemaKeys,
      titulo: EdicaoCapacidade.titulo,
      nivel: EdicaoCapacidade.nivel,
      relevancia: EdicaoCapacidade.relevancia,
      areaKey: areaKey,
      pessoaKey: pessoaKey,
      arquitetoKey: arquitetoKey,
    };
    return axios({
      url: baseurl + "CapacidadeNegocioDemanda/Alterar?Key=" + key,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        setLoading(false)
        ListaCapacidades("N");
        setSuccess("Capacidade de Negócio editada com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarItensDemanda = async (endpoint, descricao, status) => {
    const token = Cookies.get("Token");
    let dados = {
      empresaKey: EmpresaKey,
      descricao: descricao,
      ativo: status,
    };
    return axios({
      url: baseurl + endpoint + "/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: dados,
    })
      .then(() => {
        switch (endpoint) {
          case "CategorizacaoEstrategicaDemanda":
            ListaCategorizacao("N");
            setSuccess("Categorização Estratégica adicionada com sucesso.");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "SetorGerenciaDemanda":
            ListaSetorGerencia("N");
            setSuccess("Setor de Gerência adicionado com sucesso.");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "TipoDemanda":
            ListaTipoDemanda("N");
            setSuccess("Tipo da Demanda adicionada com sucesso.");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const EditarItensDemanda = async (endpoint, descricao, status, key) => {
    const token = Cookies.get("Token");
    let dados = {
      empresaKey: EmpresaKey,
      descricao: descricao,
      ativo: status,
    };
    return axios({
      url: baseurl + endpoint + "/Alterar?Key=" + key,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: dados,
    })
      .then(() => {
        switch (endpoint) {
          case "CapacidadeNegocioDemanda":
            ListaCapacidades("N");
            setSuccess("Capacidade de Negócio editada com sucesso.");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "CategorizacaoEstrategicaDemanda":
            ListaCategorizacao("N");
            setSuccess("Categorização Estratégica editada com sucesso.");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "SetorGerenciaDemanda":
            ListaSetorGerencia("N");
            setSuccess("Setor de Gerência editado com sucesso.");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "TipoDemanda":
            ListaTipoDemanda("N");
            setSuccess("Tipo da Demanda editada com sucesso.");
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AtualizarStatus = async (endpoint, key, status) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + endpoint + "/Ativo?key=" + key + "&status=" + status,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        switch (endpoint) {
          case "CapacidadeNegocioDemanda":
            ListaCapacidades("N");
            setSuccess(response.data);
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "CategorizacaoEstrategicaDemanda":
            ListaCategorizacao("N");
            setSuccess(response.data);
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "LinhaAtendimentoDemanda":
            ListaLinhaAtendimento("N");
            setSuccess(response.data);
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "SetorGerenciaDemanda":
            ListaSetorGerencia("N");
            setSuccess(response.data);
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          case "TipoDemanda":
            ListaTipoDemanda("N");
            setSuccess(response.data);
            setTimeout(() => {
              setSuccess("");
            }, 3000);
            break;
          default:
            break;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ObterBSC = useCallback(async (BSCKey) => {
    setLoading(true)
    let arrayDiretrizes = [];
    let objDiretrizes = {};
    let arrayPrioridade = [];
    let objPrioridade = {};
    let arrayResumoPrioridade = [];
    let objResumoPrioridade = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "BSC/ObterBSC?empresaKey=" + `${EmpresaKey}` + "&BSCKey=" + BSCKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const bsc = eval(response.data)
        setBSCDados(bsc)
        const diretrizes = eval(response.data.diretrizEstrategica);
        const prioridades = eval(response.data.prioridades);
        const resumoPrioridades = eval(response.data.resumoPrioridades);
        let count = 0;
        diretrizes.forEach((e) => {
          const diretrizesItens = eval(
            response.data.diretrizEstrategicaItems
          ).filter((item) => item.diretrizEstrategicaId == e.id);
          let objDiretrizesItens = {};
          let arrayDiretrizesItens = [];
          diretrizesItens.forEach((item) => {
            count++;
            objDiretrizesItens = {
              letra: String.fromCharCode(count + 96).toUpperCase(),
              descricao: item.descricao,
              diretrizEstrategicaId: item.diretrizEstrategicaId,
              descricaoCompleta: item.descricaoCompleta,
              numeroOrdem: item.numeroOrdem,
              diretrizEstrategicaPrioridadeId: item.diretrizEstrategicaPrioridadeId,
              cobitP: item.cobitP,
              cobitS: item.cobitS,
              ordem: item.ordem,
              parcial: item.parcial,
              pesoGrupo: item.pesoGrupo,
              diretrizEstrategica: item.diretrizEstrategica,
              diretrizEstrategicaPrioridade: item.diretrizEstrategicaPrioridade,
              id: item.id,
              key: item.key,
            };
            arrayDiretrizesItens.push(objDiretrizesItens);
          });
          objDiretrizes = {
            empresaId: e.empresaId,
            descricao: e.descricao,
            pesoPorcentagem: e.pesoPorcentagem,
            pesoBSC: e.pesoBSC,
            empresa: e.empresa,
            id: e.id,
            key: e.key,
            itens: arrayDiretrizesItens,
          };
          arrayDiretrizes.push(objDiretrizes);
        });
        prioridades.forEach((p) => {
          objPrioridade = {
            empresaId: p.empresaId,
            descricao: p.descricao,
            peso: p.peso,
            empresa: p.empresa,
            id: p.id,
            key: p.key,
          };
          arrayPrioridade.push(objPrioridade);
        });
        resumoPrioridades.forEach((r) => {
          objResumoPrioridade = {
            x: r.prioridadeDescricao,
            y: r.porcentagem.toFixed(2),
            text: r.porcentagem.toFixed(2) + "%",
          };
          arrayResumoPrioridade.push(objResumoPrioridade);
        });
        setDiretrizEstrategica(arrayDiretrizes);
        setPrioridades(arrayPrioridade);
        setResumoPrioridades(arrayResumoPrioridade);
        return Promise.resolve()
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        if (error.response.status === 401) {
          setLoading(false);
          setAuth(false)
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          return
        }
        if (typeof EmpresaKey != 'undefined') {
          error.response.data.map((er) => {
            setError(`${er.campo}: ${er.mensagem}`)
          })
          setTimeout(() => {
            setError("");
          }, 3000);
        } else {
          setError("")
        }
        return Promise.reject();
      });
  })

  const ObterBSCPorCiclo = useCallback(async (cicloKey) => {
    setLoading(true)
    let arrayDiretrizes = [];
    let objDiretrizes = {};
    let arrayPrioridade = [];
    let objPrioridade = {};
    let arrayResumoPrioridade = [];
    let objResumoPrioridade = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "BSC/ObterBSCPorCiclo?empresaKey=" + `${EmpresaKey}` + "&cicloKey=" + cicloKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const bsc = eval(response.data)
        setBSCDados(bsc)
        const diretrizes = eval(response.data.diretrizEstrategica);
        const prioridades = eval(response.data.prioridades);
        const resumoPrioridades = eval(response.data.resumoPrioridades);
        let count = 0;
        diretrizes.forEach((e) => {
          const diretrizesItens = eval(
            response.data.diretrizEstrategicaItems
          ).filter((item) => item.diretrizEstrategicaId == e.id);
          let objDiretrizesItens = {};
          let arrayDiretrizesItens = [];
          diretrizesItens.forEach((item) => {
            count++;
            objDiretrizesItens = {
              letra: String.fromCharCode(count + 96).toUpperCase(),
              descricao: item.descricao,
              diretrizEstrategicaId: item.diretrizEstrategicaId,
              descricaoCompleta: item.descricaoCompleta,
              numeroOrdem: item.numeroOrdem,
              diretrizEstrategicaPrioridadeId: item.diretrizEstrategicaPrioridadeId,
              cobitP: item.cobitP,
              cobitS: item.cobitS,
              ordem: item.ordem,
              parcial: item.parcial,
              pesoGrupo: item.pesoGrupo,
              diretrizEstrategica: item.diretrizEstrategica,
              diretrizEstrategicaPrioridade: item.diretrizEstrategicaPrioridade,
              id: item.id,
              key: item.key,
            };
            arrayDiretrizesItens.push(objDiretrizesItens);
          });
          objDiretrizes = {
            empresaId: e.empresaId,
            descricao: e.descricao,
            pesoPorcentagem: e.pesoPorcentagem,
            pesoBSC: e.pesoBSC,
            empresa: e.empresa,
            id: e.id,
            key: e.key,
            itens: arrayDiretrizesItens,
          };
          arrayDiretrizes.push(objDiretrizes);
        });
        prioridades.forEach((p) => {
          objPrioridade = {
            empresaId: p.empresaId,
            descricao: p.descricao,
            peso: p.peso,
            empresa: p.empresa,
            id: p.id,
            key: p.key,
          };
          arrayPrioridade.push(objPrioridade);
        });
        resumoPrioridades.forEach((r) => {
          objResumoPrioridade = {
            x: r.prioridadeDescricao,
            y: r.porcentagem.toFixed(2),
            text: r.porcentagem.toFixed(2) + "%",
          };
          arrayResumoPrioridade.push(objResumoPrioridade);
        });
        setDiretrizEstrategicaPorCiclo(arrayDiretrizes);
        setPrioridadesPorCiclo(arrayPrioridade);
        setResumoPrioridadesPorCiclo(arrayResumoPrioridade);
        return Promise.resolve()
      })
      .catch((error) => {
        console.log(error)
        setLoading(false)
        if (error.response.status === 401) {
          setLoading(false);
          setAuth(false)
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          return
        }
        if (typeof EmpresaKey != 'undefined') {
          error.response.data.map((er) => {
            setError(`${er.campo}: ${er.mensagem}`)
          })
          setTimeout(() => {
            setError("");
          }, 3000);
        } else {
          setError("")
        }
        return Promise.reject();
      });
  })

  const SimularBSC = async () => {
    let arrayDiretrizes = [];
    let objDiretrizes = {};
    let arrayPrioridade = [];
    let objPrioridade = {};
    let arrayResumoPrioridade = [];
    let objResumoPrioridade = {};
    const token = Cookies.get("Token");
    setLoading(true);
    return axios({
      url: baseurl + "BSC/Simulacao?empresaKey=" + `${EmpresaKey}`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: BSCDados,
    })
      .then((response) => {
        setLoading(false)
        const bsc = eval(response.data)
        setBSCDados(bsc)
        const diretrizes = eval(response.data.diretrizEstrategica);
        const prioridades = eval(response.data.prioridades);
        const resumoPrioridades = eval(response.data.resumoPrioridades);
        let count = 0;
        diretrizes.forEach((e) => {
          const diretrizesItens = eval(
            response.data.diretrizEstrategicaItems
          ).filter((item) => item.diretrizEstrategicaId == e.id);
          let objDiretrizesItens = {};
          let arrayDiretrizesItens = [];
          diretrizesItens.forEach((item) => {
            count++;
            objDiretrizesItens = {
              letra: String.fromCharCode(count + 96).toUpperCase(),
              descricao: item.descricao,
              diretrizEstrategicaId: item.diretrizEstrategicaId,
              descricaoCompleta: item.descricaoCompleta,
              numeroOrdem: item.numeroOrdem,
              diretrizEstrategicaPrioridadeId: item.diretrizEstrategicaPrioridadeId,
              cobitP: item.cobitP,
              cobitS: item.cobitS,
              ordem: item.ordem,
              parcial: item.parcial,
              pesoGrupo: item.pesoGrupo,
              diretrizEstrategica: item.diretrizEstrategica,
              diretrizEstrategicaPrioridade: item.diretrizEstrategicaPrioridade,
              id: item.id,
              key: item.key,
            };
            arrayDiretrizesItens.push(objDiretrizesItens);
          });
          objDiretrizes = {
            empresaId: e.empresaId,
            descricao: e.descricao,
            pesoPorcentagem: e.pesoPorcentagem,
            pesoBSC: e.pesoBSC,
            empresa: e.empresa,
            id: e.id,
            key: e.key,
            itens: arrayDiretrizesItens,
          };
          arrayDiretrizes.push(objDiretrizes);
        });
        prioridades.forEach((p) => {
          objPrioridade = {
            empresaId: p.empresaId,
            descricao: p.descricao,
            peso: p.peso,
            empresa: p.empresa,
            id: p.id,
            key: p.key,
          };
          arrayPrioridade.push(objPrioridade);
        });
        resumoPrioridades.forEach((r) => {
          objResumoPrioridade = {
            x: r.prioridadeDescricao,
            y: r.porcentagem,
            text: r.porcentagem.toFixed(2) + "%",
          };
          arrayResumoPrioridade.push(objResumoPrioridade);
        });
        setDiretrizEstrategica(arrayDiretrizes);
        setPrioridades(arrayPrioridade);
        setResumoPrioridades(arrayResumoPrioridade);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AlterarGutBSC = async (key, g, u, t, diretriz, id) => {
    const gutPontos = g * u * t
    let gutBSC = 0
    if (diretriz.pesoBSC != 0) {
      gutBSC = gutPontos * diretriz.pesoBSC
    }
    const token = Cookies.get("Token");
    const data = {
      DemandaKey: key,
      DiretrizEstrategicaId: diretriz.id,
      GUT_Gravidade: g,
      GUT_Urgencia: u,
      GUT_Tendencia: t,
      GUT_Pontuacao: gutPontos,
      GUT_BSC_Pontuacao: gutBSC,
    }
    setLoading(true);
    return axios({
      url: baseurl + "Demanda/AlterarGutBSC",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,

    })
      .then((response) => {
        const novasDemandas = [...Demandas]
        const indiceNova = novasDemandas.findIndex((item) => item.id === id)
        if (indiceNova !== -1) {
          novasDemandas[indiceNova].guT_Gravidade = g
          novasDemandas[indiceNova].guT_Urgencia = u
          novasDemandas[indiceNova].guT_Tendencia = t
          novasDemandas[indiceNova].guT_Pontuacao = gutPontos
          novasDemandas[indiceNova].guT_BSC_Pontuacao = gutBSC
          novasDemandas[indiceNova].diretrizEstrategicaId = diretriz.id
        }
        setDemandas(novasDemandas)
        setLoading(false)
        setSuccess(response.data)
        setTimeout(() => {
          setSuccess("")
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("")
        }, 3000);
        return Promise.reject();
      });
  }

  const SalvarBSC = async (key) => {
    const token = Cookies.get("Token");
    setLoading(true);
    return axios({
      url: baseurl + "BSC/Salvar?empresaKey=" + `${EmpresaKey}`,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: BSCDados,
    })
      .then((response) => {
        ListaBSC(key)
        setShow(true)
        setLoading(false)
        setSuccess(response.data)
        setTimeout(() => {
          setSuccess("")
        }, 3000);
        ObterBSC(BSCDados.bscKey)
        setModalEditarBSC(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setShow(true)
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("")
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarComentario = async () => {
    const token = Cookies.get("Token");
    setLoading(true);

    const linkRegex = /(https?:\/\/[^\s]+)|(www\.[^\s]+)|([^\s]+\.[a-zA-Z]{2,})/g;
    const comentarioTexto = AddComentario.comentario;
    let comentarioRenderizado = comentarioTexto;

    const isLink = linkRegex.test(comentarioRenderizado)
    if (isLink) {
      comentarioRenderizado = comentarioTexto.replace(linkRegex, (match) => {
        if (match.startsWith("http") || match.startsWith("www")) {
          if (match.startsWith("www")) {
            return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
          }
          return `<a href="${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
        } else {
          return `<a href="http://${match}" target="_blank" rel="noopener noreferrer">${match}</a>`;
        }
      });
    }

    const data = {
      demandaKey: DemandaAtualKey,
      comentario: comentarioRenderizado,
      dataHora: AddComentario.dataHora,
    };

    try {
      if (typeof comentarioRenderizado === "undefined") {
        setError("Campo comentário em branco.")
        setTimeout(() => {
          setError("");
        }, 3000);
        setLoading(false);
        return;
      }
    } catch (error) {
    }
    return axios({
      url: baseurl + "Demanda/AdicionarComentario",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaComentario()
        setSuccess("Comentário adicionado com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
        comentarioRef.current.value = "";
        setAddComentario({
          comentario: ""
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaComentario = async () => {
    let arrayComentario = [];
    let objComentario = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ListarComentario?demandaKey=" + DemandaAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const user = eval(response.data);
        user.forEach((e) => {
          objComentario = {
            comentario: e.comentario,
            dataHora: e.dataHora,
            nome: e.usuario.nome,
            chaveComentario: e.key
          };
          arrayComentario.push(objComentario);
        });
        setComentario(arrayComentario.reverse());
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirComentario = async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ExcluirComentario?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        ListaComentario();
        setSuccess(response.data);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarLink = async () => {
    const token = Cookies.get("Token");
    setLoading(true);
    const data = {
      demandaKey: DemandaAtualKey,
      link: AddLink.addLink,
      descricao: AddLink.descricaoLink,
      dataHora: AddLink.dataHora,
    };
    try {
      if (typeof AddLink.addLink === "undefined") {
        setError("Campo link em branco.")
        setTimeout(() => {
          setError("");
        }, 3000);
        setLoading(false);
        return;
      }
    } catch (error) {
    }
    return axios({
      url: baseurl + "Demanda/AdicionarLink",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaLink()
        setSuccess("Link adicionado com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
        linkRef.current.value = '';
        descricaoRef.current.value = '';
        setAddLink({
          link: "",
          descricao: "",
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaLink = useCallback(async () => {
    let arrayLink = [];
    let objLink = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ListarLink?demandaKey=" + DemandaAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const user = eval(response.data);
        user.forEach((e) => {
          objLink = {
            nome: e.usuario.nome,
            dataHora: e.dataHora,
            descricao: e.descricao,
            link: e.link,
            chaveLink: e.key
          };
          arrayLink.push(objLink);
        });
        setLink(arrayLink.reverse());
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ExcluirLink = async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ExcluirLink?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        ListaLink();
        setSuccess(response.data);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarRisco = async () => {
    const token = Cookies.get("Token");
    setLoading(true);
    const data = {
      demandaKey: DemandaAtualKey,
      riscoAtual: AddRisco.riscoAtual,
      riscoAtualJustificativa: AddRisco.riscoAtualJustificativa,
      mitigacaoRisco: AddRisco.mitigacaoRisco,
      mitigacaoRiscoJustificativa: AddRisco.mitigacaoRiscoJustificativa,
    };
    return axios({
      url: baseurl + "Demanda/AdicionarRisco",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaRisco()
        setSuccess("Risco adicionado com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
        riscoJustificativaRef.current.value = '';
        mitigacaoJustificativaRef.current.value = '';

        const checkboxes = document.getElementsByName("riscoAtual");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });

        const mitigacaoCheckboxes = document.getElementsByName("mitigacaoRisco");
        mitigacaoCheckboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });

        setAddRisco({
          riscoAtual: '',
          riscoAtualJustificativa: '',
          mitigacaoRisco: '',
          mitigacaoRiscoJustificativa: ''
        })
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaRisco = useCallback(async () => {
    let arrayRisco = [];
    let objRisco = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ListarRisco?demandaKey=" + DemandaAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const user = eval(response.data);
        user.forEach((e) => {
          objRisco = {
            riscoAtual: e.riscoAtual,
            riscoAtualJustificativa: e.riscoAtualJustificativa,
            mitigacaoRisco: e.mitigacaoRisco,
            mitigacaoRiscoJustificativa: e.mitigacaoRiscoJustificativa,
            chaveRisco: e.key,
          };
          arrayRisco.push(objRisco);
        });
        setRisco(arrayRisco.reverse());
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const EditarRisco = async (risco, riscoJustificativa, mitigacao, mitigacaoJustificativa, key) => {
    const token = Cookies.get("Token")
    const data = {
      demandaKey: DemandaAtualKey,
      riscoAtual: risco,
      riscoAtualJustificativa: riscoJustificativa,
      mitigacaoRisco: mitigacao,
      mitigacaoRiscoJustificativa: mitigacaoJustificativa,
      demandaRiscoKey: key
    }
    return axios({
      url: baseurl + "Demanda/AlterarRisco?key=" + key,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data
    })
      .then((response) => {
        ListaRisco()
        setRiscoEditar(false)
        setSuccess(response.data)
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        riscoJustificativaRef.current.value = '';
        mitigacaoJustificativaRef.current.value = '';

        const checkboxes = document.getElementsByName("riscoAtual");
        checkboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });

        const mitigacaoCheckboxes = document.getElementsByName("mitigacaoRisco");
        mitigacaoCheckboxes.forEach((checkbox) => {
          checkbox.checked = false;
        });
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      })
  }

  const ExcluirRisco = async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ExcluirRisco?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        ListaRisco();
        setSuccess(response.data);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarDemandaCiclo = async (props) => {
    setLoading(true)
    let listaDemandas = []
    let objDemandas = {}
    props.forEach((item, index) => {
      objDemandas = {
        cicloOrdem: index + 1,
        demandaKey: item.id
      }
      listaDemandas.push(objDemandas)
    })
    const token = Cookies.get("Token")
    const { cicloDataInicial, cicloDataFinal } = CadastroCiclo;
    if (!CadastroCiclo || !cicloDataInicial || !cicloDataFinal) {
      setError("Preencha todos os campos.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return Promise.reject();
    }

    const cicloDataInicialISO = new Date(cicloDataInicial).toISOString();
    const cicloDataFinalISO = new Date(cicloDataFinal).toISOString();
    try {
      if (CadastroCiclo === "") {
        setError("Formulário vazio.");
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      }
      if (!CadastroCiclo.cicloDescricao) {
        setError("Campo descrição obrigatório.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      }
      if (!cicloDataFinalISO) {
        setError("Campo data inicial obrigatório.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      }
      if (!cicloDataInicialISO) {
        setError("Campo data final obrigatório.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      }
      if (!CadastroCiclo.cicloObservacao) {
        setError("Campo observação obrigatório.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      }
    } catch (error) {
    }
    const data = {
      empresaKey: EmpresaKey,
      cicloDescricao: CadastroCiclo.cicloDescricao,
      cicloDataInicial: cicloDataInicialISO,
      cicloDataFinal: cicloDataFinalISO,
      cicloObservacao: CadastroCiclo.cicloObservacao,
      demandaCicloOrdens: listaDemandas,
    };
    return axios({
      url: baseurl + "Demanda/AdicionarDemandasNovoCiclo",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        setLoading(false)
        setSuccess("Demanda(s) adicionada(s) no novo ciclo com sucesso.");
        setModalEnviarCiclo(false)
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        ListaDemanda(true, 'PRIORIZACAO')
        ListaCiclos()
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      })
  }

  const EditarRole = async (Admin, Supervisor, Leitor, Nome) => {
    const token = Cookies.get("Token")
    const empresaKey = Cookies.get('Key')
    setLoading(true)
    let data = {
      empresaKey: empresaKey,
      nome: Nome,
      supervisor: Supervisor,
      admin: Admin,
      somenteVisualizar: Leitor,
      id: UserId
    }
    return axios({
      url: baseurl + "Autenticacao/EditarUsuario",
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      data: data
    })
      .then((response) => {
        setModalTrocarRole(false)
        setSuccess("Usuário editado com sucesso!");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        ListaUsuarios()
        setLoading(false)
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        setLoading(false)
        return Promise.reject(error)
      })
  }

  const ExcluirDemandaCiclo = async (demandaKey, cicloKey) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/RemoverCiclo?demandaKey=" + demandaKey + "&cicloKey=" + cicloKey,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        ListaCiclos();
        ListaDemanda(false, 'PRIORIZACAO')
        ListaCicloDemanda(demandaKey)
        setSuccess(response.data);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirCiclo = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Demanda/ExcluirCiclo?Key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaCiclos()
        setSuccess("Ciclo excluído com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirCiclo(false)
      })
      .catch((error) => {
        console.log(error)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const ListaCicloDemanda = useCallback(async (key) => {
    let arrayciclo = []
    let objciclo = {}
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ListarCiclos?demandaKey=" + key,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const user = eval(response.data);
        user.forEach((e) => {
          objciclo = {
            cicloId: e.cicloId,
            usuarioId: e.usuarioId,
            usuarioNome: e.usuario.nome,
            dataHora: e.dataHora,
            cicloKey: e.ciclo.key,
            cicloOrdem: e.cicloOrdem,
            cicloDescricao: e.ciclo.descricao,
            chaveCiclo: e.key,
            guT_Pontuacao: e.guT_Pontuacao,
            guT_BSC_Pontuacao: e.guT_BSC_Pontuacao
          };
          arrayciclo.push(objciclo);
        });
        setCicloDem(arrayciclo);
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }, [DemandaAtualKey])

  const CancelarDemanda = async () => {
    setLoading(true)
    const token = Cookies.get("Token");
    try {
      if (MotivoCancelar.motivo === '') {
        setError("Motivo é obrigatório.");
        setTimeout(() => {
          setError("");
        }, 3000);
        setLoading(false)
        return;
      }
    } catch (error) {
      error.response.data.map((er) => {
        setError(`${er.campo}: ${er.mensagem}`)
      })
    }
    const data = {
      demandaKey: DemandaAtualKey,
      motivo: MotivoCancelar.motivo,
    }
    return axios({
      url: baseurl + 'Demanda/Cancelar',
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        setLoading(false)
        setSuccess("Demanda cancelada com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setCancelarDemandaModal(false)
        setMotivoCancelar('')
        ListaDemanda()
        return Promise.resolve()
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      })
  }

  const ConcluirDemanda = async () => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + 'Demanda/Concluir?key=' + DemandaAtualKey,
      method: 'PUT',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setSuccess(response.data)
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        ListaDemanda()
        setConcluirDemandaModal(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const CompartilharDemanda = async () => {
    setLoading(true)
    const url = new URL(window.location.href);
    const token = Cookies.get("Token");
    const data = {
      demandaKey: DemandaAtualKey,
      diasValidade: Compartilhar.diasValidade
    }
    return axios({
      url: baseurl + "Demanda/Compartilhar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then((response) => {
        setLoading(false)
        setSuccess("Demanda compartilhada com sucesso.");
        setUrlCompartilhar(url.origin)
        setKeyCompartilhar(response.data)
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setAuth(false)
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      })
  }

  const VerCompartilhada = async (key) => {
    return axios({
      url: baseurl + 'Demanda/VerCompartilhada?key=' + key,
      method: 'GET',
    })
      .then((response) => {
        const dem = response.data;
        const objDemandas = {
          id: dem.id,
          key: dem.key,
          titulo: dem.titulo,
          descricao: dem.descricao,
          criacaoUsuarioNome: dem.criacaoUsuarioNome,
          criacaoDataHora: dem.criacaoDataHora,
          solicitante: dem.solicitante,
          dataLimite: dem.dataLimite,
          faseDemandaId: dem.faseDemandaId,
          faseDescricao: dem.faseDescricao,
          setorGerenciaDescricao: dem.setorGerenciaDescricao,
          tipoDescricao: dem.tipoDescricao,
          linhaAtendimentoId: dem.linhaAtendimentoId,
          linhaAtendimentoDescricao: dem.linhaAtendimentoDescricao,
          diretrizEstrategicaDescricao: dem.diretrizEstrategicaDescricao,
          diretrizEstrategicaId: dem.diretrizEstrategicaId,
          pesoBSC: dem.pesoBSC,
          guT_Gravidade: dem.guT_Gravidade,
          guT_Urgencia: dem.guT_Urgencia,
          guT_Tendencia: dem.guT_Tendencia,
          guT_Pontuacao: dem.guT_Pontuacao,
          guT_BSC_Pontuacao: dem.guT_BSC_Pontuacao,
          expectativaEntrega: dem.expectativaEntrega,
          esforcoTotal: dem.esforcoTotal,
          cancelamentoDataHora: dem.cancelamentoDataHora,
          conclusaoDataHora: dem.conclusaoDataHora,
          cicloDescricao: dem.cicloDescricao,
          cicloOrdem: dem.cicloOrdem,
        }
        setDemCompartilhada(objDemandas)
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        return Promise.reject()
      })
  }

  const ListarCompartilhamentos = async (key) => {
    let arrayDem = []
    let objDem = {}
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + 'Demanda/ListarCompartilhamentos?demandaKey=' + key,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const dem = response.data;
        dem.forEach((e) => {
          objDem = {
            demandaId: e.demandaId,
            usuarioId: e.usuarioId,
            dataHora: e.dataHora,
            dataValidade: e.dataValidade !== 'undefined' ? e.dataValidade : '',
            quantidadeLeituras: e.quantidadeLeituras,
            demanda: e.demanda,
            usuario: e.usuario,
            id: e.id,
            key: e.key
          }
          arrayDem.push(objDem)
        })
        setListaDemCompartilhada(arrayDem)
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        return Promise.reject()
      })
  }

  const CriarSwot = async () => {
    setLoading(true)
    const token = Cookies.get("Token");
    const data = {
      empresaKey: EmpresaKey,
      descricao: Swot.descricao,
      areaKey: Swot.areaKey,
      forca: ForcaSwot,
      fraqueza: FraquezaSwot,
      oportunidade: OportunidadeSwot,
      ameaca: AmeacaSwot,
      cicloDemandaKey: Swot.cicloDemandaKey
    };
    if (typeof Swot.descricao === "undefined" || Swot.descricao === "0") {
      setLoading(false)
      setError("Descrição não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof Swot.areaKey === "undefined" || Swot.areaKey === "0") {
      setLoading(false)
      setError("Área não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof Swot.cicloDemandaKey === "undefined" || Swot.cicloDemandaKey === "0") {
      setLoading(false)
      setError("Ciclo não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    return axios({
      url: baseurl + "Swot/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        setLoading(false);
        setSuccess("SWOT adicionado com sucesso.");
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        ListaSwot()
        setSwot({})
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const EditarSwot = async () => {
    const token = Cookies.get("Token");
    const data = {
      empresaKey: EmpresaKey,
      descricao: SwotObter.descricao,
      areaKey: SwotObter.areaKey || SwotObter.area.key,
      cicloDemandaKey: SwotObter.cicloDemandaKey || SwotObter.cicloDemanda.key
    };
    return axios({
      url: baseurl + "Swot/Alterar?key=" + SwotAtualKey,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaSwot();
        setSuccess("Swot editado com sucesso!");
        setModalEditarDemanda(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setSwotObter([])
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ListaSwot = useCallback(async () => {
    let objSwot = {};
    let arraySwot = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Swot/Listar?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const swot = eval(response.data);
        swot.forEach((e) => {
          objSwot = {
            descricao: e.descricao,
            dataCriacao: e.dataCriacao,
            usuario: e.usuario.nome,
            chave: e.key,
            areaKey: e.areaKey,
            cicloDemandaKey: e.cicloDemandaKey
          };
          arraySwot.push(objSwot);
        });
        setSwotLista(arraySwot);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ListaSwotPorCiclo = useCallback(async (id) => {
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Swot/ListarPorCiclo?empresaKey=" + EmpresaKey + "&cicloId=" + id,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        setSwotPorCiclo(response.data)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ObterSwot = useCallback(async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Swot/ObterKey?key=" + key,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const swot = response.data;
        const objSwot = {
          empresaId: swot.empresaId,
          descricao: swot.descricao,
          dataCriacao: swot.dataCriacao,
          usuarioId: swot.usuarioId,
          usuario: swot.usuario,
          empresa: swot.empresa,
          id: swot.id,
          key: swot.key,
          areaId: swot.areaId,
          cicloDemandaId: swot.cicloDemandaId,
          area: swot.area,
          cicloDemanda: swot.cicloDemanda
        }
        setSwotObter(objSwot);
        return Promise.resolve()
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        console.log(error)
        return Promise.reject()
      })
  })

  const ExcluirSwot = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Swot/Excluir?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaSwot()
        setSuccess("SWOT excluído com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirDemanda(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const AdicionarSwotItem = async () => {
    const token = Cookies.get("Token");
    setLoading(true);
    if (!AddSwotItem) {
      setError("Preencha os valores.");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (!AddSwotItem.capacidadeNegocioDemandaKey || AddSwotItem.capacidadeNegocioDemandaKey == "") {
      setError("Capacidade: campo obrigatório.");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (!AddSwotItem.gravidade || AddSwotItem.gravidade == "") {
      setError("Gravidade: campo obrigatório.");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (!AddSwotItem.urgencia || AddSwotItem.urgencia == "") {
      setError("Urgência: campo obrigatório.");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (!AddSwotItem.tendencia || AddSwotItem.tendencia == "") {
      setError("Tendência: campo obrigatório.");
      setLoading(false);
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      swotKey: SwotAtualKey,
      capacidadeNegocioDemandaKey: AddSwotItem.capacidadeNegocioDemandaKey,
      gravidade: AddSwotItem.gravidade,
      urgencia: AddSwotItem.urgencia,
      tendencia: AddSwotItem.tendencia,
      gut: AddSwotItem.gut,
      tipo: AddSwotItem.tipo,
    };
    return axios({
      url: baseurl + "Swot/AdicionarItem",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaSwotItem()
        setSuccess("Adicionado com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
        setAddSwotItem({
          capacidadeNegocioDemandaKey: "",
          gravidade: "",
          urgencia: "",
          tendencia: "",
          gut: "",
          tipo: "",
        });
        setForcaItem({
          capacidadeNegocioDemandaKey: "",
          gravidade: "",
          urgencia: "",
          tendencia: "",
          gut: "",
          tipo: "",
        });
        setFraquezaItem({
          capacidadeNegocioDemandaKey: "",
          gravidade: "",
          urgencia: "",
          tendencia: "",
          gut: "",
          tipo: "",
        });
        setOportunidadeItem({
          capacidadeNegocioDemandaKey: "",
          gravidade: "",
          urgencia: "",
          tendencia: "",
          gut: "",
          tipo: "",
        });
        setAmeacaItem({
          capacidadeNegocioDemandaKey: "",
          gravidade: "",
          urgencia: "",
          tendencia: "",
          gut: "",
          tipo: "",
        });
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaSwotItem = useCallback(async () => {
    let objSwot = {};
    let arraySwot = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Swot/ListarItem?SwotKey=" + SwotAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const swot = eval(response.data);
        swot.forEach((e) => {
          objSwot = {
            swotId: e.swotId,
            capacidadeNegocioDemandaId: e.capacidadeNegocioDemandaId,
            gravidade: e.gravidade,
            urgencia: e.urgencia,
            tendencia: e.tendencia,
            gut: e.gut,
            tipo: e.tipo,
            swot: e.swot,
            capacidadeNegocioDemanda: e.capacidadeNegocioDemanda,
            id: e.id,
            key: e.key
          };
          arraySwot.push(objSwot);
        });
        setSwotItemLista(arraySwot);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ExcluirSwotItem = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Swot/ExcluirItem?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false);
        ListaSwotItem();
        setSuccess("Excluído com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ListaMatriz = useCallback(async (key) => {
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Swot/Matriz?key=" + key,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const swotData = eval(response.data);
        let objSwot = {
          empresaId: swotData.swot.empresaId,
          descricao: swotData.swot.descricao,
          dataCriacao: swotData.swot.dataCriacao,
          usuarioId: swotData.swot.usuarioId,
          usuario: swotData.swot.usuario,
          empresa: swotData.swot.empresa,
          id: swotData.swot.id,
          key: swotData.swot.key,
          forcas: [],
          fraquezas: [],
          oportunidades: [],
          ameacas: []
        };
        objSwot.forcas = swotData.forca.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));
        objSwot.fraquezas = swotData.fraqueza.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));
        objSwot.oportunidades = swotData.oportunidade.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));
        objSwot.ameacas = swotData.ameaca.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));

        setSwotMatriz(objSwot);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ListaRelatorio = useCallback(async (key) => {
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Swot/Relatorio?key=" + key,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const swotData = eval(response.data);
        let objSwot = {
          empresaId: swotData.swot.empresaId,
          descricao: swotData.swot.descricao,
          dataCriacao: swotData.swot.dataCriacao,
          usuarioId: swotData.swot.usuarioId,
          usuario: swotData.swot.usuario,
          empresa: swotData.swot.empresa,
          id: swotData.swot.id,
          key: swotData.swot.key,
          indice: swotData.indice,
          porcentagemForca: swotData.porcentagemForca,
          porcentagemFraqueza: swotData.porcentagemFraqueza,
          porcentagemOportunidade: swotData.porcentagemOportunidade,
          porcentagemAmeaca: swotData.porcentagemAmeaca,
          quantidadeForca: swotData.quantidadeForca,
          quantidadeFraqueza: swotData.quantidadeFraqueza,
          quantidadeOportunidade: swotData.quantidadeOportunidade,
          quantidadeAmeaca: swotData.quantidadeAmeaca,
          forcas: [],
          fraquezas: [],
          oportunidades: [],
          ameacas: []
        };
        objSwot.forcas = swotData.forca.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));
        objSwot.fraquezas = swotData.fraqueza.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));
        objSwot.oportunidades = swotData.oportunidade.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));
        objSwot.ameacas = swotData.ameaca.map((item) => ({
          swotId: item.swotId,
          capacidadeNegocioDemandaId: item.capacidadeNegocioDemandaId,
          gravidade: item.gravidade,
          urgencia: item.urgencia,
          tendencia: item.tendencia,
          gut: item.gut,
          tipo: item.tipo,
          id: item.id,
          key: item.key,
          capacidadeNegocioDemanda: item.capacidadeNegocioDemanda
        }));

        setSwotRelatorio(objSwot);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const CadastrarSistema = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (CadastroSistema === "") {
      setLoading(false)
      setError("Formulário vazio.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroSistema.descricao === "undefined" || CadastroSistema.descricao === "") {
      setLoading(false)
      setError("Nome não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroSistema.fornecedor === "undefined" || CadastroSistema.fornecedor === "") {
      setLoading(false)
      setError("Fornecedor não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      EmpresaKey: EmpresaKey,
      descricao: CadastroSistema.descricao,
      fornecedor: CadastroSistema.fornecedor,
    };
    return axios({
      url: baseurl + "Sistema/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaSistemas()
        setSuccess("Sistema cadastrado com sucesso!");
        setCadastroSistema({
          descricao: "",
          fornecedor: ""
        })
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaSistemas = useCallback(async () => {
    let objSistemas = {};
    let arraySistemas = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Sistema/Listar?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          objSistemas = {
            descricao: e.descricao,
            fornecedor: e.fornecedor,
            chave: e.key
          };
          arraySistemas.push(objSistemas);
        });
        setSistemas(arraySistemas);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const EditarSistema = async () => {
    const token = Cookies.get("Token");
    setLoading(true)
    const data = {
      empresaKey: EmpresaKey,
      descricao: EdicaoSistema.descricao,
      fornecedor: EdicaoSistema.fornecedor,
    };
    return axios({
      url: baseurl + "Sistema/Alterar?key=" + SistemaAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaSistemas();
        setSuccess("Sistema editado com sucesso!");
        setModalEditarSistema(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirSistema = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Sistema/Deletar?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaSistemas()
        setSuccess("Sistema excluído com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirSistema(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const CadastrarArea = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (typeof CadastroArea.descricao === "undefined" || CadastroArea.descricao === "") {
      setLoading(false)
      setError("Descrição não informada.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      EmpresaKey: EmpresaKey,
      descricao: CadastroArea.descricao,
      itens: ItensArea,
    };
    return axios({
      url: baseurl + "Area/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaArea()
        setSuccess("Área cadastrada com sucesso!");
        setCadastroArea({
          descricao: ""
        })
        setItensArea([])
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaArea = useCallback(async () => {
    let objArea = {};
    let arrayArea = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Area/Listar?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          objArea = {
            descricao: e.descricao,
            chave: e.key,
            id: e.id,
          };
          arrayArea.push(objArea);
        });
        setAreas(arrayArea);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ListaAreaPorCiclo = useCallback(async (cicloId) => {
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Area/listarPorCiclo?empresaKey=" + EmpresaKey + "&cicloId=" + cicloId,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        setAreaPorCiclo(response.data);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const EditarArea = async () => {
    const token = Cookies.get("Token");
    setLoading(true)
    const data = {
      empresaKey: EmpresaKey,
      descricao: EdicaoArea.descricao,
      itens: EdicaoArea.itens
    };
    return axios({
      url: baseurl + "Area/Alterar?key=" + AreaAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaArea();
        setSuccess("Área editada com sucesso!");
        setModalEditarArea(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirArea = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Area/Deletar?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaArea()
        setSuccess("Área excluída com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirArea(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        setError("Área não pôde ser excluída, pois pertence a um SWOT.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const AdicionarItensArea = async (pessoaKey, cargoKey) => {
    const token = Cookies.get("Token");
    setLoading(true);
    if (cargoKey && !pessoaKey) {
      setLoading(false)
      setError("Pessoa não informada.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    } else if (pessoaKey && !cargoKey) {
      setLoading(false)
      setError("Cargo não informado.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      areaKey: AreaAtualKey,
      pessoaKey: pessoaKey,
      cargoKey: cargoKey
    };
    return axios({
      url: baseurl + "Area/AdicionarItem",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaItensArea()
        setSuccess("Item adicionado com sucesso!");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaItensArea = useCallback(async () => {
    let objArea = {};
    let arrayArea = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Area/ListarItem?areaKey=" + AreaAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          objArea = {
            pessoa: e.pessoa,
            cargo: e.cargo,
            chave: e.key
          };
          arrayArea.push(objArea);
        });
        setAreaItens(arrayArea);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ExcluirItensArea = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Area/ExcluirItem?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaItensArea()
        setSuccess("Item excluído com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const CriarCiclo = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    let dataInicialISO = '';
    let dataFinalISO = '';
    try {
      if (CicloCadastro === "") {
        setLoading(false)
        setError("Formulário vazio.");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof CicloCadastro.descricao === "undefined" || CicloCadastro.descricao === "") {
        setLoading(false)
        setError("Descrição não informada");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof CicloCadastro.observacao === "undefined" || CicloCadastro.observacao === "") {
        setLoading(false)
        setError("Observação não informada");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof CicloCadastro.dataInicial === "undefined" || CicloCadastro.dataInicial === "undefined") {
        setLoading(false)
        setError("Data inicial não informada");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof CicloCadastro.dataFinal === "undefined" || CicloCadastro.dataFinal === "undefined") {
        setLoading(false)
        setError("Data final não informada");
        setTimeout(() => {
          setError("");
        }, 3000);
        return;
      }
      if (typeof CicloCadastro.dataInicial != "undefined" && CicloCadastro.dataInicial != "") {
        dataInicialISO = new Date(CicloCadastro.dataInicial).toISOString();
      }
      if (typeof CicloCadastro.dataFinal != "undefined" && CicloCadastro.dataFinal != "") {
        dataFinalISO = new Date(CicloCadastro.dataFinal).toISOString();
      }
    } catch (error) {
    }
    const data = {
      EmpresaKey: EmpresaKey,
      descricao: CicloCadastro.descricao,
      observacao: CicloCadastro.observacao,
      dataInicial: dataInicialISO,
      dataFinal: dataFinalISO,
      naoAlterarBSCSwot: CicloCadastro.naoAlterarBSCSwot
    };
    return axios({
      url: baseurl + "CicloDemanda/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCiclos()
        setSuccess("Ciclo cadastrado com sucesso!");
        setCicloCadastro({})
        setModalCriarCiclo(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaSistemaCapacidade = async () => {
    setLoading(true)
    let arraySistema = [];
    let objSistema = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "CapacidadeNegocioDemandaSistema/Listar?capacidadeNegocioDemandaSistemaKey=" + CapacidadeAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          objSistema = {
            descricao: e.sistema.descricao,
            chave: e.sistema.key,
          };
          arraySistema.push(objSistema);
        });
        setSistemaCapacidade(arraySistema);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const CadastrarPlanoNegocio = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (PlanoNegocio === "") {
      setLoading(false)
      setError("Formulário vazio.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof PlanoNegocio.indicador === "undefined" || PlanoNegocio.indicador === "") {
      setLoading(false)
      setError("Indicador não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof PlanoNegocio.tipo === "undefined" || PlanoNegocio.tipo === "0") {
      setLoading(false)
      setError("Tipo não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof PlanoNegocio.valor === "undefined" || PlanoNegocio.valor === "") {
      setLoading(false)
      setError("Valor não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof PlanoNegocio.avaliacaoKey === "undefined" || PlanoNegocio.avaliacaoKey === "") {
      setLoading(false)
      setError("Avaliação relacionada não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      indicador: PlanoNegocio.indicador,
      valor: PlanoNegocio.valor,
      tipo: PlanoNegocio.tipo,
      ativo: true,
      avaliacaoKey: PlanoNegocio.avaliacaoKey,
    };
    return axios({
      url: baseurl + "PlanoNegocio/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaPlanoNegocio()
        setSuccess("Plano de Negócios cadastrado com sucesso!");
        setOpenModal(false)
        setPlanoNegocio({
          avaliacao: "",
          indicador: "",
          valor: "",
          tipo: "",
          ativo: "",
          planoNegocioPaiKey: "",
        })
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaPlanoNegocio = useCallback(async () => {
    let objPlano = {};
    let arrayPlano = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "PlanoNegocio/Listar?empresaKey=" + EmpresaKey + "&somenteAtivas=N",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const plano = eval(response.data);
        plano.forEach((e) => {
          objPlano = {
            indicador: e.indicador,
            valor: e.valor,
            tipo: e.tipo,
            avaliacaoPlanoNegocio: e.avaliacaoPlanoNegocio,
            avaliacaoPlanoNegocioId: e.avaliacaoPlanoNegocioId,
            ativo: e.ativo,
            empresa: e.empresa,
            chave: e.key
          };
          arrayPlano.push(objPlano);
        });
        setPlanoNegocios(arrayPlano);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ListaTipoPlanoNegocio = useCallback(async () => {
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "PlanoNegocio/ListarTipos",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const plano = eval(response.data);
        setTipoPlanoNegocio(plano);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const EditarPlanoNegocio = async () => {
    const token = Cookies.get("Token");
    setLoading(true)
    if (typeof EdicaoPlanoNegocio.avaliacao === "undefined" || EdicaoPlanoNegocio.avaliacao === "") {
      setLoading(false)
      setError("Avaliação não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof EdicaoPlanoNegocio.indicador === "undefined" || EdicaoPlanoNegocio.indicador === "") {
      setLoading(false)
      setError("Indicador não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof EdicaoPlanoNegocio.tipo === "undefined" || EdicaoPlanoNegocio.tipo === "0") {
      setLoading(false)
      setError("Tipo não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof EdicaoPlanoNegocio.valor === "undefined" || EdicaoPlanoNegocio.valor === "") {
      setLoading(false)
      setError("Valor não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      avaliacao: EdicaoPlanoNegocio.avaliacao,
      indicador: EdicaoPlanoNegocio.indicador,
      valor: EdicaoPlanoNegocio.valor,
      tipo: EdicaoPlanoNegocio.tipo,
      planoNegocioPaiKey: EdicaoPlanoNegocio.planoNegocioPaiKey,
      ativo: true,
    };
    return axios({
      url: baseurl + "PlanoNegocio/Alterar?key=" + PlanoNegocioAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaPlanoNegocio();
        setSuccess("Plano de Negócio editado com sucesso!");
        setModalEditarPlanoNegocio(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirPlanoNegocio = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "PlanoNegocio/Deletar?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaPlanoNegocio()
        setSuccess("Plano de Negócio excluído com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirPlanoNegocio(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        setError("Não foi possível excluir o item.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const StatusPlanoNegocio = async (key, status) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "PlanoNegocio/Ativo?key=" + key + "&status=" + status,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        ListaPlanoNegocio()
        if (status) {
          setSuccess("Plano de negócio ativado com sucesso.")
        } else {
          setSuccess("Plano de negócio inativado com sucesso.")
        }
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const CadastrarPlanoNegocioAvaliacao = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (typeof PlanoNegocioAvaliacao === "undefined" || PlanoNegocioAvaliacao === "") {
      setLoading(false)
      setError("Descrição não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      descricao: PlanoNegocioAvaliacao
    };
    return axios({
      url: baseurl + "PlanoNegocio/AdicionarAvaliacao",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaPlanoNegocioAvaliacao()
        setSuccess("Avaliação cadastrada com sucesso!");
        setPlanoNegocioAvaliacao("")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaPlanoNegocioAvaliacao = useCallback(async () => {
    let objPlano = {};
    let arrayPlano = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "PlanoNegocio/ListarAvaliacao?empresaKey=" + EmpresaKey + "&somenteAtivas=N",
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const plano = eval(response.data);
        plano.forEach((e) => {
          objPlano = {
            descricao: e.descricao,
            chave: e.key,
            ativo: true
          };
          arrayPlano.push(objPlano);
        });
        setAvaliacao(arrayPlano);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ExcluirPlanoNegocioAvaliacao = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "PlanoNegocio/DeletarAvaliacao?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaPlanoNegocioAvaliacao()
        setSuccess("Plano de Negócio excluído com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirPlanoNegocio(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        setError("Não foi possível excluir o item.")
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const ListaPlanoDemanda = useCallback(async () => {
    let array = [];
    let obj = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ListarPlanoNegocio?demandaKey=" + DemandaAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            demandaId: e.demandaId,
            planoNegocioId: e.planoNegocioId,
            valor: e.valor,
            planoNegocio: e.planoNegocio,
            id: e.id,
            chave: e.key
          };
          array.push(obj);
        });
        setPlanoDemanda(array.reverse());
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  })

  const ExcluirPlanoDemanda = async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ExcluirPlanoNegocio?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        ListaPlanoDemanda();
        setSuccess(response.data);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ObterDiretrizesItemsDoCiclo = useCallback(async (ciclo) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "BSC/ObterDiretrizesItemsDoCiclo?empresaKey=" + EmpresaKey + "&cicloDemandaKey=" + ciclo,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        setDiretrizesItemsDoCiclo(response.data);
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        return Promise.reject()
      })
  })

  const ObterGutSwot = async (areaKey, cicloDemandaKey) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Swot/ObterGut?areaKey=" + areaKey + "&cicloDemandaKey=" + cicloDemandaKey,
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        setGutSwot(response.data);
        return Promise.resolve(response.data)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        else {
          setLoading(false);
        }
        return Promise.reject()
      })
  }

  const AdicionarCapacidadeNegocio = async (capacidadeKey, areaKey) => {
    const token = Cookies.get("Token");
    setLoading(true);
    const data = {
      demandaKey: DemandaAtualKey,
      capacidadeNegocioKey: capacidadeKey,
      areaKey: areaKey,
    };
    return axios({
      url: baseurl + "Demanda/AdicionarCapacidadeNegocio",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCapacidadeNegocio()
        setSuccess("Capacidade adicionada com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaCapacidadeNegocio = async () => {
    let array = [];
    let obj = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ListarCapacidadeNegocio?demandaKey=" + DemandaAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            empresaId: e.empresaId,
            capacidadeNegocioDemandaId: e.capacidadeNegocioDemandaId,
            demanda: e.demanda,
            capacidadeNegocioDemanda: e.capacidadeNegocioDemanda,
            id: e.id,
            key: e.key,
          };
          array.push(obj);
        });
        setCapacidadeDemanda(array);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirCapacidadeNegocio = async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ExcluirCapacidadeNegocio?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        ListaCapacidadeNegocio();
        setSuccess("Capacidade excluída com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const CadastrarCargo = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (CadastroCargo === "") {
      setLoading(false)
      setError("Formulário vazio.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroCargo.descricao === "undefined" || CadastroCargo.descricao === "") {
      setLoading(false)
      setError("Descrição não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      descricao: CadastroCargo.descricao,
      ativo: true
    };
    return axios({
      url: baseurl + "Cargo/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCargo()
        setSuccess("Cargo cadastrado com sucesso!");
        setCadastroCargo({
          descricao: ""
        })
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaCargo = useCallback(async () => {
    let obj = {};
    let array = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Cargo/Listar?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            descricao: e.descricao,
            chave: e.key,
            id: e.id,
          };
          array.push(obj);
        });
        setCargos(array);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const EditarCargo = async () => {
    const token = Cookies.get("Token");
    setLoading(true)
    const data = {
      empresaKey: EmpresaKey,
      descricao: EdicaoCargo.descricao,
      ativo: true
    };
    return axios({
      url: baseurl + "Cargo/Alterar?key=" + CargoAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCargo();
        setSuccess("Cargo editado com sucesso!");
        setModalEditarCargo(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirCargo = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Cargo/Deletar?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaCargo()
        setSuccess("Cargo excluído com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirCargo(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const CadastrarPessoa = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (CadastroPessoa === "") {
      setLoading(false)
      setError("Formulário vazio.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroPessoa.nome === "undefined" || CadastroPessoa.nome === "") {
      setLoading(false)
      setError("Nome não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroPessoa.cargoKey === "undefined" || CadastroPessoa.cargoKey === 0) {
      setLoading(false)
      setError("Cargo não informado");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      nome: CadastroPessoa.nome,
      cargoKey: CadastroPessoa.cargoKey,
      ativo: true
    };
    return axios({
      url: baseurl + "Pessoa/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaPessoa()
        setSuccess("Pessoa cadastrada com sucesso!");
        setCadastroPessoa({
          nome: "",
          cargoKey: 0
        })
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaPessoa = useCallback(async () => {
    let obj = {};
    let array = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Pessoa/Listar?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            nome: e.nome,
            cargo: e.cargo,
            chave: e.key,
            id: e.id,
          };
          array.push(obj);
        });
        setPessoas(array);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ListaPessoaPorCargo = useCallback(async (key) => {
    let obj = {};
    let array = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Pessoa/ListarPorCargo?empresaKey=" + EmpresaKey + "&cargoKey=" + key,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            nome: e.nome,
            cargo: e.cargo,
            chave: e.key,
            id: e.id,
          };
          array.push(obj);
        });
        setPessoasPorCargo(array);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
          setTimeout(() => {
            setError("");
          }, 3000);
        })
        return Promise.reject();
      });
  })

  const EditarPessoa = async (key) => {
    const token = Cookies.get("Token");
    setLoading(true)
    const data = {
      empresaKey: EmpresaKey,
      nome: EdicaoPessoa.nome,
      cargoKey: key,
      ativo: true
    };
    return axios({
      url: baseurl + "Pessoa/Alterar?key=" + PessoaAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaPessoa();
        setSuccess("Pessoa editada com sucesso!");
        setModalEditarPessoa(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirPessoa = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Pessoa/Deletar?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaPessoa()
        setSuccess("Pessoa excluída com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirPessoa(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const CadastrarCompetencia = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (CadastroCompetencia === "") {
      setLoading(false)
      setError("Formulário vazio.");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroCompetencia.descricao === "undefined" || CadastroCompetencia.descricao === "") {
      setLoading(false)
      setError("Descrição não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      descricao: CadastroCompetencia.descricao,
      ativo: true
    };
    return axios({
      url: baseurl + "Competencia/Adicionar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCompetencia()
        setSuccess("Competência cadastrada com sucesso!");
        setCadastroCompetencia({
          descricao: ""
        })
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaCompetencia = useCallback(async () => {
    let obj = {};
    let array = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Competencia/Listar?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            descricao: e.descricao,
            chave: e.key,
            id: e.id,
          };
          array.push(obj);
        });
        setCompetencias(array);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const EditarCompetencia = async () => {
    const token = Cookies.get("Token");
    setLoading(true)
    const data = {
      empresaKey: EmpresaKey,
      descricao: EdicaoCompetencia.descricao,
      ativo: true
    };
    return axios({
      url: baseurl + "Competencia/Alterar?key=" + CompetenciaAtualKey,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCompetencia();
        setSuccess("Competência editada com sucesso!");
        setModalEditarCompetencia(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirCompetencia = async (key) => {
    setLoading(true)
    const token = Cookies.get("Token")
    return axios({
      url: baseurl + "Competencia/Deletar?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaCompetencia()
        setSuccess("Competência excluída com sucesso!")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setModalExcluirCompetencia(false)
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false)
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error)
      })
  }

  const ListaBSC = useCallback(async (key) => {
    let obj = {};
    let array = [];
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "BSC/Listar?empresaKey=" + EmpresaKey + "&cicloDemandaKey=" + key,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        setBSCs(response.data)
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            alteracaoDataHora: e.alteracaoDataHora,
            chave: e.key,
            cicloDemanda: e.cicloDemanda,
            cicloDemandaId: e.cicloDemandaId,
            criacaoDataHora: e.criacaoDataHora,
            descricao: e.descricao,
            empresaId: e.empresaId,
            id: e.id,
            padrao: e.padrao
          };
          array.push(obj);
        });
        setBSCs(array);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const CadastrarBSC = async (event) => {
    event.preventDefault();
    const token = Cookies.get("Token");
    setLoading(true);
    if (typeof CadastroBSC.cicloDemandaKey === "undefined" || CadastroBSC.cicloDemandaKey === "") {
      setLoading(false)
      setError("Informe um ciclo na tela anterior");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    if (typeof CadastroBSC.descricao === "undefined" || CadastroBSC.descricao.trim() === "") {
      setLoading(false)
      setError("Descrição não informada");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }
    const data = {
      empresaKey: EmpresaKey,
      cicloDemandaKey: CadastroBSC.cicloDemandaKey,
      descricao: CadastroBSC.descricao,
    };
    return axios({
      url: baseurl + "BSC/Criar",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaBSC(CadastroBSC.cicloDemandaKey)
        setSuccess("BSC cadastrado com sucesso!");
        setCadastroBSC([])
        setOpenModal(false);
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const BSCPadrao = async (key, cicloKey) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "BSC/DefinirPadrao?BSCKey=" + key,
      method: "PUT",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        ListaBSC(cicloKey)
        setSuccess("Atualizado com sucesso.")
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarCompetenciaItem = async (key, quantidade) => {
    const token = Cookies.get("Token");
    setLoading(true);
    const data = {
      demandaKey: DemandaAtualKey,
      competenciaKey: key,
      quantidade: quantidade
    };
    return axios({
      url: baseurl + "Demanda/AdicionarCompetencia",
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      data: data,
    })
      .then(() => {
        ListaCompetenciaItem()
        setSuccess("Competência adicionada com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaCompetenciaItem = async () => {
    let array = [];
    let obj = {};
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ListarCompetencia?demandaKey=" + DemandaAtualKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        const user = eval(response.data);
        user.forEach((e) => {
          obj = {
            quantidade: e.quantidade,
            competencia: e.competencia,
            id: e.id,
            key: e.key,
          };
          array.push(obj);
        });
        setCompetenciaDemanda(array);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const ExcluirCompetenciaItem = async (key) => {
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Demanda/ExcluirCompetencia?key=" + key,
      method: "DELETE",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        ListaCompetenciaItem();
        setSuccess("Competência excluída com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
        setLoading(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setLoading(false);
          setAuth(false)
          return
        }
        setLoading(false);
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const getArvoreCapacidades = async (cicloKey) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + `Dashboard/DashboardArvoreCapacidade?empresaKey=${EmpresaKey}&cicloKey=${cicloKey}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        if (response.data.length !== 0) {
          setArvoreDeCapacidades(response.data)
        }
        else {
          setSuccess("Nenhuma informação encontrada");
          setArvoreDeCapacidades([])
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        }

      })
      .catch((error) => {
        setLoading(false)
        // if (error.response.status === 401) {
        //   setError(`Sua sessão expirou, faça login novamente`)
        //   setTimeout(() => {
        //     setError("");
        //   }, 3000);

        //   setAuth(false)
        //   return
        // }
        // error.response.data.map((er) => {
        //   setError(`${er.campo}: ${er.mensagem}`)
        // })
        // setTimeout(() => {
        //   setError("");
        // }, 3000);
        console.log(error)
        return Promise.reject();
      });
  }

  const getCustoXDemanda = async (cicloKey, qtdExibir) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + `Dashboard/DashboardCustoDemanda?empresaKey=${EmpresaKey}&cicloKey=${cicloKey}&Quantidade=${qtdExibir}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        if (response.data.length !== 0) {
          setLoading(false)
          setCustoXDemanda(response.data)
        }
        else {
          setSuccess("Nenhuma informação encontrada");
          setCustoXDemanda([])
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        }

      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);

          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const getDemandaXCapacidade = async (swotKey, qtdExibir) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + `Dashboard/DashboardCapacidadesDemanda?swotKey=${swotKey}&Quantidade=${qtdExibir}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        if (response.data.length !== 0) {
          setDemandaXCapacidade(response.data)
        }
        else {
          setSuccess("Nenhuma informação encontrada");
          setDemandaXCapacidade([])
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        }

      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);

          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const getSistemaXDemanda = async (cicloKey, qtdExibir) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + `Dashboard/DashboardSistemaDemanda?cicloKey=${cicloKey}&Quantidade=${qtdExibir}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        if (response.data.length !== 0) {
          setSistemaXDemanda(response.data)
        }
        else {
          setSuccess("Nenhuma informação encontrada");
          setSistemaXDemanda([])
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        }

      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);

          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const getBscXSwotXGut = async (demandaKey, swotKey) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + `Dashboard/DashboardBSCSwotGut?demandaKey=${demandaKey}&swotKey=${swotKey}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        if (response.data.length !== 0) {
          setBscXSwotXGut(response.data)
        }
        else {
          setSuccess("Nenhuma informação encontrada");
          setBscXSwotXGut([])
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        }

      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);

          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const DashboardSwot = async (swotKey) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + `Dashboard/DashboardSwot?empresaKey=${EmpresaKey}&swotKey=${swotKey}`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        if (response.data.length !== 0) {
          setGraficoSwotCiclo(response.data)
        }
        else {
          setSuccess("Nenhuma informação encontrada");
          setGraficoSwotCiclo([])
          setTimeout(() => {
            setSuccess("");
          }, 3000);
        }

      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);

          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject();
      });
  }

  const AdicionarGraficoFavorito = async (nomeCargo) => {
    setLoading(true)
    const token = Cookies.get("Token");
    return axios({
      url: baseurl + "Dashboard/FavoritarGrafico?empresaKey=" + EmpresaKey + "&nomeGrafico=" + nomeCargo,
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then(() => {
        setLoading(false)
        ListaGraficosFavoritos()
        setSuccess("Alteração feita com sucesso.");
        setTimeout(() => {
          setSuccess("");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        setError(error.response.data)
        setTimeout(() => {
          setError("");
        }, 3000);
        return Promise.reject(error);
      });
  }

  const ListaGraficosFavoritos = useCallback(async () => {
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Dashboard/RetornaGraficoFavoritos?empresaKey=" + EmpresaKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        setFavoritos(response.data)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  const ListaDemandaPorCiclo = useCallback(async (cicloKey) => {
    const token = Cookies.get("Token");
    setLoading(true)
    return axios({
      url: baseurl + "Demanda/ListarPorCiclo?cicloKey=" + cicloKey,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        setLoading(false)
        setDemandaPorCiclo(response.data)
      })
      .catch((error) => {
        setLoading(false)
        if (error.response.status === 401) {
          setError(`Sua sessão expirou, faça login novamente`)
          setTimeout(() => {
            setError("");
          }, 3000);
          setAuth(false)
          return
        }
        error.response.data.map((er) => {
          setError(`${er.campo}: ${er.mensagem}`)
        })
        return Promise.reject();
      });
  })

  useEffect(() => {
    if (Auth) {
      setLoading(true)
      ListaEmpresas()
      // ObterBSC()
    }
  }, [Auth])

  let data = {
    EmpresaKey,
    SimularBSC,
    DemandasCiclo,
    setDemandasCiclo,
    CadastroCiclo,
    setCadastroCiclo,
    ListaCiclos,
    ListaDemandaCiclo,
    Ciclos,
    setCiclos,
    EditarCiclo,
    setEditarCiclo,
    EditarCiclos,
    modalEditarCiclo,
    setModalEditarCiclo,
    CicloAtualKey,
    setCicloAtualKey,
    CadastrarEmpresa,
    Cadastro,
    setCadastro,
    CadastrarUsuario,
    CadastroUsuario,
    setCadastroUsuario,
    ListaUsuarios,
    Usuarios,
    setUsuarios,
    StatusUsuario,
    ListaEmpresas,
    setEmpresas,
    setFormSelecionarCiclo,
    FormSelecionarCiclo,
    Empresas,
    ListaDashboard,
    Dashboard,
    setDashboard,
    DashboardDados,
    setDashboardDados,
    DashboardFases,
    setDashboardFases,
    DashboardLinhas,
    setDashboardLinhas,
    DashboardImpacto,
    setDashboardImpacto,
    ListaCapacidades,
    Capacidades,
    setCapacidades,
    ListaCategorizacao,
    Categorias,
    setCategorias,
    ListaFases,
    Fases,
    setFases,
    ListaLinhaAtendimento,
    Linhas,
    setLinhas,
    ListaSetorGerencia,
    Setores,
    setSetores,
    ListaTipoDemanda,
    Tipos,
    setTipos,
    ListaDemanda,
    Demandas,
    setDemandas,
    getEmpresa,
    Empresa,
    AdicionarItensDemanda,
    modalEditarOpen,
    setModalEditar,
    setKey,
    setDemandaAtual,
    DemandaAtual,
    EditarItensDemanda,
    AtualizarStatus,
    loading,
    setLoading,
    CriarDemanda,
    setForm,
    FormDemanda,
    setGut,
    Gut,
    ObterBSC,
    DiretrizEstrategica,
    setDiretrizEstrategica,
    Prioridades,
    ResumoPrioridades,
    setgutBSC,
    GutBSC,
    linhaAtendimento,
    setlinhaAtendimento,
    Error,
    Success,
    openModal,
    setOpenModal,
    EditarDemanda,
    EditarDemandaModal,
    setModalEditarDemanda,
    alterarGutBSC,
    AlterarGutBSC,
    setAlterarGutBSC,
    GutGravidade,
    setGutGravidade,
    GutUrgencia,
    setGutUrgencia,
    GutTendencia,
    setGutTendencia,
    BSC,
    setBSC,
    BSCFiltro,
    setBSCFiltro,
    DemandaAtualKey,
    setDemandaAtualKey,
    ExcluirDemanda,
    ExcluirDemandaModal,
    setModalExcluirDemanda,
    AdicionarComentario,
    AddComentarioModal,
    setAddComentarioModal,
    AddComentario,
    setAddComentario,
    ListaComentario,
    Comentario,
    setComentario,
    AdicionarLink,
    AddLink,
    setAddLink,
    ListaLink,
    Link,
    setLink,
    FiltroDemanda,
    setFiltroDemanda,
    DemandaDadoDash,
    ExcluirComentario,
    ExcluirLink,
    BSCDados,
    setBSCDados,
    SalvarBSC,
    staticKey,
    FormLink,
    setFormLink,
    FormComentario,
    setFormComentario,
    AdicionarDemandaCiclo,
    AdicionarLinha,
    EditarLinha,
    TrocarRoleModal,
    setModalTrocarRole,
    EditarRole,
    UserId,
    setIdUser,
    NomeUser,
    setNomeUser,
    AdminUser,
    setAdminUser,
    SupervisorUser,
    setSupervisorUser,
    LeitorUser, setLeitorUser,
    FormRisco,
    setFormRisco,
    setError,
    AdicionarRisco,
    ListaRisco,
    ExcluirRisco,
    AddRisco,
    setAddRisco,
    Risco,
    setRisco,
    show,
    setShow,
    comentarioRef,
    linkRef,
    descricaoRef,
    riscoJustificativaRef,
    mitigacaoJustificativaRef,
    modalEnviarCiclo,
    setModalEnviarCiclo,
    arraySelecionarDemandaCiclo,
    setArraySelecionarDemandaCiclo,
    ExcluirCiclo,
    ExcluirCicloModal,
    setModalExcluirCiclo,
    Descricao, setDescricao,
    CenarioAtual, setCenarioAtual,
    ProblemaCenarioAtual, setProblemaCenarioAtual,
    Objetivo, setObjetivo,
    Resultado, setResultado,
    Necessidade, setNecessidade,
    Oportunidade, setOportunidade,
    DataLimite, setDataLimite,
    Esforco, setEsforco,
    ExcluirDemandaCiclo,
    ExcluirDemandaCicloModal, setExcluirDemandaCicloModal,
    demandaCicloAtual, setDemandaCicloAtual,
    AddDemandaCiclo, setAddDemandaCiclo,
    RTFModal, setRTFModal,
    ListaCicloDemanda,
    CicloDem, setCicloDem,
    CadastrarCiclo,
    ObterDemanda,
    Dem, setDem,
    CancelarDemanda,
    CancelarDemandaModal, setCancelarDemandaModal,
    MotivoCancelar, setMotivoCancelar,
    ConcluirDemanda,
    ConcluirDemandaModal, setConcluirDemandaModal,
    CompartilharDemanda,
    Compartilhar, setCompartilhar,
    CompartilharDemandaModal, setCompartilharDemandaModal,
    VerCompartilhada,
    DemCompartilhada, setDemCompartilhada,
    ListarCompartilhamentos,
    ListaDemCompartilhada, setListaDemCompartilhada,
    KeyCompartilhar, setKeyCompartilhar,
    UrlCompartilhar, setUrlCompartilhar,
    EditarRisco,
    RiscoEditar, setRiscoEditar,
    AdicionarCapacidade,
    EditarCapacidade,
    CriarSwot,
    Swot, setSwot,
    SwotItem, setSwotItem,
    ListaSwot,
    SwotLista,
    ExcluirSwot,
    SwotAtualKey, setSwotAtualKey,
    ForcaSwot, setForcaSwot,
    FraquezaSwot, setFraquezaSwot,
    OportunidadeSwot, setOportunidadeSwot,
    AmeacaSwot, setAmeacaSwot,
    ObterSwot,
    SwotObter, setSwotObter,
    SwotItemLista, setSwotItemLista,
    ListaSwotItem,
    AdicionarSwotItem,
    AddSwotItem, setAddSwotItem,
    ExcluirSwotItem,
    EditarSwot,
    SwotMatriz, setSwotMatriz,
    ListaMatriz,
    SwotRelatorio, setSwotRelatorio,
    ListaRelatorio,
    forcaItem, setForcaItem,
    fraquezaItem, setFraquezaItem,
    oportunidadeItem, setOportunidadeItem,
    ameacaItem, setAmeacaItem,
    ListaArvoreCapacidades,
    ArvoreCapacidades, setArvoreCapacidades,
    CadastroSistema, setCadastroSistema,
    Sistemas, setSistemas,
    CadastrarSistema,
    ListaSistemas,
    EditarSistema,
    EdicaoSistema, setEdicaoSistema,
    SistemaAtualKey, setSistemaAtualKey,
    modalEditarSistema, setModalEditarSistema,
    ExcluirSistema,
    modalExcluirSistema, setModalExcluirSistema,
    CadastroArea, setCadastroArea,
    Areas, setAreas,
    CadastrarArea,
    ListaArea,
    EditarArea,
    EdicaoArea, setEdicaoArea,
    AreaAtualKey, setAreaAtualKey,
    modalEditarArea, setModalEditarArea,
    ExcluirArea,
    modalExcluirArea, setModalExcluirArea,
    CriarCiclo,
    CicloCadastro, setCicloCadastro,
    modalCriarCiclo, setModalCriarCiclo,
    ListaSistemaCapacidade,
    SistemaCapacidade, setSistemaCapacidade,
    CapacidadeAtualKey, setCapacidadeAtualKey,
    CadastrarPlanoNegocio,
    PlanoNegocio, setPlanoNegocio,
    ListaPlanoNegocio,
    PlanoNegocios, setPlanoNegocios,
    ListaTipoPlanoNegocio,
    TipoPlanoNegocio, setTipoPlanoNegocio,
    ExcluirPlanoNegocio,
    EditarPlanoNegocio,
    EdicaoPlanoNegocio, setEdicaoPlanoNegocio,
    PlanoNegocioAtualKey, setPlanoNegocioAtualKey,
    modalEditarPlanoNegocio, setModalEditarPlanoNegocio,
    modalExcluirPlanoNegocio, setModalExcluirPlanoNegocio,
    StatusPlanoNegocio,
    CadastrarPlanoNegocioAvaliacao,
    PlanoNegocioAvaliacao, setPlanoNegocioAvaliacao,
    ListaPlanoNegocioAvaliacao,
    Avaliacao,
    ExcluirPlanoNegocioAvaliacao,
    PlanoNegocioAvaliacaoAtualKey, setPlanoNegocioAvaliacaoAtualKey,
    FormPlano, setFormPlano,
    PlanoDemanda, ListaPlanoDemanda,
    ExcluirPlanoDemanda,
    ObterDiretrizesItemsDoCiclo,
    DiretrizesItemsDoCiclo,
    stringDiretrizesItems, setStringDiretrizesItems,
    GutSwot, setGutSwot,
    ObterGutSwot,
    CapacidadeDemanda,
    ListaCapacidadeNegocio,
    ExcluirCapacidadeNegocio,
    AdicionarCapacidadeNegocio,
    CadastroCargo, setCadastroCargo,
    Cargos, setCargos,
    CadastrarCargo,
    ListaCargo,
    EditarCargo,
    EdicaoCargo, setEdicaoCargo,
    CargoAtualKey, setCargoAtualKey,
    modalEditarCargo, setModalEditarCargo,
    ExcluirCargo,
    modalExcluirCargo, setModalExcluirCargo,
    CadastroPessoa, setCadastroPessoa,
    Pessoas, setPessoas,
    CadastrarPessoa,
    ListaPessoa,
    EditarPessoa,
    EdicaoPessoa, setEdicaoPessoa,
    PessoaAtualKey, setPessoaAtualKey,
    modalEditarPessoa, setModalEditarPessoa,
    ExcluirPessoa,
    modalExcluirPessoa, setModalExcluirPessoa,
    CadastroCompetencia, setCadastroCompetencia,
    Competencias, setCompetencias,
    CadastrarCompetencia,
    ListaCompetencia,
    EditarCompetencia,
    EdicaoCompetencia, setEdicaoCompetencia,
    CompetenciaAtualKey, setCompetenciaAtualKey,
    modalEditarCompetencia, setModalEditarCompetencia,
    ExcluirCompetencia,
    modalExcluirCompetencia, setModalExcluirCompetencia,
    ItensArea, setItensArea,
    AreaItens, setAreaItens,
    ListaItensArea,
    ExcluirItensArea,
    AdicionarItensArea,
    Capacidade, setCapacidade,
    EdicaoCapacidade, setEdicaoCapacidade,
    PessoasPorCargo, setPessoasPorCargo,
    ListaPessoaPorCargo,
    ListaCapacidadesPorArea,
    CapacidadesPorArea, setCapacidadesPorArea,
    BSCs, setBSCs,
    ListaBSC,
    CadastrarBSC,
    CadastroBSC, setCadastroBSC,
    modalEditarBSC, setModalEditarBSC,
    BSCPadrao,
    ObterBSCPorCiclo,
    BSCPorCiclo, setBSCPorCiclo,
    DiretrizEstrategicaPorCiclo, setDiretrizEstrategicaPorCiclo,
    PrioridadesPorCiclo, setPrioridadesPorCiclo,
    ResumoPrioridadesPorCiclo, setResumoPrioridadesPorCiclo,
    AdicionarCompetenciaItem,
    CompetenciaDemanda, setCompetenciaDemanda,
    ExcluirCompetenciaItem,
    ListaCompetenciaItem,
    showModalArvoreDeCapacidades, setModalArvoreDeCapacidades,
    ArvoreDeCapacidades, setArvoreDeCapacidades,
    getArvoreCapacidades,
    showModalCustoxdemanda, setModalCustoxdemanda,
    getCustoXDemanda,
    CustoXDemanda, setCustoXDemanda,
    AdicionarGraficoFavorito,
    ListaGraficosFavoritos,
    Favoritos,
    ListaSwotPorCiclo,
    SwotPorCiclo,
    DashboardSwot,
    GraficoSwotCiclo, setGraficoSwotCiclo,
    DemandaXCapacidade, setDemandaXCapacidade,
    getDemandaXCapacidade,
    getSistemaXDemanda,
    SistemaXDemanda,
    ListaDemandaPorCiclo,
    DemandaPorCiclo,
    getBscXSwotXGut,
    BscXSwotXGut,
    modalCriarDemanda, setModalCriarDemanda,
    ListaCapacidadePorAreaSwot,
    CapacidadePorAreaSwot, setCapacidadePorAreaSwot,
    ListaAreaPorCiclo, AreaPorCiclo,
    delibComite, setDelibComite
  };

  return <ApiContext.Provider value={data}>{children}</ApiContext.Provider>;
};
