import '../../App.css'
import React, { useContext, useState } from 'react'
import ModalNovaLinhaAtendimento from '../modal/ModalNovaLinhaAtendimento'
import { FaPlus } from 'react-icons/fa'
import { StyleContext } from '../../context/StyleContext'

function BotaoNovoCadastro() {

    const { btnCriar } = useContext(StyleContext)
    const [openModal, setopenModal] = useState(false)

    return (
        <div>
            {openModal &&
                <ModalNovaLinhaAtendimento closeModal={setopenModal} />
            }
            <button className='btn-dash btn-criar' style={btnCriar} onClick={setopenModal}><FaPlus style={{ marginRight: "10px" }} />Novo</button>
        </div>
    )
}

export default BotaoNovoCadastro