import { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { FaStar, FaRegStar } from "react-icons/fa";
import { LinearGaugeComponent, AxesDirective, AxisDirective, PointersDirective, PointerDirective, AnnotationDirective, Annotations, AnnotationsDirective, RangesDirective, RangeDirective } from '@syncfusion/ej2-react-lineargauge';
import { Inject } from '@syncfusion/ej2-react-charts';
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";

export default function Indice({ corFavorito, tab }) {
    const { AdicionarGraficoFavorito, SwotRelatorio } = useContext(ApiContext)
    const content = `<div style="width: 70px;margin-top: 25%;font-size: 16px;text-align: center">${SwotRelatorio && (SwotRelatorio.indice.toFixed(2))} %</div>`;
    const load = (args) => {
    };
    const cor = (valor) => {
        if (valor >= -200 && valor <= -100) {
            return "indice indice-red";
        } else if (valor > -100 && valor < -30) {
            return "indice indice-light-red";
        } else if (valor > -30 && valor < 30) {
            return "indice indice-yellow";
        } else if (valor > 30 && valor < 100) {
            return "indice indice-light-green";
        } else if (valor >= 100 && valor <= 200) {
            return "indice indice-green";
        }
    }
    return (
        <>
            <h2 className="h2-bsc" style={{ paddingLeft: '20px' }}>
                <button onClick={() => AdicionarGraficoFavorito("Índice de favorabilidade")} className='btn-grafico'>{corFavorito("Índice de favorabilidade") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                <span style={{ fontWeight: '500' }}>Índice de favorabilidade</span>
            </h2>
            <div style={{ padding: '0' }}>
                <div className='modal-body-swot'>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <div className={cor(SwotRelatorio?.indice.toFixed(2))}>
                            <p className='span-bsc'>Seu índice</p>
                            <span className='span-bsc'>{SwotRelatorio?.indice.toFixed(2)} %</span>
                        </div>
                    </div>
                </div>
                <div className='control-section'>
                    <LinearGaugeComponent load={load.bind(this)} background='transparent' orientation='Horizontal' height='200' width={'100%'} id={`${tab}-indice`}>
                        <Inject services={[Annotations]} />
                        <AxesDirective>
                            <AxisDirective minorTicks={{ interval: 100, height: 10, color: '#000' }} majorTicks={{ interval: 50, height: 20, color: '#000' }} labelStyle={{ offset: 48, font: { fontFamily: 'inherit' } }} minimum={-200} maximum={200} line={{ width: 2, color: '#000' }}>
                                <RangesDirective>
                                    <RangeDirective start={-200} end={-100} startWidth={20} endWidth={20} color='#F87171'>
                                    </RangeDirective>
                                    <RangeDirective start={-100} end={-30} startWidth={20} endWidth={20} color='#FFA99B'>
                                    </RangeDirective>
                                    <RangeDirective start={-30} end={0} startWidth={20} endWidth={20} color='#FACC15'>
                                    </RangeDirective>
                                    <RangeDirective start={0} end={30} startWidth={20} endWidth={20} color='#FACC15'>
                                    </RangeDirective>
                                    <RangeDirective start={30} end={100} startWidth={20} endWidth={20} color='#43ce97'>
                                    </RangeDirective>
                                    <RangeDirective start={100} end={200} startWidth={20} endWidth={20} color='#8AB54E'>
                                    </RangeDirective>
                                </RangesDirective>
                                <PointersDirective>
                                    <PointerDirective value={SwotRelatorio.indice.toFixed(2)} placement='Near' height={15} width={15} offset={-40} markerType='Triangle'>
                                    </PointerDirective>
                                </PointersDirective>
                            </AxisDirective>
                        </AxesDirective>
                        <AnnotationsDirective>
                            <AnnotationDirective content={content} axisIndex={0} axisValue={SwotRelatorio.indice.toFixed(2)} x={10} zIndex='1' y={-70} />
                        </AnnotationsDirective>
                    </LinearGaugeComponent>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderTop: '1px solid #E4E4E7' }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                        <span className='span-bsc'>Muito desfavorável</span>
                        <span className='span-swot span-swot-red'> <IoChevronDownOutline /> -100%</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                        <span className='span-bsc'>Desfavorável</span>
                        <span className='span-swot span-swot-red'>-30% a -100%</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                        <span className='span-bsc'>Equilíbrio</span>
                        <span className='span-swot span-swot-yellow'>-30% a 30%</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                        <span className='span-bsc'>Favorável</span>
                        <span className='span-swot span-swot-green'>30% a 100%</span>
                    </div>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', gap: '10px' }}>
                        <span className='span-bsc'>Muito favorável</span>
                        <span className='span-swot span-swot-green'><IoChevronUp />100%</span>
                    </div>
                </div>
            </div>
        </>
    )
}