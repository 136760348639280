import React, { createContext, useEffect, useState, useRef } from 'react'
import axios from 'axios'
import baseurl from "../services/empresa/baseurl";

export const StyleContext = createContext()

export const StyleContextProvider = ({ children }) => {

    const [loadingLayout, setLoadingLayout] = useState(true)
    const [corPrimaria, setCorPrimaria] = useState('')
    const [corSecundaria, setCorSecundaria] = useState('')
    const [corTerciaria, setTerciaria] = useState('')
    const [logoBackground, setlogoBackground] = useState('')
    const [logoLogin, setlogoLogin] = useState('')
    const [logoMenu, setlogoMenu] = useState('')
    const [logoBranca, setlogoBranca] = useState('')
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')
    const chartRef = useRef(null);

    const obterLayout = async () => {
        setLoadingLayout(true)
        return axios({
            url: baseurl + "ConfiguracaoCor/ObterCor?url=" + window.location.hostname,
            method: "GET",
            timeout: 15000,
        })
            .then((response) => {
                const dadosLayout = response.data
                setCorPrimaria(dadosLayout.corPrimaria)
                setCorSecundaria(dadosLayout.corSecundaria)
                setTerciaria(dadosLayout.corTerciaria)
                setlogoBackground(dadosLayout.logoBackground)
                setlogoLogin(dadosLayout.logoLogin)
                setlogoMenu(dadosLayout.logoMenu)
                setlogoBranca(dadosLayout.logoNavbar)
            })
            .finally(() => {
                setLoadingLayout(false)
            })
    }

    const bodyLogin = {
        width: '100%',
        margin: '0 auto',
        backgroundImage: `linear-gradient(#00000080, #00000080), url(${logoBackground})`,
        backgroundPosition: 'top',
        backgroundSize: 'cover'
    }

    const loginFormBtn = {
        marginTop: '25px',
        fontSize: '15px',
        fontWeight: '500',
        border: 'none',
        borderRadius: '8px',
        color: '#fff',
        lineHeight: 1.2,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '40px',
        background: `${corPrimaria}`,
        transition: '1s'
    }

    const btnSelecionar = {
        fontSize: '15px',
        width: '200px',
        height: '50px',
        border: 'none',
        borderRadius: '10px',
        color: '#fff',
        lineHeight: 1.2,
        textTransform: 'uppercase',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `${corSecundaria}`,
        transition: '1s'
    }

    const header = {
        height: '7vh',
        width: '100vw',
        position: 'fixed',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '2em 30px',
        boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px',
        justifyContent: 'space-between',
        zIndex: '2',
        background: `${corPrimaria}`
    }

    const btnVer = {
        border: `1px solid ${corSecundaria}`,
        color: `${corSecundaria}`
    }


    const btnCriar = {
        border: 'none',
        borderRadius: '5px',
        color: 'white',
        marginLeft: '15px',
        background: `${corSecundaria}`
    }

    const btnPesquisar = {
        color: 'white',
        backgroundColor: `${corTerciaria}`,
        borderRadius: '4px',
        border: 'none',
        display: 'flex',
        alignItems: 'center'
    }

    const h3Demanda = {
        borderRadius: '8px 8px 0 0',
        padding: '16px',
        margin: 0,
        color: `${corSecundaria}`
    }

    const toggleTheme = () => {
        if (theme === "light") {
          localStorage.setItem('theme', 'dark');
          setTheme('dark');
        } else {
          localStorage.setItem('theme', 'light');
          setTheme('light');
        }
        if (chartRef.current) {
          chartRef.current.update();
        }
      };

    const styles = {
        logoLogin,
        bodyLogin,
        loginFormBtn,
        corPrimaria,
        corSecundaria,
        corTerciaria,
        btnSelecionar,
        logoBranca,
        header,
        logoMenu,
        btnVer,
        btnCriar,
        btnPesquisar,
        h3Demanda,
        loadingLayout, 
        setLoadingLayout,
        theme, setTheme,
        chartRef,
        toggleTheme
    }



    useEffect(() => {
        obterLayout()
    }, [])


    return (
        <StyleContext.Provider value={styles}>
            {children}
        </StyleContext.Provider>
    )
}