import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios'
import baseurl from "../services/empresa/baseurl";

export const DashboardContext = createContext()

export const DashboardContextProvider = ({ children }) => {

    const [demandaKey, setDemandaKey] = useState("0")
    const [qtdDemandaXCapacidade, setQtdDemandaXCapacidade] = useState(5)
    const [qtdSistemaXDemanda, setQtdSistemaXDemanda] = useState(5)
    const [qtdCustoXDemanda, setQtdCustoXDemanda] = useState(5)

    const data = {
        demandaKey, setDemandaKey,
        qtdDemandaXCapacidade, setQtdDemandaXCapacidade,
        qtdSistemaXDemanda, setQtdSistemaXDemanda,
        qtdCustoXDemanda, setQtdCustoXDemanda
    }

    return (
        <DashboardContext.Provider value={data}>
            {children}
        </DashboardContext.Provider>
    )
}