import '../../App.css';
import React, { useContext, useState, useRef } from "react";
import { ApiContext } from '../../context/ApiContext';
import { Form } from 'react-bootstrap'
import { MdOutlineContentCopy } from 'react-icons/md'
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalCompartilharDemanda() {

    const { setCompartilharDemandaModal, CompartilharDemanda, Compartilhar, setCompartilhar, UrlCompartilhar, setUrlCompartilhar, KeyCompartilhar, setError } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        if (event.target.value < 0) {
            setError("Não é permitido valor negativo.")
            setTimeout(() => {
                setError("");
            }, 3000);
            event.target.value = 0
            return;
        } else if (event.target.value > 100000) {
            setError("Não é permitido valor acima de 100000.")
            setTimeout(() => {
                setError("");
            }, 3000);
            event.target.value = 0
            return;
        }
        setCompartilhar({ ...Compartilhar, [event.target.name]: event.target.value })
    }

    const cancelar = () => {
        setUrlCompartilhar('')
        setCompartilharDemandaModal(false)
    }

    const [copySuccess, setCopySuccess] = useState('');
    const textAreaRef = useRef(null);

    function copyToClipboard(e) {
        textAreaRef.current.select();
        document.execCommand('copy');
        e.target.focus();
        setCopySuccess('Copiado!');
    };

    return (
        <div className="modal-novo">
            <div className="modal-interno-delete" style={{ width: '500px' }}>
                <div className="modal-content-delete">
                    <div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '20px', padding: '16px' }} className='border-bottom'>
                            <div>
                                Compartilhar demanda
                            </div>
                            <button className='btn-fechar' onClick={() => cancelar()}>
                                <FaTimes />
                            </button>
                        </div>
                        {!UrlCompartilhar &&
                            <div>
                                <div style={{ padding: '16px' }} className='border-bottom'>
                                    <Form.Group className="mb-3 w-50">
                                        <Form.Label>Dias de validade</Form.Label>
                                        <Form.Control type="number" step={1} onChange={handleChange} name="diasValidade" defaultValue={0} />
                                    </Form.Group>
                                </div>
                                <div className="content-buttons">
                                    <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => cancelar()}>Cancelar</button>
                                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => CompartilharDemanda()}>Salvar</button>
                                </div>
                            </div>
                        }
                        {UrlCompartilhar &&
                            <div>
                                <div style={{ display: 'flex', alignItens: 'center', padding: '16px' }} className='border-bottom'>
                                    <Form.Group className="mb-3 w-75">
                                        <Form.Label>Link</Form.Label>
                                        <Form.Control ref={textAreaRef} value={UrlCompartilhar + '/vercompartilhada?q=' + KeyCompartilhar} />
                                    </Form.Group>
                                    <div style={{ margin: '10px 0 0 10px', display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }} className='div-compartilhar'>
                                        <button style={{ marginLeft: '5px' }} className="btn-copy" onClick={copyToClipboard}><MdOutlineContentCopy /></button>
                                        {copySuccess}
                                    </div>
                                </div>
                                <div className="content-buttons">
                                    <button type="submit" className="btn-modal-delete btn-cancelar" onClick={() => cancelar()}>Fechar</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalCompartilharDemanda