import '../../App.css';
import React, { useContext } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Col, Form, Row } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalCriarCiclo() {
    const { CriarCiclo, CicloCadastro, setCicloCadastro, setModalCriarCiclo } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setCicloCadastro({ ...CicloCadastro, [event.target.name]: event.target.value })
    }
    const handleChangeCheckbox = (event) => {
        setCicloCadastro({ ...CicloCadastro, [event.target.name]: event.target.checked })
    }

    const fecharModal = () => {
        setModalCriarCiclo(false)
    }

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-ciclo-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Cadastrar ciclo</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => fecharModal()}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CriarCiclo}>
                    <div className="modal-content-criar-demanda">
                        <Row className='mt-3'>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control maxLength={100} as="textarea" rows={2} onChange={handleChange} name="descricao" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Observação</Form.Label>
                                    <Form.Control maxLength={100} as="textarea" rows={2} onChange={handleChange} name="observacao" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Data inicial</Form.Label>
                                    <Form.Control type="date" defaultValue={new Date("01/01/0001")} onChange={handleChange} name="dataInicial" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Data final</Form.Label>
                                    <Form.Control type="date" defaultValue={new Date("01/01/0001")} onChange={handleChange} name="dataFinal" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            {['checkbox'].map((type) => (
                                <div className="mb-3">
                                    <Form.Check
                                        type="checkbox"
                                        id={`default-${type}`}
                                        label="Não alterar BSC e SWOT"
                                        name="naoAlterarBSCSwot"
                                        onChange={handleChangeCheckbox}
                                    />
                                </div>
                            ))}
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fecharModal()}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCriarCiclo