import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { TbTrash } from 'react-icons/tb';
import { StyleContext } from '../../context/StyleContext';

function ModalCadastrarArea({ closeModal }) {
    const { CadastrarArea, CadastroArea, setCadastroArea, Cargos, ListaCargo, ItensArea, setItensArea, setError, ListaPessoaPorCargo, PessoasPorCargo } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const [cargo, setCargo] = useState()
    const [pessoa, setPessoa] = useState()

    const handleChange = (event) => {
        setCadastroArea({ ...CadastroArea, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        ListaCargo()
    }, [])

    useEffect(() => {
        if (cargo) {
            ListaPessoaPorCargo(JSON.parse(cargo).key)
        }
        setPessoa(0)
    }, [cargo])

    const AdicionarItem = () => {
        if ((!cargo && !pessoa) || (cargo === 0 && pessoa === 0) || (cargo === "0" && pessoa === "0")) {
            setError("Informe um valor.");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        } else if ((cargo && !pessoa) || (cargo && pessoa === 0) || (cargo && pessoa === "0")) {
            setError("Pessoa não informada.");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        } else if ((pessoa && !cargo) || (pessoa && cargo === 0) || (pessoa && cargo === "0")) {
            setError("Cargo não informado.");
            setTimeout(() => {
                setError("");
            }, 3000);
            return;
        }
        let data = {
            pessoa: JSON.parse(pessoa).nome,
            pessoaKey: JSON.parse(pessoa).key,
            cargo: JSON.parse(cargo).descricao,
            cargoKey: JSON.parse(cargo).key
        };

        setItensArea((ItensArea) => [...ItensArea, data]);
        setCargo(0)
        setPessoa(0)
    }

    const ExcluirItem = (pessoaKey, cargoKey) => {
        const novosItens = ItensArea.filter((item) => item.pessoaKey !== pessoaKey || item.cargoKey !== cargoKey);
        setItensArea(novosItens);
    }

    const fechar = () => {
        closeModal(false)
        setItensArea([])
    }

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Cadastrar área</h1>
                    <div className="btn-close-modal" onClick={() => fechar()}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CadastrarArea}>
                    <div className="modal-content">
                        <Row>
                            <Col xl={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control maxLength={100} onChange={handleChange} name="descricao" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center' }}>
                            <Col xl={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Select aria-label="Default select example" value={cargo} onChange={(e) => setCargo(e.target.value)} name="cargoKey">
                                        <option value={0}>Selecione</option>
                                        {Cargos &&
                                            Cargos?.map((item) => {
                                                return (
                                                    <option key={item.id} value={JSON.stringify({ descricao: item.descricao, key: item.chave })}>{item.descricao}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xl={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Pessoa</Form.Label>
                                    <Form.Select aria-label="Default select example" value={pessoa} onChange={(e) => setPessoa(e.target.value)} name="pessoaKey">
                                        <option value={0}>Selecione</option>
                                        {PessoasPorCargo &&
                                            PessoasPorCargo?.map((item) => {
                                                return (
                                                    <option key={item.id} value={JSON.stringify({ nome: item.nome, key: item.chave })}>{item.nome}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xl={2}>
                                <button type="button" className="btn-dash btn-ver mt-3" style={btnVer} onClick={() => AdicionarItem()}>Adicionar</button>
                            </Col>
                        </Row>
                        <Row>
                            <div className="secao-comentario">
                                {
                                    ItensArea?.map((item, index) => {
                                        return (
                                            <div key={index} className="content-comentario">
                                                <div className="div-comentario" style={{ flexDirection: 'column', alignItems: 'start' }}>
                                                    <p className="p-title" style={{ marginLeft: '5px' }}>Cargo</p>
                                                    <span>{item.cargo}</span>
                                                </div>
                                                <div className="div-comentario" style={{ flexDirection: 'column', alignItems: 'start' }}>
                                                    <p className="p-title" style={{ marginLeft: '5px' }}>Pessoa</p>
                                                    <span>{item.pessoa}</span>
                                                </div>
                                                <div className="div-button">
                                                    <button type="button" className="btn-del" onClick={() => ExcluirItem(item.pessoaKey, item.cargoKey)}><TbTrash /></button>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fechar()}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCadastrarArea