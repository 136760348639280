import '../../App.css';
import React, { useContext, useState } from "react";
import { FaTimes } from 'react-icons/fa';
import { Form } from 'react-bootstrap';
import { ApiContext } from '../../context/ApiContext';
import { StyleContext } from '../../context/StyleContext';

function ModalEditarLinhaAtendimento() {

    const { setModalEditar, DemandaAtual, Usuarios, EditarLinha } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [Descricao, setDescricao] = useState(DemandaAtual.descricao)
    const [Status, setStatus] = useState(true)
    const [Usuario, setUsuario] = useState(DemandaAtual.responsavelId)

    const editarItensDemanda = () => {
        EditarLinha(Descricao, Status, Usuario, DemandaAtual.chaveCadastro)
            .then(() => {
                setModalEditar(false)
            })
            .catch((error) => {
            })
    }

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <div>
                        <h1 className="h1-modal">Editar cadastro</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setModalEditar(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content">
                    <form className="mb-3">
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <label className="modal-label">Descrição:</label>
                            <textarea className="modal-input" rows={3} defaultValue={DemandaAtual.descricao} onChange={(e) => setDescricao(e.target.value)} />
                        </div>
                        <Form.Group className="mb-3">
                            <label className="modal-label">Responsável:</label>
                            <Form.Select aria-label="Default select example" defaultValue={DemandaAtual.responsavelId} onChange={(e) => setUsuario(e.target.value)} name="responsavelId">
                                <option value="">Selecione</option>
                                {Usuarios?.map((l) => {
                                        return (
                                            <option key={l.id}
                                                value={l.id}
                                                selected={l.id == DemandaAtual.responsavelId ? true : false}
                                            >
                                                {l.nome}</option>
                                        )
                                    })}
                            </Form.Select>
                        </Form.Group>
                        <div className="checkbox-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <input type="checkbox" label=" Ativo" className="toggleBtn" defaultChecked={DemandaAtual.ativo} onChange={(e) => setStatus(e.target.checked)} />
                            <p className='modal-status'>Ativo</p>
                        </div>
                    </form>
                </div>
                <div className="footer-modal">
                    <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalEditar(false)}>Cancelar</button>
                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => editarItensDemanda()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarLinhaAtendimento