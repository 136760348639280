import '../../App.css';
import React, { useContext, useEffect } from 'react';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import { FaPlus } from 'react-icons/fa'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { TbPencil, TbTrash } from "react-icons/tb";
import { StyleContext } from '../../context/StyleContext';
import ModalCadastrarCargo from '../../components/modal/ModalCadastrarCargo';
import ModalEditarCargo from '../../components/modal/ModalEditarCargo';
import ModalExcluirCargo from '../../components/modal/ModalExcluirCargo';

function Cargo() {
    const { Success, Error, openModal, setOpenModal, Cargos, ListaCargo, loading, modalEditarCargo, setModalEditarCargo, setEdicaoCargo, setCargoAtualKey, modalExcluirCargo, setModalExcluirCargo } = useContext(ApiContext)
    const { h3Demanda, btnCriar, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });

    const EditCargo = (props) => {
        setModalEditarCargo(true)
        setCargoAtualKey(props.chave)
        setEdicaoCargo(props)
    }

    const DeletarCargo = (props) => {
        setCargoAtualKey(props.chave)
        setModalExcluirCargo(true)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-ciclo'>
            <td className="td-ciclo">{props.descricao}</td>
            <td className='td-ciclo'>
                <button onClick={() => EditCargo(props)} className='btn-dem' style={{ color: `${corTerciaria}` }} ><TbPencil /></button>
                <button onClick={() => DeletarCargo(props)} className='btn-dem' style={{ color: `${corTerciaria}` }} ><TbTrash /></button>
            </td>
        </tr>);
    };

    useEffect(() => {
        ListaCargo("")
    }, [])

    return (
        <div>
            <Header pagina={'Cadastros'} subpagina={'Cadastrar cargo'}></Header>
            <MenuLateral></MenuLateral>
            {modalEditarCargo &&
                <ModalEditarCargo />
            }
            {modalExcluirCargo &&
                <ModalExcluirCargo />
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <div className='container-principal'>
                <div className='demanda-header'>
                    {openModal &&
                        <ModalCadastrarCargo closeModal={setOpenModal} />
                    }
                    <button className='btn-dash btn-criar' style={btnCriar} onClick={() => setOpenModal(true)}><FaPlus style={{ marginRight: "10px" }} />Cadastrar cargo</button>
                </div>
                {loading ? (
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : (
                    <div className='card card-table'>
                        <h3 className='h3-demanda' style={h3Demanda}>Cargos cadastrados</h3>
                        <GridComponent locale='pt-BR' id="user" dataSource={Cargos} rowTemplate={gridTemplate} toolbar={toolbarOptions} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                            <ColumnsDirective>
                                <ColumnDirective field='descricao' headerText='Descrição' />
                                <ColumnDirective headerText='Opções' />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Toolbar]} />
                        </GridComponent>
                    </div>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Cargo;