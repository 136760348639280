import React, { useContext, useState } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { ThemeProvider } from 'styled-components';
import { GlobalStyled } from '../../GlobalStyled/GlobalStyled';
import imglogo from '../../images/coamoLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineLogout, AiOutlineLock } from 'react-icons/ai';
import { FaUserCircle } from 'react-icons/fa'
import { ligthTheme, darkTheme } from '../../GlobalStyled/themes'
import Dropdown from 'react-bootstrap/Dropdown';
import ModalAlterarSenha from '../modal/ModalAlterarSenha';
import Toggle from '../botao/BotaoTrocaTema';
import Cookies from 'js-cookie'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { StyleContext } from '../../context/StyleContext';

function Header() {
  const { AlterarSenhaModal, Erro, Success } = useContext(AuthContext)
  const { header, logoBranca } = useContext(StyleContext)
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

  return (
    <>
      {Success &&
        <div className='success'>
          <IoIosCheckmarkCircle className='error-icon' />{Success}
        </div>
      }
      {Erro &&
        <div className='error'>
          <IoIosWarning className='error-icon' />{Erro}
        </div>
      }
      <ThemeProvider theme={theme === "light" ? darkTheme : ligthTheme}>
        <GlobalStyled />
        <div className='header' style={header}>
          {AlterarSenhaModal &&
            <ModalAlterarSenha />
          }
          <Link to="/dashboard">
            <img className='logoMenu' src={`${logoBranca}`} alt="Logo" />
          </Link>
        </div>
      </ThemeProvider>
    </>
  )
}

export default Header