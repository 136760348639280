import '../../App.css';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { Col, Form, Row, Tooltip, OverlayTrigger, Popover } from 'react-bootstrap'
import { FaSearch, FaPlus, FaCalendarAlt } from 'react-icons/fa';
import { TbPencil } from "react-icons/tb";
import { IoIosWarning, IoIosCheckmarkCircle, IoMdSave } from "react-icons/io";
import { SlOptionsVertical } from 'react-icons/sl'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import ModalCriarDemanda from '../../components/modal/ModalCriarDemanda';
import ModalEditarDemanda from '../../components/modal/ModalEditarDemanda';
import ModalCadastrarCiclo from '../../components/modal/ModalCadastrarCiclo';
import ModalExcluirDemandaCiclo from '../../components/modal/ModalExcluirDemandaCiclo'
import ModalAdicionarDemandaCiclo from '../../components/modal/ModalAdicionarDemandaCiclo';
import ModalMostrarCiclo from '../../components/modal/ModalMostrarCiclo';
import { isEmptyObject } from 'jquery';
import { StyleContext } from '../../context/StyleContext';

let DemandasCiclo = []

function PriorizacaoDemandas() {
    const { Setores,
        DiretrizEstrategica,
        ListaSetorGerencia,
        setDiretrizEstrategica,
        Linhas,
        Fases,
        ListaLinhaAtendimento,
        Demandas,
        ListaDemanda,
        EditarDemandaModal,
        setModalEditarDemanda,
        AlterarGutBSC,
        setGut,
        setGutGravidade,
        setGutUrgencia,
        setGutTendencia,
        setBSC,
        setDemandaAtualKey,
        Success,
        Error,
        ListaFases,
        ListaCiclos,
        setgutBSC, modalCriarDemanda, setModalCriarDemanda, FiltroDemanda, setFiltroDemanda, Ciclos, loading, ObterBSC, modalEnviarCiclo,
        setModalEnviarCiclo, setArraySelecionarDemandaCiclo, setLoading, setDemandaCicloAtual, ExcluirDemandaCicloModal, AddDemandaCiclo, setAddDemandaCiclo,
        setDescricao,
        ObterDemanda,
        ListaCicloDemanda
    } = useContext(ApiContext)

    const { btnCriar, btnPesquisar, h3Demanda, btnVer, corTerciaria } = useContext(StyleContext)

    let G = 0
    let U = 0
    let T = 0
    let DiretrizAtual = {}

    const [abremodal, setAbreModal] = useState(false)

    const sortingOptions = {
        columns: [{ field: 'id', direction: 'Descending' }]
    };

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });

    const handleChange = (event) => {
        const { type, checked, name, value } = event.target;
        if (type === 'checkbox') {
            setFiltroDemanda({ ...FiltroDemanda, [name]: checked })
        } else {
            setFiltroDemanda({ ...FiltroDemanda, [name]: value })
        }
        if (event.target.name === "diretrizID") {
            DiretrizAtual = JSON.parse(event.target.value)
        }
        if (event.target.name === "guT_Gravidade") {
            G = event.target.value
        }
        if (event.target.name === "guT_Urgencia") {
            U = event.target.value
        }
        if (event.target.name === "guT_Tendencia") {
            T = event.target.value
        }
    }

    const CriarDemanda = () => {
        setModalCriarDemanda(true)
        setDescricao('')
    }

    const selecionarDem = (props) => {
        ObterDemanda(props.chave)
            .then(() => {
                EditarDemanda(props)
            })
    }

    const EditarDemanda = (props) => {
        setDemandaAtualKey(props.chave)
        setGut(props.guT_Pontuacao)
        setGutGravidade(props.guT_Gravidade)
        setGutUrgencia(props.guT_Urgencia)
        setGutTendencia(props.guT_Tendencia)
        setgutBSC(props.guT_BSC_Pontuacao)
        setBSC(props.pesoBSC)
        setModalEditarDemanda(true)
    }

    const SalvarGUTBSC = (demanda) => {
        if (G == 0 && U == 0 && T == 0 && (isEmptyObject(DiretrizAtual))) {
            return;
        }
        if (G == 0) {
            G = demanda.guT_Gravidade
        }
        if (U == 0) {
            U = demanda.guT_Urgencia
        }
        if (T == 0) {
            T = demanda.guT_Tendencia
        }
        if (isEmptyObject(DiretrizAtual)) {
            DiretrizAtual = { id: demanda.diretrizEstrategicaId, pesoBSC: demanda.pesoBSC }
        }
        AlterarGutBSC(demanda.chave, G, U, T, DiretrizAtual, demanda.id)
    }

    const SelecionarDemandas = (props, key, titulo) => {
        if (props) {
            let obj = {
                id: key,
                content: titulo
            }
            DemandasCiclo.push(obj)
        }
        else {
            DemandasCiclo = DemandasCiclo.filter((obj => obj.id !== key))
        }
    }

    const CriarCiclo = () => {
        setArraySelecionarDemandaCiclo(DemandasCiclo)
        setModalEnviarCiclo(true)
    }

    const Pesquisar = () => {
        setLoading(true)
        ListaDemanda(true, "PRIORIZACAO")
            .then(() => {
                DemandasCiclo.length = 0
                setLoading(false)
            })
            .catch(() => {
                setLoading(false)
            })
    }

    const AdicionarDemandaCiclo = (props) => {
        setAddDemandaCiclo(true)
        setDemandaCicloAtual(props.titulo)
        setDemandaAtualKey(props.chave)
    }

    const VerCiclo = (props) => {
        setDemandaAtualKey(props.chave)
        ListaCicloDemanda(props.chave).then(() => {
            setAbreModal(true)
        })
    }

    function carregando() {
        return new Promise(function (resolve) {
            setLoading(true)
            setTimeout(resolve, 1)
        });
    }

    function naoCarregando() {
        return new Promise(function (resolve) {
            setLoading(resolve, false)
        });
    }

    const chamarLista = async () => {
        await carregando()
        await ListaLinhaAtendimento()
        await ListaSetorGerencia()
        await ListaDemanda(false, "PRIORIZACAO")
        await ListaFases()
        await ListaCiclos()
        setDiretrizEstrategica()
        // await ObterBSC()
        await naoCarregando()
    }

    const popover = (descricao) => {
        return { __html: descricao };
    };

    useEffect(() => {
        setModalCriarDemanda(false)
    }, [])

    useEffect(() => {
        chamarLista()
        setFiltroDemanda({
            somenteAtrasadas: false,
            somenteSemCiclo: false,
            somenteCanceladas: false,
            somenteConcluidas: false
        });
        DemandasCiclo.length = 0
    }, [])

    useEffect(() => {
        if (EditarDemandaModal || modalEnviarCiclo || ExcluirDemandaCicloModal || AddDemandaCiclo || modalCriarDemanda || abremodal) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [EditarDemandaModal, modalEnviarCiclo, ExcluirDemandaCicloModal, AddDemandaCiclo, modalCriarDemanda, abremodal]);

    const gridDiretriz = useCallback((props) => {
        return (
            <tr className='tr-dem' key={props.id}>
                <td className="td-ciclo">
                    <OverlayTrigger
                        placement="right"
                        overlay={<Tooltip id="button-tooltip">
                            Selecionar demandas para criar um novo ciclo
                        </Tooltip>}>
                        <input onChange={(e) => SelecionarDemandas(e.target.checked, props.chave, props.titulo)} type='checkbox'></input>
                    </OverlayTrigger>
                </td>
                <td className="td-dem">{props.id}</td>
                <OverlayTrigger placement="bottom" overlay={<Popover id="popover-basic">
                    <Popover.Header as="h3">Descrição</Popover.Header>
                    <Popover.Body dangerouslySetInnerHTML={popover(props.descricao)}></Popover.Body>
                </Popover>}>
                    <td className="td-dem">{props.titulo}</td>
                </OverlayTrigger>
                <td className="td-dem">{props.setorGerenciaDescricao}</td>
                <td className="td-dem">{props.linhaAtendimentoDescricao}</td>
                <td className='td-dem'>
                    <select className='select-prioridade' style={{ backgroundColor: 'transparent', width: '110px', border: '0px' }} defaultValue={props.guT_Gravidade} aria-label="Default select example" onChange={handleChange} name="guT_Gravidade">
                        <option key="0" value="0" className='option-prioridade'>Selecione</option>
                        <option key="1" value="1" className='option-prioridade'>Sem gravidade</option>
                        <option key="2" value="2" className='option-prioridade'>Pouco grave</option>
                        <option key="3" value="3" className='option-prioridade'>Grave</option>
                        <option key="4" value="4" className='option-prioridade'>Muito grave</option>
                        <option key="5" value="5" className='option-prioridade'>Extremamente grave</option>
                    </select>
                </td>
                <td className='td-dem'>
                    <select className='select-prioridade' style={{ backgroundColor: 'transparent', width: '110px', border: '0px' }} defaultValue={props.guT_Urgencia} aria-label="Default select example" onChange={handleChange} name="guT_Urgencia">
                        <option key={0} value="0" className='option-prioridade'>Selecione</option>
                        <option key={1} value="1" className='option-prioridade'>Longuíssimo prazo</option>
                        <option key={2} value="2" className='option-prioridade'>Longo prazo</option>
                        <option key={3} value="3" className='option-prioridade'>Médio prazo</option>
                        <option key={4} value="4" className='option-prioridade'>Curto</option>
                        <option key={5} value="5" className='option-prioridade'>Imediatamente</option>
                    </select>
                </td>
                <td className='td-dem'>
                    <select className='select-prioridade' style={{ backgroundColor: 'transparent', width: '110px', border: '0px' }} defaultValue={props.guT_Tendencia} aria-label="Default select example" onChange={handleChange} name="guT_Tendencia">
                        <option key={0} value="0" className='option-prioridade'>Selecione</option>
                        <option key={1} value="1" className='option-prioridade'>Desaparece</option>
                        <option key={2} value="2" className='option-prioridade'>Reduz-se ligeiramente</option>
                        <option key={3} value="3" className='option-prioridade'>Permanece</option>
                        <option key={4} value="4" className='option-prioridade'>Aumenta</option>
                        <option key={5} value="5" className='option-prioridade'>Piora muito</option>
                    </select>
                </td>
                <td className='td-dem' >
                    <select className='select-prioridade' style={{ backgroundColor: 'transparent', width: '130px', border: '0px' }} aria-label="Default select example" onChange={handleChange} name="diretrizID">
                        <option key={0} value="0" className='option-prioridade'>Selecione</option>
                        {DiretrizEstrategica?.map((d, index) => {
                            return (
                                <option className='option-prioridade' key={d.id} value={JSON.stringify({ id: d.id, pesoBSC: d.pesoBSC })} selected={d.id == props.diretrizEstrategicaId ? true : false}>{d.descricao}</option>
                            )
                        })}
                    </select>
                </td>
                <td className="td-dem" style={{ paddingLeft: '30px' }}>{props.guT_Pontuacao}</td>
                <td className="td-dem" style={{ paddingLeft: '30px' }}>{props.guT_BSC_Pontuacao.toFixed(2)}</td>
                <td className="td-dem">{props.esforcoTotal ? props.esforcoTotal : '-'}</td>
                <td className="td-dem">{props.cicloDescricao ? props.cicloDescricao : '-'}</td>
                <td style={{ borderBottom: '1px solid #eee' }}>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Ver grupos de demanda
                        </Tooltip>}
                    ><button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => VerCiclo(props)}><SlOptionsVertical /></button>
                    </OverlayTrigger>
                </td>
                <td className="td-dem" style={{ paddingLeft: '30px' }}>{props.cicloOrdem ? props.cicloOrdem : '-'}</td>
                <td className="td-dem" style={{ display: 'flex', justifyContent: 'center' }}>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Adicionar uma demanda a um grupo de demandas
                        </Tooltip>}
                    ><button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => AdicionarDemandaCiclo(props)}><FaPlus /></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Editar demanda
                        </Tooltip>}
                    ><button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => selecionarDem(props)}><TbPencil /></button>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="left"
                        overlay={<Tooltip id="button-tooltip">
                            Salvar alterações GUT/BSC
                        </Tooltip>}
                    ><button className='btn-dem' style={{color: `${corTerciaria}`}} onClick={() => SalvarGUTBSC(props)} ><IoMdSave /></button>
                    </OverlayTrigger>
                </td>
            </tr>
        );
    }, [DiretrizEstrategica, Demandas]);

    return (
        <div>
            {EditarDemandaModal &&
                <ModalEditarDemanda priorizacao={"PRIORIZACAO"} />
            }
            {modalEnviarCiclo &&
                <ModalCadastrarCiclo DemandasCiclo={DemandasCiclo} />
            }
            {ExcluirDemandaCicloModal &&
                <ModalExcluirDemandaCiclo />
            }
            {AddDemandaCiclo &&
                <ModalAdicionarDemandaCiclo DemandasCiclo={DemandasCiclo} />
            }
            {abremodal &&
                <ModalMostrarCiclo setAbreModal={setAbreModal} />
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <Header pagina={'Priorização de Demandas'}></Header>
            <MenuLateral></MenuLateral>
            <div className="container-dem">
                <div className='header-dem'>
                    {modalCriarDemanda &&
                        <ModalCriarDemanda closeModal={setModalCriarDemanda} priorizacao={"PRIORIZACAO"} />
                    }
                    <button className='btn-dash btn-ver' style={btnVer} type="button" onClick={() => CriarCiclo()}><FaPlus style={{ marginRight: "10px" }} />Grupo de demandas</button>
                    <button className='btn-dash btn-criar' style={btnCriar} onClick={() => CriarDemanda()}><FaPlus style={{ marginRight: "10px" }} />Criar demanda</button>
                </div>
                {loading &&
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                <div>
                    <div className='row filtro-demanda'>
                        <div className='col-12'>
                            <Row style={{ display: 'flex', alignItems: 'center' }}>
                                <Form.Group as={Col} xl="2" lg="2">
                                    <Form.Control placeholder='Número' onChange={handleChange} name="numero" />
                                </Form.Group>
                                <Form.Group as={Col} xl="1" lg="1">
                                    <Form.Select aria-label="Default select example" onChange={handleChange} name="faseId">
                                        <option value="0">Fase</option>
                                        {
                                            Fases?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="3">
                                    <Form.Select defaultValue={FiltroDemanda?.linhaAtendimentoId} aria-label="Default select example" onChange={handleChange} name="linhaAtendimentoId">
                                        <option value="0">Linha atendimento</option>
                                        {
                                            Linhas?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.id}>{item.descricao}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="3">
                                    <Form.Select aria-label="Default select example" onChange={handleChange} name="setorGerenciaDemandaId">
                                        <option value="0">Gerência solicitante</option>
                                        {Setores?.map((s) => {
                                            return (
                                                <option key={s.descricao} value={s.id}>{s.descricao}</option>
                                            )

                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="1" lg="1">
                                    <Form.Select aria-label="Default select example" onChange={handleChange} name="cicloId">
                                        <option value="0">Ciclo</option>
                                        {Ciclos?.map((c) => {
                                            return (
                                                <option key={c.id} value={c.id}>{c.descricao} ({c.usuarioNome})</option>
                                            )

                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="2">
                                    <Form.Control type="number" placeholder='GUT/BSC (Inicial)' name="guT_BSC_Pontuacao_Inicial" onChange={handleChange} />
                                </Form.Group>
                                <Form.Group as={Col} xl="2" lg="2">
                                    <Form.Control type="number" placeholder='GUT/BSC (Final)' name="guT_BSC_Pontuacao_Final" onChange={handleChange} />
                                </Form.Group>

                            </Row>
                            <Row className='mt-3'>
                                <Form.Group as={Col} xl="5" lg="5">
                                    <Form.Control placeholder='Título/Descrição' onChange={handleChange} name="descricao" />
                                </Form.Group>
                                <Col style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ display: 'flex' }} className='mt-1'>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check
                                                        inline
                                                        label="Atrasadas"
                                                        type={type}
                                                        id={`inline-${type}-1`}
                                                        onChange={handleChange}
                                                        name="somenteAtrasadas"
                                                        checked={FiltroDemanda.somenteAtrasadas}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={type}>
                                                    <Form.Check
                                                        inline
                                                        label="Sem ciclo"
                                                        type={type}
                                                        id={`inline-${type}-2`}
                                                        onChange={handleChange}
                                                        name="somenteSemCiclo"
                                                        checked={FiltroDemanda.somenteSemCiclo}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check
                                                        inline
                                                        label="Canceladas"
                                                        type={type}
                                                        id={`inline-${type}-3`}
                                                        onChange={handleChange}
                                                        name="somenteCanceladas"
                                                        checked={FiltroDemanda.somenteCanceladas}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                        <Form.Group>
                                            {['checkbox'].map((type) => (
                                                <div key={`inline-${type}`}>
                                                    <Form.Check
                                                        inline
                                                        label="Concluídas"
                                                        type={type}
                                                        id={`inline-${type}-4`}
                                                        onChange={handleChange}
                                                        name="somenteConcluidas"
                                                        checked={FiltroDemanda.somenteConcluidas}
                                                    />
                                                </div>
                                            ))}
                                        </Form.Group>
                                    </div>
                                    <Form.Group>
                                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
                                            <button className='btn-modal btn-pesquisar' style={btnPesquisar} onClick={() => Pesquisar()}><FaSearch style={{ marginRight: "5px" }} /> Pesquisar</button>
                                        </div>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <h3 className='h3-demanda' style={h3Demanda}>Demandas</h3>
                    <GridComponent locale='pt-BR' id="grid" dataSource={Demandas} rowTemplate={gridDiretriz} sortSettings={sortingOptions} pageSettings={{ pageSize: 50, pageCount: 5 }} allowSorting={true} rowHeight={60} allowPaging={true}  >
                        <ColumnsDirective>
                            <ColumnDirective headerText='' width={30} />
                            <ColumnDirective field='id' headerText='Nº' />
                            <ColumnDirective field='titulo' headerText='Título' />
                            <ColumnDirective field='setorGerenciaDescricao' headerText='Gerência' />
                            <ColumnDirective field='linhaAtendimentoDescricao' headerText='Linha' />
                            <ColumnDirective field='guT_Gravidade' headerText='Gravidade' />
                            <ColumnDirective field='guT_Urgencia' headerText='Urgência' />
                            <ColumnDirective field='guT_Tendencia' headerText='Tendência' />
                            <ColumnDirective field='DiretrizEstrategica' headerText='Diretriz estratégica' />
                            <ColumnDirective field='guT_Pontuacao' headerText='GUT' />
                            <ColumnDirective field='guT_BSC_Pontuacao' headerText='GUT/BSC' />
                            <ColumnDirective field='esforcoTotal' headerText='Esforço' />
                            <ColumnDirective field='cicloDescricao' headerText='Grupo de demanda' />
                            <ColumnDirective headerText='' width={30} />
                            <ColumnDirective field='cicloOrdem' headerText='Ordem' />
                            <ColumnDirective headerText='Opções' textAlign='center' width={150} />
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Toolbar]} />
                    </GridComponent>
                </div>
            </div>
            <Footer></Footer>
        </div >
    );
}

export default PriorizacaoDemandas;