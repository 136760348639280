import '../../App.css';
import React, { useContext, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Col, Form, Row } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { TbTrash } from 'react-icons/tb'
import { MdDragIndicator } from "react-icons/md";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { StyleContext } from '../../context/StyleContext';

function ModalCadastrarCiclo({ DemandasCiclo }) {
    const { AdicionarDemandaCiclo, CadastroCiclo, setCadastroCiclo, arraySelecionarDemandaCiclo, setModalEnviarCiclo } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setCadastroCiclo({ ...CadastroCiclo, [event.target.name]: event.target.value })
    }

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const [items, setItems] = useState(arraySelecionarDemandaCiclo);

    const getItemStyle = (isDragging, draggableStyle) => ({
        userSelect: "none",
        padding: `5px 10px`,
        margin: `0 0 8px 0`,
        borderRadius: 5,
        border: `1px solid #ddd`,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        // background: isDragging ? "lightgreen" : "white",

        ...draggableStyle
    });

    const getListStyle = () => ({
        width: '100%',
    });

    const salvarCiclo = () => {
        AdicionarDemandaCiclo(items)
            .then(() => {
                DemandasCiclo.length = 0;
                CadastroCiclo.cicloDescricao = '';
                CadastroCiclo.cicloObservacao = '';
                CadastroCiclo.cicloDataInicial = '';
                CadastroCiclo.cicloDataFinal = '';
            })
    }

    const onDragEnd = result => {
        if (!result.destination) {
            return;
        }

        const updatedItems = reorder(
            items,
            result.source.index,
            result.destination.index
        );

        setItems(updatedItems);
    };

    const removeItem = (itemId) => {
        const updatedItems = items.filter((item) => item.id !== itemId);
        setItems(updatedItems);
    }

    const fecharModal = () => {
        setModalEnviarCiclo(false)
    }

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-ciclo-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Cadastrar grupo de demandas</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => fecharModal()}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content-criar-demanda">
                    <Row className='mt-3'>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Descrição</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={handleChange} name="cicloDescricao" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Observação</Form.Label>
                                <Form.Control as="textarea" rows={2} onChange={handleChange} name="cicloObservacao" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Data inicial</Form.Label>
                                <Form.Control type="date" defaultValue={new Date("01/01/0001")} onChange={handleChange} name="cicloDataInicial" />
                            </Form.Group>
                        </Col>
                        <Col>
                            <Form.Group className="mb-3">
                                <Form.Label>Data final</Form.Label>
                                <Form.Control type="date" defaultValue={new Date("01/01/0001")} onChange={handleChange} name="cicloDataFinal" />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label>Ordenar demandas</Form.Label>
                        <DragDropContext onDragEnd={onDragEnd} style={{ overflow: 'auto' }}>
                            <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        style={getListStyle()}
                                    >
                                        {items?.map((item, index) =>
                                        (
                                            <Draggable key={item.id} draggableId={item.id} index={index} className="list-name">
                                                {(provided, snapshot) => (
                                                    <div
                                                        className='reorder'
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                            snapshot.isDragging,
                                                            provided.draggableProps.style
                                                        )}
                                                    >
                                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                                            <MdDragIndicator style={{ marginRight: '10px', fontSize: '20px' }} />
                                                            {item.content}
                                                        </div>
                                                        <button type="button" className='btn-del' onClick={() => removeItem(item.id)}><TbTrash /></button>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </Row>
                </div>
                <div className="footer-modal-criar-demanda">
                    <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => fecharModal()}>Cancelar</button>
                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => salvarCiclo()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalCadastrarCiclo