import { useContext, useState } from 'react';
import { Form } from 'react-bootstrap'
import { ApiContext } from '../../context/ApiContext';
import { FaStar, FaRegStar } from "react-icons/fa";

export default function Principais({ corFavorito }) {
    const { AdicionarGraficoFavorito, SwotMatriz } = useContext(ApiContext)
    const [swotItem, setSwotItem] = useState("1")
    const retornaSwotItem = (valor) => {
        if (valor === "1") {
            return 'forças'
        } else if (valor === "2") {
            return 'fraquezas'
        } else if (valor === "3") {
            return 'oportunidades'
        } else if (valor === "4") {
            return 'ameaças'
        }
    }
    const retornaSwotLista = (valor) => {
        if (valor === "1") {
            return (
                SwotMatriz && SwotMatriz?.forcas.length > 0 ?
                    SwotMatriz?.forcas.map((item, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }} className='span-bsc'>
                            <div>{item.capacidadeNegocioDemanda.titulo}</div>
                            <div>{item.gut}</div>
                        </div>
                    ))
                    :
                    <p style={{ padding: '8px 16px', margin: '0' }}>Não foram adicionadas forças a esse SWOT.</p>
            )
        } else if (valor === "2") {
            return (
                SwotMatriz && SwotMatriz?.fraquezas.length > 0 ?
                    SwotMatriz?.fraquezas.map((item, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
                            <div>{item.capacidadeNegocioDemanda.titulo}</div>
                            <div>{item.gut}</div>
                        </div>
                    ))
                    :
                    <p style={{ padding: '8px 16px', margin: '0' }}>Não foram adicionadas fraquezas a esse SWOT.</p>
            )
        } else if (valor === "3") {
            return (
                SwotMatriz && SwotMatriz?.oportunidades.length > 0 ?
                    SwotMatriz?.oportunidades.map((item, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
                            <div>{item.capacidadeNegocioDemanda.titulo}</div>
                            <div>{item.gut}</div>
                        </div>
                    ))
                    :
                    <p style={{ padding: '8px 16px', margin: '0' }}>Não foram adicionadas oportunidades a esse SWOT.</p>
            )
        } else if (valor === "4") {
            return (
                SwotMatriz && SwotMatriz?.ameacas.length > 0 ?
                    SwotMatriz?.ameacas.map((item, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
                            <div>{item.capacidadeNegocioDemanda.titulo}</div>
                            <div>{item.gut}</div>
                        </div>
                    ))
                    :
                    <p style={{ padding: '8px 16px', margin: '0' }}>Não foram adicionadas ameaças a esse SWOT.</p>
            )
        }
    }
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', padding: '0' }}>
                <h2 className="h2-bsc">
                    <button onClick={() => AdicionarGraficoFavorito("Principais ameaças")} className='btn-grafico'>{corFavorito("Principais ameaças") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                    <span style={{ fontWeight: '500' }}>Principais {retornaSwotItem(swotItem)}</span>
                </h2>
                <Form.Select style={{ width: '200px', marginRight: '15px' }} value={swotItem} onChange={(e) => setSwotItem(e.target.value)} size="sm">
                    <option value="1">Forças</option>
                    <option value="2">Fraquezas</option>
                    <option value="3">Oportunidades</option>
                    <option value="4">Ameaças</option>
                </Form.Select>
            </div>
            <div style={{ borderRadius: '8px', border: '1px solid #E4E4E7', padding: '0', margin: '20px 15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px', borderBottom: '1px solid #E4E4E7' }}>
                    <span className='span-bsc'>Demanda</span>
                    <span className='span-bsc'>Pontos</span>
                </div>
                {retornaSwotLista(swotItem)}
            </div>
        </>
    )
}