import React from "react"
import MenuLateralAdmin from "./MenuLateralAdmin"
import HeaderAdmin from "./HeaderAdmin"
import Empresas from "./Empresas"

const LayoutAdmin = () => {
    
    return (
        <div>
            <HeaderAdmin />
            <MenuLateralAdmin />
            <Empresas />
        </div>
    )
}

export default LayoutAdmin