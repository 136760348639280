import '../../App.css'
import React from 'react'

function Footer() {

    return (
        <div className='footer'>
            ©<script>
                document.write(new Date().getFullYear());
            </script> 2023 <a href="https://smartdemand.com.br/" target="_blank" className="footer-link fw-bolder"> Smart Demand</a>
        </div>
    )
}

export default Footer