import React, { useContext, useEffect } from "react";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { ApiContext } from "../context/ApiContext";
import Cookies from 'js-cookie'
import LayoutAdmin from "../pages/Admin/LayoutAdmin";
import Layouts from "../pages/Admin/Layouts";
import NotFound from "../pages/NotFound/NotFound";

const RotasAdmin = () => {


    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<LayoutAdmin />} />
                <Route path="/admin" element={<LayoutAdmin />} />
                <Route path="/layoutscadastrados" element={<Layouts />} />
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    )
}

export default RotasAdmin;