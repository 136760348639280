export const ligthTheme = {
  h2: "#52525B",
  h3: "#388933",
  bgCard: "#ffffff",
  borderCard: "#E4E4E7",
  icone: "#71717A",
  body: "#f8f8f8",
  header: "#0F6631",
  dashGrafico: "#fff",
  dashGraficoCancel: "rgb(215 54 54 / 40%)",
  borderCardGrafico: "rgba(18, 243, 2, 0.705) 0px 1px 1px 0px, rgba(0, 250, 12, 0.404) 0px 0px 0px 1px",
  borderGrafico: "#E4E4E7",
  status: "#F4F4F5",
  red: "#eb000031",
  green: "#00af0f31",
  blue: "#7076eb31",
  redtext: "#e40000",
  greentext: "#00af0f",
  bluetext: "#7076eb",
  text: "#52525B",
  textDescricao: "#0F6631",
  menuHover: '#eee',
  filtroDemanda: '#fff',
  btnHover: '#e4e4e4',
  textBlack: "#332E33",
  headerCards: 'rgba(90deg, rgba(15, 102, 49, 1) 0%, rgba(15, 102, 49, 1) 35%, rgba(22, 163, 77, 1) 100%)',
  headerModal: 'linear-gradient(90deg, rgba(15,102,49,1) 0%, rgba(15,102,49,1) 35%, rgba(22,163,77,1) 100%)',
  labelCard: '#fff',
  borderInput: "#ccc",
  containerGut: "#eee",
  textBtnVer: "#0F6631",
  borderBtnVer: "#0F6631",
  bgAtivo: '#00af0f31',
  txtAtivo: '#00af0f',
  bgInativo: '#eb000031',
  txtInativo: '#e40000',
  tableRow: '#bbdcba57',
  lightColor: '#aedabf',
  ordem: 'rgb(174, 218, 191)',
  boxShadow: 'rgba(17, 243, 2, 0.31) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px',
};

export const darkTheme = {
  h2: "#fff",
  h3: "#8AB54E",
  bgCard: "#3f3f46",
  borderCard: "#52525B",
  icone: "#ffffff",
  body: "#27272A",
  header: "#0F6631",
  dashGrafico: "#52525B",
  dashGraficoCancel: "rgb(215 54 54 / 75%)",
  borderCardGrafico: "rgba(72, 75, 112, 0.705) 0px 1px 1px 0px, rgba(72, 75, 112, 0.404) 0px 0px 0px 1px",
  borderGrafico: "#52525B",
  status: "#52525B",
  red: "#eb0000e5",
  green: "#00af0fdc",
  blue: "#7076eb",
  redtext: "#fbfbfc",
  greentext: "#fbfbfc",
  bluetext: "#fbfbfc",
  text: "#ffffff",
  textDescricao: "#fbfbfc",
  menuHover: '#1e1e2f',
  filtroDemanda: '#3F3F46',
  btnHover: "black",
  textBlack: "#fbfbfc",
  headerCards: 'rgba(90deg, rgba(10,18,0) 0%, rgba(42,52,30) 35%, rgba(15,102,49) 100%)',
  headerModal: 'linear-gradient(90deg, rgba(39,41,61,1) 0%, rgba(51,53,79,1) 35%, rgba(65,68,102,1) 100%)',
  labelCard: '#27293d',
  borderInput: "#fbfbfc",
  containerGut: "rgb(75 90 135)",
  textBtnVer: "#f0f0f0",
  borderBtnVer: "#FFFFFF",
  bgAtivo: 'rgb(0 175 15 / 60%)',
  txtAtivo: 'rgb(180 229 184)',
  bgInativo: 'rgb(235 0 0 / 60%)',
  txtInativo: 'rgb(221 173 173)',
  tableRow: '#666D7857',
  lightColor: '#6F85C7',
  ordem: 'rgb(75 90 135)',
  boxShadow: 'rgb(0 0 0) 0px 7px 29px 0px', 
};
