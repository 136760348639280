import React from "react";
import { RiMoonLine } from "react-icons/ri";

const Toggle = ({ toggleTheme }) => {
  return (
    <button style={{ backgroundColor: "transparent", border: "none", display: "flex", alignItems: "center", color: "#fff" }} onClick={toggleTheme} className='icon-options-tema'>
      <RiMoonLine />
    </button>
  );
};

export default Toggle;