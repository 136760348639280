import { useContext } from 'react';
import { ApiContext } from '../../context/ApiContext';
import { FaStar, FaRegStar } from "react-icons/fa";
import { ProgressBar } from 'react-bootstrap'

export default function AnaliseGeral({ corFavorito }) {
    const { AdicionarGraficoFavorito, SwotRelatorio } = useContext(ApiContext)
    let soma = SwotRelatorio?.quantidadeForca + SwotRelatorio?.quantidadeFraqueza + SwotRelatorio?.quantidadeOportunidade + SwotRelatorio?.quantidadeAmeaca
    return (
        <>
            <h2 className="h2-bsc">
                <button onClick={() => AdicionarGraficoFavorito("Análise Geral")} className='btn-grafico'>{corFavorito("Análise Geral") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                <span style={{ fontWeight: '500' }}>Análise Geral</span>
            </h2>
            {SwotRelatorio &&
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '25px' }}>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <span className='span-bsc' style={{ fontWeight: 600 }}>Forças</span>
                            <span className='span-bsc'>{SwotRelatorio?.porcentagemForca.toFixed(2)}% ({SwotRelatorio?.quantidadeForca})</span>
                        </div>
                        <ProgressBar min={0} max={soma} id='progress-forca' now={SwotRelatorio?.quantidadeForca} style={{ width: '100%' }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <span className='span-bsc' style={{ fontWeight: 600 }}>Fraquezas</span>
                            <span className='span-bsc'>{SwotRelatorio?.porcentagemFraqueza.toFixed(2)}% ({SwotRelatorio.quantidadeFraqueza})</span>
                        </div>
                        <ProgressBar min={0} max={soma} id='progress-fraqueza' now={SwotRelatorio?.quantidadeFraqueza} style={{ width: '100%' }} />
                    </div>
                    <div style={{ width: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <span className='span-bsc' style={{ fontWeight: 600 }}>Oportunidades</span>
                            <span className='span-bsc'>{SwotRelatorio?.porcentagemOportunidade.toFixed(2)}% ({SwotRelatorio.quantidadeOportunidade})</span>
                        </div>
                        <ProgressBar min={0} max={soma} id='progress-oportunidade' now={SwotRelatorio?.quantidadeOportunidade} style={{ width: '100%' }} />
                    </div>
                    <div style={{ width: '100%', height: '100%' }}>
                        <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                            <span className='span-bsc' style={{ fontWeight: 600 }}>Ameaças</span>
                            <span className='span-bsc'>{SwotRelatorio?.porcentagemAmeaca.toFixed(2)}% ({SwotRelatorio?.quantidadeAmeaca})</span>
                        </div>
                        <ProgressBar min={0} max={soma} id='progress-ameaca' now={SwotRelatorio?.quantidadeAmeaca} style={{ width: '100%' }} />
                    </div>
                </div>
            }
        </>
    )
}