import React, { useContext, useState, useEffect } from "react";
import { ApiContext } from '../../context/ApiContext';
import { DashboardContext } from '../../context/DashboardContext';
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, Tooltip, ColumnSeries, DataLabel, Highlight } from '@syncfusion/ej2-react-charts';
import { InputLabel, MenuItem, FormControl, Select, Skeleton } from '@mui/material'
import { FaStar, FaRegStar } from "react-icons/fa";

export default function CustoXDemandas({ corFavorito, cicloDemandaKey, tab }) {
    const theme = localStorage.getItem('theme') || 'light'
    const { AdicionarGraficoFavorito, getCustoXDemanda, CustoXDemanda } = useContext(ApiContext)
    const { qtdCustoXDemanda, setQtdCustoXDemanda } = useContext(DashboardContext)
    const [isLoading, setIsLoading] = useState(false)

    const axisLabelRender = (args) => {
        theme == 'light' ? args.labelStyle.color = '#fff' : args.labelStyle.color = '#3f3f46'

    };

    const primaryxAxis = { title: '', titleStyle: { color: theme == 'light' ? '#fff' : '#3f3f46' }, labelIntersectAction: 'None', labelRotation: -45, valueType: 'Category', interval: 1, majorGridLines: { width: 0 }, majorTickLines: { width: 0 } };
    const primaryyAxis = { title: 'Custo', titleStyle: { color: theme == 'light' ? '#fff' : '#3f3f46' }, majorTickLines: { width: 0 }, lineStyle: { width: 0 } }

    useEffect(() => {
        if (cicloDemandaKey != "") {
            setIsLoading(true)
            getCustoXDemanda(cicloDemandaKey, qtdCustoXDemanda)
                .then(() => {
                    setIsLoading(false)
                })
        }
    }, [cicloDemandaKey, qtdCustoXDemanda])
    return (
        <>
            <div style={{ display: 'flex', alignItems: 'end', justifyContent: 'space-between', padding: '0' }}>
                <h2 className="h2-bsc">
                    <button onClick={() => AdicionarGraficoFavorito("Custo x Demandas")} className='btn-grafico'>{corFavorito("Custo x Demandas") ? <FaRegStar /> : <FaStar style={{ color: 'orange' }} />}</button>
                    <span style={{ fontWeight: '500' }}>Custo x Demandas</span>
                </h2>
                <FormControl size="small" sx={{ m: 1, minWidth: 200 }}>
                    <InputLabel id="qtd-demanda-2">Quantidade de demandas</InputLabel>
                    <Select
                        className='span-bsc'
                        labelId="qtd-demanda-label-2"
                        id="qtd-demanda-2"
                        value={qtdCustoXDemanda}
                        label="Quantidade de demandas"
                        onChange={(e) => setQtdCustoXDemanda(parseInt(e.target.value))}
                    >
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="15">15</MenuItem>
                        <MenuItem value="20">20</MenuItem>
                        <MenuItem value="30">30</MenuItem>
                        <MenuItem value="40">40</MenuItem>
                        <MenuItem value="1000">Todas</MenuItem>
                    </Select>
                </FormControl>
            </div>

            <div style={{ width: '100%', minHeight: '550px' }}>
                {isLoading ?
                    <Skeleton variant="rounded" width="100%" height={550} />
                    :
                    <ChartComponent id={`${tab}-custoxdemanda`} style={{ textAlign: "center" }} legendSettings={{ enableHighlight: true }}
                        primaryXAxis={primaryxAxis} primaryYAxis={primaryyAxis} axisLabelRender={axisLabelRender} chartArea={{ border: { width: 0 } }} tooltip={{ enable: true, header: "<b>${point.tooltip}</b>", shared: true }} width={'100%'} height={'100%'} title=''>
                        <Inject services={[ColumnSeries, Legend, Tooltip, Category, DataLabel, Highlight]} />
                        <SeriesCollectionDirective >
                            <SeriesDirective dataSource={CustoXDemanda} tooltipMappingName='toolTipMappingName' xName='x' columnSpacing={0.01} yName='y' type='Column' />

                        </SeriesCollectionDirective>
                    </ChartComponent>
                }
            </div>
        </>
    )
}