import '../../App.css'
import React, { useContext } from "react";
import { AiOutlineAppstore } from 'react-icons/ai';
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";

import { IoLogOutOutline } from "react-icons/io5";
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { slide as Slider } from 'react-burger-menu';
import { Link, useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';
import { AdminContext } from '../../context/AdminContext';

function MenuLateralAdmin() {

    const { logoMenu } = useContext(StyleContext)

    const { setAuthAdmin } = useContext(AdminContext)

    const navigate = useNavigate()

    const sair = () => {
        Cookies.remove("TokenAdmin")
        Cookies.remove("Key")
        setAuthAdmin(false);
        navigate('/');
    }

    return (
        <Sidebar>
            <Slider>
                <img src={`${logoMenu}`} alt='Logo' width={90} style={{ margin: '0 0 30px 30px' }} />
                <Menu>
                    <MenuItem icon={<HiOutlineBuildingOffice2 />} component={<Link to="/" />}> Empresas </MenuItem>
                    <MenuItem icon={<AiOutlineAppstore />} component={<Link to="/layoutscadastrados" />}> Layouts </MenuItem>
                    <MenuItem onClick={() => sair()} icon={<IoLogOutOutline />}> Sair </MenuItem>
                </Menu>
            </Slider>
        </Sidebar>
    )
}

export default MenuLateralAdmin