import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { TbTrash } from 'react-icons/tb';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function ModalEditarArea() {
    const { EditarArea, EdicaoArea, setEdicaoArea, setModalEditarArea, Cargos, ListaCargo, ListaPessoaPorCargo, PessoasPorCargo, AreaItens, ListaItensArea, ExcluirItensArea, AdicionarItensArea } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [cargoKey, setCargoKey] = useState("")
    const [pessoaKey, setPessoaKey] = useState("")

    const handleChange = (event) => {
        setEdicaoArea({ ...EdicaoArea, [event.target.name]: event.target.value })
    }

    const Adicionar = (pessoaKey, cargoKey) => {
        AdicionarItensArea(pessoaKey, cargoKey)
            .then(() => {
                setCargoKey(0)
                setPessoaKey(0)
            })
    }

    useEffect(() => {
        ListaCargo()
        ListaItensArea()
    }, [])

    useEffect(() => {
        if (cargoKey) {
            ListaPessoaPorCargo(cargoKey)
        }
        setPessoaKey("")
    }, [cargoKey])

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Editar área</h1>
                    <div className="btn-close-modal" onClick={() => setModalEditarArea(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div>
                    <div className="modal-content">
                        <Row>
                            <Col xs={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Descrição</Form.Label>
                                    <Form.Control maxLength={100} defaultValue={EdicaoArea.descricao} onChange={handleChange} name="descricao" />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ alignItems: 'center' }}>
                            <Col xl={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Select aria-label="Default select example" value={cargoKey} onChange={(e) => setCargoKey(e.target.value)} name="cargoKey">
                                        <option value="0">Selecione</option>
                                        {Cargos &&
                                            Cargos?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.chave}>{item.descricao}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xl={5}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Pessoa</Form.Label>
                                    <Form.Select aria-label="Default select example" value={pessoaKey} onChange={(e) => setPessoaKey(e.target.value)} name="pessoaKey">
                                        <option value="0">Selecione</option>
                                        {PessoasPorCargo &&
                                            PessoasPorCargo?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.chave}>{item.nome}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                            <Col xl={2}>
                                <button type="button" className="btn-dash btn-ver mt-3" style={btnVer} onClick={() => Adicionar(pessoaKey, cargoKey)}>Adicionar</button>
                            </Col>
                        </Row>
                        <Row>
                            <div className="secao-comentario">
                                {AreaItens &&
                                    AreaItens?.map((item, index) => {
                                        return (
                                            <div key={index} className="content-comentario">
                                                <div className="div-comentario" style={{ flexDirection: 'column', alignItems: 'start' }}>
                                                    <p className="p-title" style={{ marginLeft: '5px' }}>Cargo</p>
                                                    <span>{item.cargo.descricao}</span>
                                                </div>
                                                <div className="div-comentario" style={{ flexDirection: 'column', alignItems: 'start' }}>
                                                    <p className="p-title" style={{ marginLeft: '5px' }}>Pessoa</p>
                                                    <span>{item.pessoa.nome}</span>
                                                </div>
                                                <div className="div-button">
                                                    <button type="button" className="btn-del" onClick={() => ExcluirItensArea(item.chave)}><TbTrash /></button>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalEditarArea(false)}>Cancelar</button>
                        <button type='button' className="btn-dash btn-criar" style={btnCriar} onClick={() => EditarArea()}>Salvar</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarArea