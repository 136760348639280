import '../../App.css';
import React, { useContext, useState } from "react";
import { FaTimes } from 'react-icons/fa';
import { ApiContext } from '../../context/ApiContext';
import { StyleContext } from '../../context/StyleContext';

function ModalEditarCadastro({ end }) {

    const { setModalEditar, DemandaAtual, EditarItensDemanda } = useContext(ApiContext)
    const [Descricao, setDescricao] = useState(DemandaAtual.descricao)
    const [Status, setStatus] = useState(true)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const editarItensDemanda = () => {
        EditarItensDemanda(end, Descricao, Status, DemandaAtual.chaveCadastro)
            .then(() => {
                setModalEditar(false)
            })
            .catch((error) => {
                console(error)
            })
    }

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <div>
                        <h1 className="h1-modal">Editar cadastro</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setModalEditar(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content">
                    <form className="mb-3">
                        <div style={{ display: "flex", flexDirection: "column", overflow: 'scroll' }}>
                            <label className="modal-label">Descrição:</label>
                            <textarea className="modal-input" rows={3} defaultValue={DemandaAtual.descricao} onChange={(e) => setDescricao(e.target.value)} />
                        </div>
                        <div className="checkbox-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <input type="checkbox" label=" Ativo" className="toggleBtn" defaultChecked={DemandaAtual.ativo} onChange={(e) => setStatus(e.target.checked)} />
                            <p className='modal-status'>Ativo</p>
                        </div>
                    </form>
                </div>
                <div className="footer-modal">
                    <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => setModalEditar(false)}>Cancelar</button>
                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => editarItensDemanda()}>Salvar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalEditarCadastro