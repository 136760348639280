import '../../App.css';
import React, { useContext, useEffect, useState } from "react";
import { ApiContext } from "../../context/ApiContext";
import { StyleContext } from '../../context/StyleContext';
import { TreeViewComponent } from '@syncfusion/ej2-react-navigations';
import { FaTimes, FaPlus } from 'react-icons/fa';
import ModalCadastrarCapacidade from './ModalCadastrarCapacidade';

function ModalArvoreCapacidade({ setAbreModal }) {
    const { ListaArvoreCapacidades, ArvoreCapacidades, loading } = useContext(ApiContext)
    const { btnCriar } = useContext(StyleContext)
    const [keyArvore, setKeyArvore] = useState('')
    const [descricaoArvore, setDescricaoArvore] = useState('')
    const [openModal, setopenModal] = useState(false)

    useEffect(() => {
        ListaArvoreCapacidades()
    }, [])

    const processarDataSource = (data) => {
        if (!Array.isArray(data)) {
            return [];
        }
    
        return data.map(item => ({
            ...item,
            titulo: item.nivel ? `${item.nivel} - ${item.titulo}` : item.titulo,
            filhos: item.filhos ? processarDataSource(item.filhos) : undefined,
        }));
    };
    
    const dataSourceTransformado = processarDataSource(ArvoreCapacidades);

    const fields = { 
        dataSource: dataSourceTransformado, 
        id: 'key', 
        text: 'titulo', 
        child: 'filhos' 
    };

    function nodeSelected(e) {
        setKeyArvore(e.nodeData.id)
        setDescricaoArvore(e.nodeData.text)
    }

    return (
        <div className="modal-criar-demanda">
            {openModal &&
                <ModalCadastrarCapacidade closeModal={setopenModal} keyArvore={keyArvore} descricaoArvore={descricaoArvore} setKeyArvore={setKeyArvore} />
            }
            <div className="modal-criar-ciclo-interno">
                {loading &&
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                }
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Árvore Capacidades de Negócio da Demanda</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setAbreModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <div className="modal-content-criar-demanda">
                    <div style={{ position: 'fixed', width: '58%', display: 'flex', justifyContent: 'end', zIndex: 2 }} className='container-btn-arvore'>
                        <button className='btn-dash btn-criar' style={btnCriar} onClick={setopenModal}><FaPlus style={{ marginRight: "10px" }} />Novo</button>
                    </div>
                    <TreeViewComponent id="treeview" fields={fields} sortOrder='Ascending' nodeSelected={nodeSelected} style={{marginTop: '40px', color: '#fff'}}/>
                </div>
            </div>
        </div>
    )
}

export default ModalArvoreCapacidade