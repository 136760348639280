import './App.css';
import React, { useContext } from 'react';
import PublicRotas from './routes/public-routes';
import Layout from './Layout';
import { AuthContext } from './context/AuthContext';
import { AdminContext } from './context/AdminContext';
import RotasAdmin from './routes/routes-admin';

function App() {

  const { Auth } = useContext(AuthContext)
  const { AuthAdmin } = useContext(AdminContext)

  return Auth ? <Layout /> : AuthAdmin ? <RotasAdmin /> : <PublicRotas />

}

export default App;