import '../../App.css';
import React, { useContext, useState } from "react";
import { Form, InputGroup } from 'react-bootstrap';
import { AuthContext } from '../../context/AuthContext';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';


function ModalAlterarSenha() {

    const { setAlterarSenhaModal, TrocarSenha } = useContext(AuthContext)
    const { btnVer, btnCriar } = useContext(StyleContext)
    const [passwordShown, setPasswordShown] = useState(false);
    const [novaSenha, setnovaSenha] = useState('');

    let senha = document.getElementById('senha');
    let senhaC = document.getElementById('senhaC');

    function validarSenha() {
        if (senha.value != senhaC.value) {
            senhaC.setCustomValidity("Senhas diferentes!");
            senhaC.reportValidity();
            return false;
        } else {
            senhaC.setCustomValidity("");
            return true;
        }
    }

    const handleChange = (event) => {
        setnovaSenha(event.target.value)
    }

    const togglePassword = () => {
        setPasswordShown(!passwordShown);
    };

    return (
        <div className="modal-novo">
            <div className="modal-interno-delete">
                <div className="header-modal-criar-demanda">
                    <h1 className="h1-modal">Alterar senha</h1>
                    <button className='btn-fechar' style={{ marginRight: '16px' }} onClick={() => setAlterarSenhaModal(false)}>
                        <FaTimes />
                    </button>
                </div>
                <div style={{ padding: '16px' }} className='border-bottom'>
                    <h2>Insira sua nova senha:</h2>
                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <Form.Control type={passwordShown ? "text" : "password"} onChange={handleChange} id="senha" />
                            <InputGroup.Text className="eye-password" id="basic-addon2" onClick={togglePassword}><span>{passwordShown ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</span></InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                    <h2>Confirmar senha:</h2>
                    <Form.Group className="mb-3">
                        <InputGroup className="mb-3">
                            <Form.Control type={passwordShown ? "text" : "password"} onChange={handleChange} id="senhaC" onKeyUp={() => validarSenha()} />
                            <InputGroup.Text className="eye-password" id="basic-addon2" onClick={togglePassword}><span>{passwordShown ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}</span></InputGroup.Text>
                        </InputGroup>
                    </Form.Group>
                </div>
                <div className="content-buttons">
                    <button type="submit" className="btn-dash btn-ver" style={btnVer} onClick={() => setAlterarSenhaModal(false)}>Cancelar</button>
                    <button type="submit" className="btn-dash btn-criar" style={btnCriar} onClick={() => TrocarSenha(novaSenha)}>Alterar</button>
                </div>
            </div>
        </div>
    )
}

export default ModalAlterarSenha