import '../../App.css';
import React, { useContext, useEffect } from 'react';
import Select from 'react-select'
import { ApiContext } from '../../context/ApiContext';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie'
import { StyleContext } from '../../context/StyleContext';

function SelectEmpresa() {

    const { corPrimaria, btnSelecionar, logoBranca } = useContext(StyleContext)
    const { ListaEmpresas, Empresas, setKey } = useContext(ApiContext)

    const navigate = useNavigate()

    const navegar = () => {
        navigate('/dashboard');
    }

    const handleSelect = ({ value, key }) => {
        Cookies.set('Empresa', value)
        Cookies.set("Key", key)
        setKey(key)
    }

    useEffect(() => {
        ListaEmpresas()
    }, [])


    return (
        <div className="AppEmpresa" style={{ backgroundColor: corPrimaria }}>
            <div className='logoEmpresa'>
                <img src={`${logoBranca}`} alt="logo" />
            </div>
            <Select options={Empresas} onChange={handleSelect} className="selectEmpresa" />
            <button style={btnSelecionar} onClick={() => navegar()}>selecionar</button>
        </div>
    );
}

export default SelectEmpresa;