import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";
import NotFound from "../pages/NotFound/NotFound";
import Login from "../pages/Login/Login";
import Compartilhar from "../pages/Compartilhar/Compartilhar";
import LoginAdmin from "../pages/Login/LoginAdmin";

const PublicRotas = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="*" element={<NotFound />} />
                <Route path="/" element={<Login />} />
                <Route path="/vercompartilhada" element={<Compartilhar />}/>
                <Route path="/admin" element={<LoginAdmin />}/>
            </Routes>
        </BrowserRouter>
    )
}

export default PublicRotas;