import '../../App.css';
import React, { useContext, useEffect, useState } from 'react';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { StyleContext } from '../../context/StyleContext';
import { FaPlus } from 'react-icons/fa'
import { TbTrash, TbPencil } from 'react-icons/tb'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import ModalCadastrarPlano from '../../components/modal/ModalCadastrarPlano';
import ModalExcluirPlano from '../../components/modal/ModalExcluirPlano';
import ModalEditarPlano from '../../components/modal/ModalEditarPlano';

function PlanoNegocios() {
    const { Success, Error, loading, openModal, setOpenModal, ListaTipoPlanoNegocio, ListaPlanoNegocio, PlanoNegocios, setPlanoNegocioAtualKey, modalExcluirPlanoNegocio, setModalExcluirPlanoNegocio, modalEditarPlanoNegocio, setModalEditarPlanoNegocio, setEdicaoPlanoNegocio, StatusPlanoNegocio, ListaPlanoNegocioAvaliacao } = useContext(ApiContext)
    const { h3Demanda, btnCriar, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });

    const EditarPlano = (props) => {
        setModalEditarPlanoNegocio(true)
        setPlanoNegocioAtualKey(props.chave)
        setEdicaoPlanoNegocio(props)
    }

    const DeletarPlano = (props) => {
        setPlanoNegocioAtualKey(props.chave)
        setModalExcluirPlanoNegocio(true)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-ciclo'>
            <td className="td-ciclo">{props.avaliacaoPlanoNegocio ? props.avaliacaoPlanoNegocio.descricao : "-"}</td>
            <td className="td-ciclo">{props.indicador ? props.indicador : "-"}</td>
            <td className="td-ciclo">{props.tipo ? props.tipo : "-"}</td>
            <td className="td-ciclo">{props.tipo === 'DATA'
                ? (props.valor
                    ? new Date(props.valor).toLocaleDateString('pt-BR', { timeZone: 'UTC' })
                    : "-")
                : props.valor}</td>
            <td className="td-ciclo">
                {(props.ativo) ? (
                    <div className="item-ativo">⬤</div>
                ) : (
                    <div className="item-inativo">⬤</div>
                )}
            </td>
            <td className='td-ciclo'>
                <div className="checkbox-wrapper" style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <input type="checkbox" label=" Ativo" className="toggleBtn" defaultChecked={props.ativo} onClick={() => StatusPlanoNegocio(props.chave, !props.ativo)} />
                </div>
            </td>
            <td className='td-ciclo'>
                <button onClick={() => EditarPlano(props)} className='btn-dem' style={{ color: `${corTerciaria}` }} ><TbPencil /></button>
                <button onClick={() => DeletarPlano(props)} className='btn-dem' style={{ color: `${corTerciaria}` }} ><TbTrash /></button>
            </td>
        </tr>);
    };

    useEffect(() => {
        ListaPlanoNegocio()
        ListaTipoPlanoNegocio()
        ListaPlanoNegocioAvaliacao()
    }, [])

    return (
        <div>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            {modalEditarPlanoNegocio &&
                <ModalEditarPlano />
            }
            {modalExcluirPlanoNegocio &&
                <ModalExcluirPlano />
            }
            <Header pagina={'Cadastros'} subpagina={'Plano de Negócios'}></Header>
            <MenuLateral></MenuLateral>
            <div className='container-principal'>
                <div className='demanda-header'>
                    {openModal &&
                        <ModalCadastrarPlano closeModal={setOpenModal} />
                    }
                    <button className='btn-dash btn-criar' style={btnCriar} onClick={() => setOpenModal(true)}><FaPlus style={{ marginRight: "10px" }} />Cadastrar Plano de Negócios</button>
                </div>
                {loading ? (
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : (
                    <div className='card card-table'>
                        <h3 className='h3-demanda' style={h3Demanda}>Plano de Negócios</h3>
                        <GridComponent locale='pt-BR' id="user" dataSource={PlanoNegocios} rowTemplate={gridTemplate} toolbar={toolbarOptions} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                            <ColumnsDirective>
                                <ColumnDirective field='avaliacao' headerText='Avaliação' />
                                <ColumnDirective field='indicador' headerText='Indicador' />
                                <ColumnDirective field='tipo' headerText='Tipo' />
                                <ColumnDirective field='valor' headerText='Valor' />
                                <ColumnDirective field='ativo' headerText='Status' />
                                <ColumnDirective headerText='Ativo' />
                                <ColumnDirective headerText='Opções' />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Toolbar]} />
                        </GridComponent>
                    </div>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
}

export default PlanoNegocios;