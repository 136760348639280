import '../../../App.css'
import React, { useContext } from "react";
import { Tab, Tabs, Form, InputGroup, Row, Col, OverlayTrigger, Popover } from 'react-bootstrap';
import { FaTimes, FaRegQuestionCircle } from 'react-icons/fa';
import InputMask from 'react-input-mask';
import { AdminContext } from '../../../context/AdminContext';
import { StyleContext } from '../../../context/StyleContext';

function ModalCriarLayout({ closeModal }) {
    const { CadastrarLayout, DadosLayout, setDadosLayout } = useContext(AdminContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setDadosLayout({ ...DadosLayout, [event.target.name]: event.target.value })
    }

    const handleFile = async (event) => {
        const file = event.target.files[0]
        const file64 = await convertBase64(file)
        setDadosLayout({ ...DadosLayout, [event.target.name]: file64 })
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    return (
        <div className="modal-criar-demanda">
            <div className="modal-criar-demanda-interno">
                <div className="header-modal-criar-demanda">
                    <div>
                        <h1 className="h1-modal">Cadastrar layout</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => closeModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CadastrarLayout}>
                    <div className="modal-content-criar-demanda">
                        <Row style={{ paddingTop: 20 }}>

                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Logo do login</Form.Label>
                                        <Form.Control type="file" name="logoLogin" onChange={handleFile} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Logo branca</Form.Label>
                                        <Form.Control type="file" onChange={handleFile} name="logoNavbar" />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Logo menu</Form.Label>
                                        <Form.Control type="file" onChange={handleFile} name="logoMenu" />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Logo background</Form.Label>
                                        <Form.Control type="file" onChange={handleFile} name="logoBackground" />
                                    </Form.Group>
                                </Col>

                            </Row>
                            <Row>
                                <Col >
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cor primaria</Form.Label>
                                        <Form.Control type="color" onBlur={handleChange} name="corPrimaria" />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cor secundária</Form.Label>
                                        <Form.Control type="color" onBlur={handleChange} name="corSecundaria" />
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group className="mb-3">
                                        <Form.Label>Cor terciária</Form.Label>
                                        <Form.Control type="color" name="corTerciaria" onBlur={handleChange} />
                                    </Form.Group>
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>
                                            Url
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Popover id="popover-basic">
                                                        <Popover.Body>
                                                            Insira o hostname da url, exemplo: "smartdemand.gestaodemanda.com"
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <span>
                                                    <FaRegQuestionCircle style={{ marginBottom: 5, marginLeft: 5 }} />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Form.Control onChange={handleChange} name="url" />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => closeModal(false)}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCriarLayout