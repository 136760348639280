import '../Login/styled.css'
import React, { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import ModalResetarSenha from '../../components/modal/ModalResetarSenha';
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { ApiContext } from '../../context/ApiContext';
import { StyleContext } from '../../context/StyleContext';
import { AdminContext } from '../../context/AdminContext';

function LoginAdmin() {
    const { email, setEmail, password, setPassword, AuthUser, loading, Erro, Success, AuthUserAdmin } = useContext(AdminContext)
    const { Error } = useContext(ApiContext)
    const { logoLogin, bodyLogin, loginFormBtn, loadingLayout } = useContext(StyleContext)

    return (

        <div className="body-login" style={bodyLogin}>
            {Erro &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Erro}
                </div>
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            {/* {ResetarSenhaModal &&
                <ModalResetarSenha />
            } */}
            {loadingLayout ?
                <div className="loading-screen">
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                </div>
                :
                <div className="container-login">
                    <div className="wrap-login">
                        <form className="login-form" onSubmit={AuthUserAdmin}>
                            <div className="logo-empresa">
                                <img src={`${logoLogin}`} alt="logo" />
                            </div>
                            <span className="login-form-title">
                                <h3>Gestão da demanda ADMIN</h3>
                            </span>
                            <div className="wrap-input">
                                <input placeholder='E-mail' required className={email !== "" ? "has-val input" : "input"} type="email" value={email ? email : ''} onChange={(e) => setEmail(e.target.value)} />
                                <span className="focus-input" data-placeholder=""></span>
                            </div>
                            <div className="wrap-input-password">
                                <input placeholder='Senha' required className={password !== "" ? "has-val input" : "input"} type="password" value={password ? password : ''} onChange={(e) => setPassword(e.target.value)} />
                                <span className="focus-input" data-placeholder=""></span>
                            </div>
                            {/* <div>
                                <button className="btn-password" type="button" onClick={() => setResetarSenhaModal(true)}>Esqueci minha senha</button>
                            </div> */}
                            {loading ? (
                                <div className="container-login-form-btn">
                                    <button style={loginFormBtn}><div className="loader-container">
                                        <div className="spinner"></div>
                                    </div></button>
                                </div>
                            ) : (
                                <div className="container-login-form-btn">
                                    <button style={loginFormBtn} >Login</button>
                                </div>
                            )}
                        </form>
                    </div>
                </div>
            }
        </div>
    );
}

export default LoginAdmin;