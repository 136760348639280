import '../../App.css';
import React, { useContext, useEffect } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Form, Row, Col } from 'react-bootstrap';
import { FaTimes } from 'react-icons/fa';
import { StyleContext } from '../../context/StyleContext';

function ModalCadastrarPessoa({ closeModal }) {
    const { CadastrarPessoa, CadastroPessoa, setCadastroPessoa, Cargos, ListaCargo } = useContext(ApiContext)
    const { btnVer, btnCriar } = useContext(StyleContext)

    const handleChange = (event) => {
        setCadastroPessoa({ ...CadastroPessoa, [event.target.name]: event.target.value })
    }

    useEffect(() => {
        ListaCargo()
    }, [])

    return (
        <div className="modal-novo">
            <div className="modal-novo-interno">
                <div className="header-modal">
                    <h1 className="h1-modal">Cadastrar pessoa</h1>
                    <div className="btn-close-modal" onClick={() => closeModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form onSubmit={CadastrarPessoa}>
                    <div className="modal-content">
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Nome</Form.Label>
                                    <Form.Control maxLength={100} onChange={handleChange} name="nome" />
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Cargo</Form.Label>
                                    <Form.Select aria-label="Default select example" onChange={handleChange} name="cargoKey">
                                        <option value="0">Selecione</option>
                                        {Cargos &&
                                            Cargos?.map((item) => {
                                                return (
                                                    <option key={item.id} value={item.chave}>{item.descricao}</option>
                                                )
                                            })

                                        }
                                    </Form.Select>
                                </Form.Group>
                            </Col>
                        </Row>
                    </div>
                    <div className="footer-modal-criar-demanda">
                        <button type="button" className="btn-dash btn-ver" style={btnVer} onClick={() => closeModal(false)}>Cancelar</button>
                        <button className="btn-dash btn-criar" style={btnCriar}>Salvar</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalCadastrarPessoa