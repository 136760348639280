import React, { useContext } from "react";
import Rotas from "./routes/routes";

const Layout = () => {

    return (
        <>
            <Rotas />
        </>
    )
}

export default Layout