import '../../App.css';
import React, { useContext, useEffect, useState, useRef } from "react";
import { ApiContext } from "../../context/ApiContext";
import { Tab, Tabs, ProgressBar } from 'react-bootstrap';
import { InputLabel, MenuItem, FormControl, Select } from '@mui/material'
import { FaTimes } from 'react-icons/fa';
import { IoChevronDownOutline, IoChevronUp } from "react-icons/io5";
import { ChartComponent, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, AreaSeries, PolarSeries, RadarSeries, Highlight, Tooltip } from '@syncfusion/ej2-react-charts';
import { Browser } from '@syncfusion/ej2-base';
import { LinearGaugeComponent, AxesDirective, AxisDirective, PointersDirective, PointerDirective, AnnotationDirective, Annotations, AnnotationsDirective, RangesDirective, RangeDirective } from '@syncfusion/ej2-react-lineargauge';

function ModalRelatorioSwot() {
    const { CadastrarEmpresa, ListaRelatorio, SwotRelatorio, setCompartilharDemandaModal, ListaMatriz, SwotMatriz, SwotAtualKey } = useContext(ApiContext)

    const [forcaFraquezaValues, setForcaFraquezaValues] = useState([]);
    const [ameacaOportunidadeValues, setAmeacaOportunidadeValues] = useState([]);

    const theme = localStorage.getItem('theme') || 'light'

    let soma = SwotRelatorio?.quantidadeForca + SwotRelatorio?.quantidadeFraqueza + SwotRelatorio?.quantidadeOportunidade + SwotRelatorio?.quantidadeAmeaca

    const content = `<div style="width: 70px;margin-top: 25%;font-size: 16px;text-align: center">${SwotRelatorio && (SwotRelatorio.indice.toFixed(2))} %</div>`;

    let data = [
        { x: 'Forças', y: SwotRelatorio?.quantidadeForca },
        { x: 'Fraquezas', y: SwotRelatorio?.quantidadeFraqueza },
        { x: 'Oportunidades', y: SwotRelatorio?.quantidadeOportunidade },
        { x: 'Ameaças', y: SwotRelatorio?.quantidadeAmeaca },
    ]

    useEffect(() => {
        ListaRelatorio(SwotAtualKey)
        ListaMatriz(SwotAtualKey)
    }, [])

    const [type, setType] = useState('Polar');
    let chartInstance = useRef(null);
    const onChartLoad = (args) => {
        document.getElementById('chart-swot').setAttribute('title', '');
    };
    const load = (args) => {
    };
    const cor = (valor) => {
        if (valor >= -200 && valor <= -100) {
            return "indice indice-red";
        } else if (valor > -100 && valor < -30) {
            return "indice indice-light-red";
        } else if (valor > -30 && valor < 30) {
            return "indice indice-yellow";
        } else if (valor > 30 && valor < 100) {
            return "indice indice-light-green";
        } else if (valor >= 100 && valor <= 200) {
            return "indice indice-green";
        }
    }

    const handleForcaFraquezaChange = (selectedKey, index) => {
        setForcaFraquezaValues(prevState => {
            const updatedValues = [...prevState];
            updatedValues[index] = {
                key: selectedKey,
                tipo: SwotMatriz?.fraquezas?.some(item => item.key === selectedKey) ? "Fraqueza" : "Força"
            };
            return updatedValues;
        });
    };
    
    const handleAmeacaOportunidadeChange = (selectedKey, index) => {
        setAmeacaOportunidadeValues(prevState => {
            const updatedValues = [...prevState];
            updatedValues[index] = {
                key: selectedKey,
                tipo: SwotMatriz?.oportunidades?.some(item => item.key === selectedKey) ? "Oportunidade" : "Ameaça"
            };
            return updatedValues;
        });
    };

    const tipoEstrategia = (tipoForcaFraqueza, tipoOportunidadeAmeaca) => {
        if (tipoForcaFraqueza === "Força" && tipoOportunidadeAmeaca === "Oportunidade") {
            return "Estratégia ofensiva"
        } else if (tipoForcaFraqueza === "Força" && tipoOportunidadeAmeaca === "Ameaça") {
            return "Estratégia de confronto"
        } else if (tipoForcaFraqueza === "Fraqueza" && tipoOportunidadeAmeaca === "Oportunidade") {
            return "Estratégia de reforço"
        } else if (tipoForcaFraqueza === "Fraqueza" && tipoOportunidadeAmeaca === "Ameaça") {
            return "Estratégia defensiva"
        } else {
            return "-"
        }
    }

    const corCapacidade = (tipo) => {
        if (tipo === "Força" || tipo === "Oportunidade") {
            return "span-swot span-swot-green"
        } else if (tipo === "Fraqueza" || tipo === "Ameaça") {
            return "span-swot span-swot-red"
        }
    }

    const corTipo = (tipo) => {
        if (tipo === "Estratégia ofensiva") {
            return "span-swot span-swot-green"
        } else if (tipo === "Estratégia defensiva") {
            return "span-swot span-swot-red"
        } else if (tipo === "Estratégia de confronto" || tipo === "Estratégia de reforço") {
            return "span-swot span-swot-yellow"
        }
    }

    const tipoRecomendacao = (tipoForcaFraqueza, tipoOportunidadeAmeaca) => {
        if (tipoForcaFraqueza === "Força" && tipoOportunidadeAmeaca === "Oportunidade") {
            return "Procure criar planos de ação para desenvolver as vantagens competitivas através das soluções de TI."
        } else if (tipoForcaFraqueza === "Força" && tipoOportunidadeAmeaca === "Ameaça") {
            return "Procure elaborar planos de ação para modificar o ambiente a favor do seu negócio através das soluções de TI."
        } else if (tipoForcaFraqueza === "Fraqueza" && tipoOportunidadeAmeaca === "Oportunidade") {
            return "Procure criar planos de ação para aproveitar melhor as oportunidades que o ambiente porporciona através das soluções de TI."
        } else if (tipoForcaFraqueza === "Fraqueza" && tipoOportunidadeAmeaca === "Ameaça") {
            return "Isto pode ser um sinal de que você precisará fazer profundas mudanças para proteger a empresa através das soluções de TI."
        } else {
            return "-"
        }
    }

    return (
        <div className="modal-nova">
            <div className="modal-nova-interno">
                <div className="header-modal-nova">
                    <div>
                        <h1 className="h1-modal">Relatório SWOT - {SwotRelatorio?.descricao}</h1>
                    </div>
                    <div className="btn-close-modal" onClick={() => setCompartilharDemandaModal(false)}>
                        <FaTimes />
                    </div>
                </div>
                <form className='form-modal-nova' onSubmit={CadastrarEmpresa}>
                    <div>
                        <Tabs fill className='mb-3' style={{ margin: '0 40px' }}>
                            <Tab eventKey="indice" title="Relatórios">
                                {SwotRelatorio &&
                                    <>
                                        <div className='modal-swot'>
                                            <h1 className="h1-modal" style={{ borderBottom: '1px solid #e4e4e7', paddingLeft: '20px' }}>Índice de favorabilidade</h1>
                                            <div className='modal-body-swot'>
                                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <div className={cor(SwotRelatorio?.indice.toFixed(2))}>
                                                        <p>Seu índice</p>
                                                        <span>{SwotRelatorio?.indice.toFixed(2)} %</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='control-section'>
                                                <LinearGaugeComponent load={load.bind(this)} background='transparent' orientation='Horizontal' height='200'>
                                                    <Inject services={[Annotations]} />
                                                    <AxesDirective>
                                                        <AxisDirective minorTicks={{ interval: 100, height: 10, color: '#000' }} majorTicks={{ interval: 50, height: 20, color: '#000' }} labelStyle={{ offset: 48, font: { fontFamily: 'inherit' } }} minimum={-200} maximum={200} line={{ width: 2, color: '#000' }}>
                                                            <RangesDirective>
                                                                <RangeDirective start={-200} end={-100} startWidth={20} endWidth={20} color='#F87171'>
                                                                </RangeDirective>
                                                                <RangeDirective start={-100} end={-30} startWidth={20} endWidth={20} color='#FFA99B'>
                                                                </RangeDirective>
                                                                <RangeDirective start={-30} end={0} startWidth={20} endWidth={20} color='#FACC15'>
                                                                </RangeDirective>
                                                                <RangeDirective start={0} end={30} startWidth={20} endWidth={20} color='#FACC15'>
                                                                </RangeDirective>
                                                                <RangeDirective start={30} end={100} startWidth={20} endWidth={20} color='#43ce97'>
                                                                </RangeDirective>
                                                                <RangeDirective start={100} end={200} startWidth={20} endWidth={20} color='#8AB54E'>
                                                                </RangeDirective>
                                                            </RangesDirective>
                                                            <PointersDirective>
                                                                <PointerDirective value={SwotRelatorio.indice.toFixed(2)} placement='Near' height={15} width={15} offset={-40} markerType='Triangle'>
                                                                </PointerDirective>
                                                            </PointersDirective>
                                                        </AxisDirective>
                                                    </AxesDirective>
                                                    <AnnotationsDirective>
                                                        <AnnotationDirective content={content} axisIndex={0} axisValue={SwotRelatorio.indice.toFixed(2)} x={10} zIndex='1' y={-70} />
                                                    </AnnotationsDirective>
                                                </LinearGaugeComponent>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', borderTop: '1px solid #E4E4E7' }}>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Muito desfavorável</span>
                                                    <span className='span-swot span-swot-red'> <IoChevronDownOutline /> -100%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Desfavorável</span>
                                                    <span className='span-swot span-swot-red'>-30% a -100%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Equilíbrio</span>
                                                    <span className='span-swot span-swot-yellow'>-30% a 30%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', borderRight: '1px solid #e4e4e7', gap: '10px' }}>
                                                    <span>Favorável</span>
                                                    <span className='span-swot span-swot-green'>30% a 100%</span>
                                                </div>
                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px 0', gap: '10px' }}>
                                                    <span>Muito favorável</span>
                                                    <span className='span-swot span-swot-green'><IoChevronUp />100%</span>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div style={{ marginTop: '25px', display: 'flex', justifyContent: 'space-between', alignItems: 'start', height: '400px' }}>
                                    <div className='modal-swot' style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '20px', borderBottom: '1px solid #e4e4e7' }}>
                                            <h1 className="h1-modal" style={{ paddingLeft: '20px' }}>Análise Geral</h1>
                                            <span style={{ fontSize: '14px' }} className='span-cinza'>Fatores internos e externos</span>
                                        </div>
                                        {SwotRelatorio &&
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'start', justifyContent: 'start', gap: '25px' }} className='modal-body-swot'>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Forças</span>
                                                        <span>{SwotRelatorio?.porcentagemForca.toFixed(2)}% ({SwotRelatorio?.quantidadeForca})</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-forca' now={SwotRelatorio?.quantidadeForca} style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Fraquezas</span>
                                                        <span>{SwotRelatorio?.porcentagemFraqueza.toFixed(2)}% ({SwotRelatorio.quantidadeFraqueza})</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-fraqueza' now={SwotRelatorio?.quantidadeFraqueza} style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Oportunidades</span>
                                                        <span>{SwotRelatorio?.porcentagemOportunidade.toFixed(2)}% ({SwotRelatorio.quantidadeOportunidade})</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-oportunidade' now={SwotRelatorio?.quantidadeOportunidade} style={{ width: '100%' }} />
                                                </div>
                                                <div style={{ width: '100%', height: '100%' }}>
                                                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                        <span style={{ fontWeight: 600 }}>Ameaças</span>
                                                        <span>{SwotRelatorio?.porcentagemAmeaca.toFixed(2)}% ({SwotRelatorio?.quantidadeAmeaca})</span>
                                                    </div>
                                                    <ProgressBar min={0} max={soma} id='progress-ameaca' now={SwotRelatorio?.quantidadeAmeaca} style={{ width: '100%' }} />
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    <div className='modal-swot' style={{ width: '100%', height: '100%' }}>
                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '20px', borderBottom: '1px solid #e4e4e7' }}>
                                            <h1 className="h1-modal" style={{ paddingLeft: '20px' }}>Radar da Análise</h1>
                                            <span style={{ fontSize: '14px' }} className='span-cinza'>SWOT Capacidades</span>
                                        </div>
                                        <div className='modal-body-swot'>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                                                <ChartComponent id='chart-swot' ref={chartInstance} primaryXAxis={{ valueType: 'Category', labelPlacement: 'OnTicks', interval: 1, coefficient: Browser.isDevice ? 80 : 100 }} legendSettings={{ visible: true, enableHighlight: true }} tooltip={{ enable: true }} loaded={onChartLoad.bind(this)} width='380' height='300'>
                                                    <Inject services={[AreaSeries, Legend, Category, PolarSeries, RadarSeries, Highlight, Tooltip]} />
                                                    <SeriesCollectionDirective>
                                                        <SeriesDirective dataSource={data} xName='x' yName='y' width={2} opacity={0.5} type={type} drawType='Area' border={{ color: 'transparent' }} />
                                                    </SeriesCollectionDirective>
                                                </ChartComponent>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-swot" style={{ marginTop: '25px' }}>
                                    <div className="modal-body-swot" style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', gap: '25px' }}>
                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-green">Análise das forças</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.forcas.length > 0 ?
                                                    SwotRelatorio?.forcas?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-forca' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas forças a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-red">Análise das fraquezas</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.fraquezas.length > 0 ?
                                                    SwotRelatorio?.fraquezas?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-fraqueza' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas fraquezas a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-blue">Análise das oportunidade</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.oportunidades.length > 0 ?
                                                    SwotRelatorio?.oportunidades?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-oportunidade' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas oportunidades a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>

                                        <div style={{ border: '1px solid #e4e4e7', borderRadius: '8px', width: '100%' }}>
                                            <div style={{ padding: '16px', borderBottom: '1px solid #e4e4e7' }}>
                                                <span className="span-swot span-swot-yellow">Análise das ameaças</span>
                                            </div>
                                            <div className="modal-body-swot" style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                {SwotRelatorio && SwotRelatorio?.ameacas.length > 0 ?
                                                    SwotRelatorio?.ameacas?.map((item, index) => {
                                                        return (
                                                            <div style={{ width: '100%' }} key={index}>
                                                                <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '5px' }}>
                                                                    <span style={{ fontWeight: 600 }}>{item.capacidadeNegocioDemanda.titulo ? item.capacidadeNegocioDemanda.titulo : '-'}</span>
                                                                    <span>{item.gut}</span>
                                                                </div>
                                                                <ProgressBar min={0} max={125} id='progress-ameaca' now={item.gut} style={{ width: '100%' }} />
                                                            </div>
                                                        )
                                                    })
                                                    :
                                                    <p>Não foram adicionadas ameaças a esse SWOT.</p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="matriz" title="Matriz">
                                {SwotMatriz &&
                                    <>
                                        <div className='modal-swot' style={{ padding: '0 20px 20px 20px' }}>
                                            <h1 className="h1-modal">Capacidades Atuais</h1>
                                            <div style={{ display: 'flex', gap: '20px' }}>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-green'>
                                                    <h3>Forças</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.forcas.length > 0 ?
                                                            SwotMatriz?.forcas.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas forças a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-red'>
                                                    <h3>Fraquezas</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.fraquezas.length > 0 ?
                                                            SwotMatriz?.fraquezas.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas fraquezas a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='modal-swot' style={{ marginTop: '20px', padding: '0 20px 20px 20px' }}>
                                            <h1 className="h1-modal">Capacidades Desejadas</h1>
                                            <div style={{ display: 'flex', gap: '20px' }}>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-blue'>
                                                    <h3>Oportunidades</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.oportunidades.length > 0 ?
                                                            SwotMatriz?.oportunidades.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas oportunidades a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                                <div style={{ width: '50%' }} className='card-swot card-swot-yellow'>
                                                    <h3>Ameaças</h3>
                                                    <ul>
                                                        {SwotMatriz && SwotMatriz?.ameacas.length > 0 ?
                                                            SwotMatriz?.ameacas.map((item, index) => (
                                                                <li key={index}>
                                                                    <span>{item.capacidadeNegocioDemanda.titulo}</span>
                                                                    <span>{item.gut}</span>
                                                                </li>
                                                            ))
                                                            :
                                                            <p>Não foram adicionadas ameaças a esse SWOT.</p>
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                }
                            </Tab>
                            <Tab eventKey="cruzamento" title="Cruzamentos">
                                {SwotMatriz &&
                                <div className='modal-swot'>                                
                                    <table className='table-swot'>
                                        <thead style={{backgroundColor: theme == 'light' ? "#A7A7AB" : '#D4D4D8'}}>
                                            <tr>
                                                <th style={{width: '15%'}}>Forças e Fraquezas</th>
                                                <th style={{width: '10%'}}>Capacidade GTI</th>
                                                <th style={{width: '15%'}}>Oportunidade e Ameças</th>
                                                <th style={{width: '10%'}}>Capacidade GTI</th>
                                                <th style={{width: '15%'}}>Tipo de estratégia</th>
                                                <th style={{width: '35%'}}>Recomendação</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {[...(SwotMatriz?.fraquezas || []), ...(SwotMatriz?.forcas || []), ...(SwotMatriz?.oportunidades || []), ...(SwotMatriz?.ameacas || [])].map((item, index) => (
                                                <tr key={index}>
                                                    <td style={{width: '15%'}}>
                                                        <FormControl size="small" sx={{ m: 1, minWidth: 300 }}>
                                                            <InputLabel id={`select-forca-fraqueza-label-${index}`}>Selecione</InputLabel>
                                                            <Select
                                                                labelId={`select-forca-fraqueza-label-${index}`}
                                                                value={forcaFraquezaValues[index]?.key || ''}
                                                                label="Selecione"
                                                                onChange={(e) => handleForcaFraquezaChange(e.target.value, index)}
                                                            >
                                                                <MenuItem value="">Selecionar...</MenuItem>
                                                                {[...(SwotMatriz?.fraquezas || []), ...(SwotMatriz?.forcas || [])].map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option.key}>
                                                                        {option.capacidadeNegocioDemanda?.titulo || "-"}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </td>
                                                    <td style={{width: '10%'}}><span className={corCapacidade(forcaFraquezaValues[index]?.tipo || '-')}>{forcaFraquezaValues[index]?.tipo || '-'}</span></td>
                                                    <td style={{width: '15%'}}>
                                                        <FormControl size="small" sx={{ m: 1, minWidth: 300 }}>
                                                            <InputLabel id={`select-ameaca-oportunidade-label-${index}`}>Selecione</InputLabel>
                                                            <Select
                                                                labelId={`select-ameaca-oportunidade-label-${index}`}
                                                                value={ameacaOportunidadeValues[index]?.key || ''}
                                                                label="Selecione"
                                                                onChange={(e) => handleAmeacaOportunidadeChange(e.target.value, index)}
                                                            >
                                                                <MenuItem value="">Selecionar...</MenuItem>
                                                                {[...(SwotMatriz?.oportunidades || []), ...(SwotMatriz?.ameacas || [])].map((option, optionIndex) => (
                                                                    <MenuItem key={optionIndex} value={option.key}>
                                                                        {option.capacidadeNegocioDemanda?.titulo || "-"}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        </FormControl>
                                                    </td>
                                                    <td style={{width: '10%'}}><span className={corCapacidade(ameacaOportunidadeValues[index]?.tipo || '-')}>{ameacaOportunidadeValues[index]?.tipo || '-'}</span></td>
                                                    <td style={{width: '15%'}}>
                                                        <span className={corTipo(tipoEstrategia(forcaFraquezaValues[index]?.tipo || '-', ameacaOportunidadeValues[index]?.tipo || '-'))}>{tipoEstrategia(forcaFraquezaValues[index]?.tipo || '-', ameacaOportunidadeValues[index]?.tipo || '-')}</span>
                                                    </td>
                                                    <td style={{width: '35%'}}>
                                                        {tipoRecomendacao(forcaFraquezaValues[index]?.tipo || '-', ameacaOportunidadeValues[index]?.tipo || '-')}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div>
                                }
                            </Tab>
                        </Tabs>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalRelatorioSwot