import '../../App.css';
import React, { Fragment, useContext, useEffect, useState, useRef } from "react";
import { ApiContext } from "../../context/ApiContext";
import { BsTrash } from 'react-icons/bs'
import { TbTrash, TbPencil } from "react-icons/tb";
import { FaTimes, FaPlus } from 'react-icons/fa';
import { SlOptionsVertical } from 'react-icons/sl';
import { Form, Row, Col, Card, OverlayTrigger, Tooltip } from 'react-bootstrap';
import ModalEditorTextoEditar from './ModalEditorTextoEditar';
import moment from 'moment';
import { StyleContext } from '../../context/StyleContext';
import { Stepper, Step, StepLabel, Box } from '@mui/material';

const steps = ['Visão Geral', 'Informações', 'Plano de Negócios', 'Área/Capacidades Atendidas', 'Competências', 'GUT/BSC', 'Comentários/Anexos'];

function ModalEditarDemanda({ priorizacao }) {
    const { ListaComentario, Comentario, ListaLink, Link, Tipos, Linhas, Fases, Categorias, Areas, ListaTipoDemanda, ListaCategorizacao, ListaCapacidades, Setores, setGut, Gut, setgutBSC, GutBSC, setlinhaAtendimento, setModalEditarDemanda, GutGravidade,
        setGutGravidade,
        GutUrgencia,
        setGutUrgencia,
        GutTendencia,
        setGutTendencia,
        BSC,
        setBSC,
        EditarDemanda,
        setAddComentario,
        setAddLink,
        AdicionarComentario,
        AdicionarLink,
        ExcluirComentario,
        ExcluirLink,
        setAddRisco,
        ListaRisco,
        comentarioRef,
        linkRef,
        descricaoRef,
        riscoJustificativaRef,
        mitigacaoJustificativaRef,
        ListaCicloDemanda,
        CicloDem,
        RTFModal,
        setRTFModal,
        Dem, setDem,
        setLoading,
        ListaSetorGerencia,
        ListaLinhaAtendimento,
        DemandaAtualKey,
        ListarCompartilhamentos,
        ListaDemCompartilhada,
        setRiscoEditar,
        DiretrizesItemsDoCiclo,
        ListaCiclos,
        Ciclos,
        ListaPlanoDemanda,
        PlanoDemanda,
        ExcluirPlanoDemanda,
        ObterDiretrizesItemsDoCiclo,
        stringDiretrizesItems,
        ListaCapacidadesPorArea,
        CapacidadesPorArea,
        ListaCapacidadeNegocio,
        CapacidadeDemanda,
        Capacidades,
        ExcluirCapacidadeNegocio,
        AdicionarCapacidadeNegocio,
        ObterGutSwot,
        GutSwot,
        ListaCompetenciaItem,
        CompetenciaDemanda,
        AdicionarCompetenciaItem,
        Competencias,
        ListaCompetencia,
        ExcluirCompetenciaItem,
        setCapacidadesPorArea,
        ListaAreaPorCiclo, AreaPorCiclo,
        CapacidadePorAreaSwot,
        setCapacidadePorAreaSwot,
        ListaCapacidadePorAreaSwot
    } = useContext(ApiContext)

    const { btnVer, btnCriar, btnPesquisar, corTerciaria, corPrimaria } = useContext(StyleContext)

    const [editorTexto, setEditorTexto] = useState('')
    const [valorTexto, setValorTexto] = useState('')
    const [obrigatorio, setObrigatorio] = useState(Dem.faseDemanda.descricao == 'Ideia' || Dem.faseDemanda.descricao == 'Entendimento' ? '' : '*')
    const [obrigatorioGut, setObrigatorioGut] = useState(Dem.faseDemanda.descricao == 'Ideia' || Dem.faseDemanda.descricao == 'Entendimento' || Dem.faseDemanda.descricao == 'Priorização' ? '' : '*')
    const [ValorRisco, setValorRisco] = useState()
    const [ValorRiscoJustificativa, setValorRiscoJustificativa] = useState()
    const [ValorMitigacao, setValorMitigacao] = useState()
    const [ValorMitigacaoJustificativa, setValorMitigacaoJustificativa] = useState()
    const [ValorKey, setValorKey] = useState()
    const [areaKey, setAreaKey] = useState("")
    const [competenciaKey, setCompetenciaKey] = useState("")
    const [capacidadeKey, setCapacidadeKey] = useState("")
    const [quantidade, setQuantidade] = useState("")
    const [activeStep, setActiveStep] = useState(0)
    const [completedSteps, setCompletedSteps] = useState(new Set());
    const [cicloId, setCicloId] = useState("")

    const [selectedItems, setSelectedItems] = useState([])

    const areaRef = useRef()
    const capRef = useRef()
    const compRef = useRef()

    const handleChange = (event) => {
        setDem({ ...Dem, [event.target.name]: event.target.value })
        if (event.target.name == "cicloDemandaId") {
            setCicloId(value.id.toString())
            const value = JSON.parse(event.target.value)
            setDem({
                ...Dem,
                ["cicloDemandaId"]: value.id.toString(),
                ["cicloDemanda"]: value
            })
        }
        if (event.target.name == "areaId") {
            const value = JSON.parse(event.target.value)
            setDem({
                ...Dem,
                ["areaId"]: value.id.toString(),
                ["area"]: value,
            })
        }
        if (event.target.name == "guT_Gravidade") {
            setGutGravidade(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == "guT_Urgencia") {
            setGutUrgencia(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == "guT_Tendencia") {
            setGutTendencia(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == "diretrizEstrategicaId") {
            const valor = JSON.parse(event.target.value)
            setDem({ ...Dem, [event.target.name]: JSON.parse(event.target.value).id })
            setBSC(valor.peso)
        }
        if (event.target.name == "linhaAtendimento") {
            setlinhaAtendimento(event.target.value)
            setDem({ ...Dem, [event.target.name]: event.target.value })
        }
        if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 0) {
            setObrigatorio('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 1) {
            setObrigatorio('')
        } else if (event.target.name == 'faseDemandaId') {
            setObrigatorio('*')
        }
        if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 0) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 1) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId' && event.target.selectedIndex == 2) {
            setObrigatorioGut('')
        } else if (event.target.name == 'faseDemandaId') {
            setObrigatorioGut('*')
        }
    }

    const handleChangeEditor = (event) => {
        if (event.target.innerHTML === "<p><br></p>") {
            setDem({ ...Dem, [event.target.id]: '' })
        }
        else {
            setDem({ ...Dem, [event.target.id]: event.target.innerHTML })
        }
    }

    const adicionarComentario = (event) => {
        const { name, value } = event.target;
        setAddComentario((prevAddComentario) => ({
            ...prevAddComentario,
            [name]: value
        }));
    }

    const adicionarLink = (event) => {
        const { name, value } = event.target;
        setAddLink((prevAddLink) => ({
            ...prevAddLink,
            [name]: value
        }));
    }

    const adicionarRisco = (event) => {
        const { name, value } = event.target;
        setAddRisco((prevAddRisco) => ({
            ...prevAddRisco,
            [name]: value
        }));
    }

    const editarRisco = (risco, riscoJustificativa, mitigacao, mitigacaoJustificativa, key) => {
        const checkboxes = document.getElementsByName("riscoAtual");
        checkboxes.forEach((checkbox) => {
            if (checkbox.value === risco) {
                checkbox.checked = true;
            }
        });

        const mitigacaoCheckboxes = document.getElementsByName("mitigacaoRisco");
        mitigacaoCheckboxes.forEach((checkbox) => {
            if (checkbox.value === mitigacao) {
                checkbox.checked = true;
            }
        });
        setRiscoEditar(true)
        setValorRisco(risco)
        setValorRiscoJustificativa(riscoJustificativa)
        setValorMitigacao(mitigacao)
        setValorMitigacaoJustificativa(mitigacaoJustificativa)
        setValorKey(key)
    }

    const cancelarRisco = () => {
        setValorRisco('')
        setValorRiscoJustificativa('')
        setValorMitigacao('')
        setValorMitigacaoJustificativa('')
        setRiscoEditar(false)

        riscoJustificativaRef.current.value = '';
        mitigacaoJustificativaRef.current.value = '';

        const checkboxes = document.getElementsByName("riscoAtual");
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });

        const mitigacaoCheckboxes = document.getElementsByName("mitigacaoRisco");
        mitigacaoCheckboxes.forEach((checkbox) => {
            checkbox.checked = false;
        });
    }

    const abrirEditor = (tipo, valor) => {
        setRTFModal(true)
        setEditorTexto(tipo)
        setValorTexto(valor)
    }

    const fechar = () => {
        setDem({})
        setModalEditarDemanda(false)
    }

    function carregando() {
        return new Promise(function (resolve) {
            setLoading(true)
            setTimeout(resolve, 1)
        });
    }

    function naoCarregando() {
        return new Promise(function (resolve) {
            setLoading(resolve, false)
        });
    }

    const handleCheckboxChange = (parcial, id) => {
        if (selectedItems.some(item => item.id === id)) {
            setSelectedItems(selectedItems.filter(item => item.id !== id));
        } else {
            setSelectedItems([...selectedItems, { id, parcial }]);
        }
    };

    const handleNext = () => {
        setCompletedSteps((prevCompleted) => {
            const newCompleted = new Set(prevCompleted);
            newCompleted.add(activeStep);
            return newCompleted;
        });
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => {
        setActiveStep(step);
    };

    const StepIcon = ({ backgroundColor, icon, borderColor, color }) => {
        return (
            <div style={{
                backgroundColor: backgroundColor,
                borderRadius: '50%',
                width: '24px',
                height: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                color: color,
                fontWeight: 'bold',
                border: '1px solid',
                borderColor: borderColor
            }}>
                {icon}
            </div>
        );
    };

    const handleCheckCapacidade = () => {
        AdicionarCapacidadeNegocio(capacidadeKey, areaKey)
            .then(() => {
                setCapacidadeKey("")
                setAreaKey("")
                areaRef.current.value = 0
                capRef.current.value = 0
                setCapacidadesPorArea([])
            })
    }

    const handleCheckCompetencia = () => {
        AdicionarCompetenciaItem(competenciaKey, quantidade)
            .then(() => {
                setCompetenciaKey("")
                setQuantidade("")
                compRef.current.value = 0
            })
    }

    useEffect(() => {
        chamarLista()
        setRiscoEditar(false)
    }, [])

    useEffect(() => {
        if (DiretrizesItemsDoCiclo.length > 0) {
            let array = []
            const select = Dem.diretrizesItensBSC ? Dem.diretrizesItensBSC.split(",").map(Number) : []
            select.forEach(element => {
                const items = DiretrizesItemsDoCiclo.filter(item => item.id === element)
                array.push(items[0])
            });
            setSelectedItems(array)
        }
    }, [DiretrizesItemsDoCiclo])

    useEffect(() => {
        if (selectedItems.length > 0) {
            let soma = 0
            const stringItens = selectedItems.map(item => item.id).join(",");
            soma = selectedItems.reduce((accumulator, item) => accumulator + item.parcial, 0);
            setBSC(soma)
            setDem(prevState => ({
                ...prevState,
                ['guT_BSC_Pontuacao']: Dem?.guT_Pontuacao / soma,
                ['diretrizesItensBSC']: stringItens
            }));
        } else {
            setDem({
                ...Dem,
                ['guT_BSC_Pontuacao']: 0,
                ['diretrizesItensBSC']: ""
            })
        }
    }, [selectedItems])

    const chamarLista = async () => {
        ListaTipoDemanda('S')
        ListaLinhaAtendimento('S')
        ListaCategorizacao('S')
        ListaCapacidades('S')
        ListaCiclos()
        //ListaFases()
        ListaSetorGerencia('S')
        ListaComentario()
        ListaLink()
        ListaRisco()
        ListaPlanoDemanda()
        ListaCicloDemanda(DemandaAtualKey)
        ListarCompartilhamentos(DemandaAtualKey)
        ListaCapacidadeNegocio()
        ListaCompetenciaItem()
        ListaCompetencia()
    }

    useEffect(() => {
        ObterDiretrizesItemsDoCiclo(Dem?.cicloDemanda.key || Dem?.cicloDemanda.chave)
    }, [])

    useEffect(() => {
        if (areaKey !== "" && Dem.cicloDemandaId !== "") {
            ListaCapacidadePorAreaSwot(Dem.cicloDemandaId, areaKey)
        }
    }, [areaKey, Dem.cicloDemandaId])

    useEffect(() => {
        ObterDiretrizesItemsDoCiclo(Dem?.cicloDemanda.key || Dem?.cicloDemanda.chave)
    }, [Dem?.cicloDemandaId])

    useEffect(() => {
        ObterGutSwot(Dem?.area.key || Dem?.area.chave, Dem?.cicloDemanda.key || Dem?.cicloDemanda.chave)
    }, [Dem?.areaId, Dem?.cicloDemandaId])

    useEffect(() => {
        setDem({
            ...Dem,
            ["guT_Swot"]: GutSwot,
        })
    }, [GutSwot])

    useEffect(() => {
        setGut(GutGravidade * GutUrgencia * GutTendencia)
        setDem({
            ...Dem,
            ["guT_Pontuacao"]: Gut,
            ["guT_BSC_Pontuacao"]: Gut / BSC,
        })
    }, [GutGravidade, GutUrgencia, GutTendencia, Gut])

    const groupDataByAvaliacaoPlanoNegocioId = (data) => {
        if (data != null && data != 'undefined') {
            const grouped = data?.reduce((acc, item) => {
                const id = item.planoNegocio.avaliacaoPlanoNegocioId;
                if (!acc[id]) {
                    acc[id] = [];
                }
                acc[id].push(item);
                return acc;
            }, {});

            return Object.keys(grouped)
                .sort((a, b) => a - b)
                .map((key) => grouped[key]);
        }
    };

    const groupedData = groupDataByAvaliacaoPlanoNegocioId(PlanoDemanda);

    useEffect(() => {
        if (Dem.cicloDemandaId !== "") {
            ListaAreaPorCiclo(Dem.cicloDemandaId)
        }
    }, [Dem.cicloDemandaId])

    return (
        <Fragment>
            {RTFModal &&
                <ModalEditorTextoEditar tipoEdicao={editorTexto} valor={valorTexto} />
            }
            <div className="modal-nova">
                <div className="modal-nova-interno">
                    <div className="header-modal-nova">
                        <div style={{ display: 'flex', alignItems: 'baseline', gap: '5px' }}>
                            <h1 className="h1-modal">Editar demanda #{Dem.id} -</h1>
                            {Dem?.cancelamentoDataHora || Dem?.conclusaoDataHora &&
                                <div>
                                    <b>
                                        {Dem.cancelamentoDataHora && 'Cancelada em: ' + moment(Dem.cancelamentoDataHora).format('L') + ' às ' + moment(Dem.cancelamentoDataHora).format('LT')}
                                        {Dem.conclusaoDataHora && 'Concluída em: ' + moment(Dem.conclusaoDataHora).format('L') + ' às ' + moment(Dem.conclusaoDataHora).format('LT')}
                                    </b>
                                </div>
                            }
                        </div>
                        <div className="btn-close-modal" onClick={() => fechar()}>
                            <FaTimes />
                        </div>
                    </div>
                    <form style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div>
                            <div style={{ margin: '0px 40px 20px 40px' }}>
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const isActive = index === activeStep;
                                        const isCompleted = completedSteps.has(index);
                                        const isPreviouslyActivated = completedSteps.has(index) || index < activeStep;
                                        const isInactiveButWasActive = completedSteps.has(index) && index > activeStep;

                                        const backgroundColor = isInactiveButWasActive
                                            ? 'white'
                                            : isActive
                                                ? 'white'
                                                : isCompleted || isPreviouslyActivated
                                                    ? corPrimaria
                                                    : 'white';

                                        const borderColor = isInactiveButWasActive
                                            ? 'white'
                                            : isActive || isCompleted
                                                ? corPrimaria
                                                : '#D4D4D8';

                                        const color = isInactiveButWasActive
                                            ? 'white'
                                            : isActive
                                                ? corPrimaria
                                                : isCompleted || isPreviouslyActivated
                                                    ? 'white'
                                                    : '#D4D4D8';

                                        return (
                                            <Step key={label}>
                                                <StepLabel
                                                    onClick={() => handleStep(index)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        color: isActive || isPreviouslyActivated ? 'white' : 'inherit',
                                                        padding: '10px',
                                                    }}
                                                    StepIconComponent={({ icon }) => (
                                                        <StepIcon backgroundColor={backgroundColor} color={color} borderColor={borderColor} icon={icon} />
                                                    )}
                                                >
                                                    <span className='titulo-fases'>{label}</span>
                                                </StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </div>
                            <div>
                                {activeStep === 0 &&
                                    <div className='modal-swot'>
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Visão Geral</h1>
                                            <p>Preencha as informações gerais relacionadas a sua demanda:</p>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Título</Form.Label>
                                                        <Form.Control defaultValue={Dem.titulo} onChange={handleChange} name="titulo" />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Área Solicitante</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="areaId">
                                                            <option value="0">Selecione</option>
                                                            {Areas?.map((s) => {
                                                                return (
                                                                    <option key={s.id}
                                                                        value={JSON.stringify(s)}
                                                                        selected={s.id == Dem?.areaId ? true : false}
                                                                    >
                                                                        {s.descricao}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>


                                            <Row>
                                                <Col xs={6}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Solicitante</Form.Label>
                                                        <Form.Control value={Dem?.solicitante} onChange={handleChange} name="solicitante" />
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={4}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Ciclos</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="cicloDemandaId">
                                                            <option key={0} value="0">Selecione</option>
                                                            {
                                                                Ciclos?.filter(item => item.ativo === true)?.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={JSON.stringify(item)} selected={item.id == Dem?.cicloDemandaId ? true : false}>
                                                                            {item.descricao}
                                                                        </option>
                                                                    )
                                                                })
                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col xs={2}>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Demanda Dependente ID</Form.Label>
                                                        <Form.Control value={Dem?.demandaDependenteId} onChange={handleChange} name="demandaDependenteId" type='number' maxLength={100} />
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Label>Perspectivas Estratégicas</Form.Label>
                                                {DiretrizesItemsDoCiclo?.map((item) => {
                                                    const isChecked = selectedItems.some(selectedItem => selectedItem.id === item.id);
                                                    return (
                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            <input type='checkbox' style={{ marginRight: '10px' }} checked={isChecked} onChange={() => handleCheckboxChange(item.parcial, item.id)} />
                                                            <p style={{ margin: 0 }}>{item.descricao} ({item.diretrizEstrategica.descricao})</p>
                                                        </div>
                                                    )
                                                })}
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 1 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Informações</h1>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Time/Squad</Form.Label>
                                                        <Form.Select aria-label="Default select example" defaultValue={Dem?.linhaAtendimentoId} onChange={handleChange} name="linhaAtendimentoId">
                                                            <option key={0} value="0">Selecione</option>
                                                            {
                                                                Linhas?.map((item) => {
                                                                    return (
                                                                        <option key={item.id} value={item.id} selected={item.id == Dem?.linhaAtendimentoId ? true : false}>{item.descricao}</option>
                                                                    )
                                                                })

                                                            }
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Fase</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="faseDemandaId">
                                                            <option value="0">Selecione</option>
                                                            {Fases?.map((f, index) => {
                                                                return (
                                                                    <option key={index}
                                                                        value={JSON.stringify({ id: f.id, descricao: f.descricao })}
                                                                        selected={f.id == Dem.faseDemandaId ? true : false}
                                                                    >
                                                                        {f.descricao}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Tipo da Demanda</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="tipoDemandaId">
                                                            <option key={0} value="0">Selecione</option>
                                                            {Tipos?.map((item) => {
                                                                return (
                                                                    <option key={item.id} value={item.id} selected={item.id == Dem?.tipoDemandaId ? true : false}>
                                                                        {item.descricao}
                                                                    </option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Categorização estratégica {obrigatorio}</Form.Label>
                                                        <Form.Select aria-label="Default select example" onChange={handleChange} name="categorizacaoEstrategicaDemandaId">
                                                            <option key={0} value="0">Selecione</option>
                                                            {Categorias?.map((item) => {
                                                                return (
                                                                    <option key={item.id} value={item.id} selected={item.id == Dem?.categorizacaoEstrategicaDemandaId ? true : false}>
                                                                        {item.descricao}
                                                                    </option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Form.Label>Deliberação pelo comitê</Form.Label>
                                                {['radio'].map((type) => (
                                                    <div key={`inline-${type}`} className="mb-3">
                                                        <Form.Check
                                                            inline
                                                            label="Sim"
                                                            value={true}
                                                            type={type}
                                                            id={`inline-${type}-1`}
                                                            onChange={handleChange}
                                                            name="deliberacaoComite"
                                                            defaultChecked={Dem?.deliberacaoComite ? true : false}
                                                        />
                                                        <Form.Check
                                                            inline
                                                            label="Não"
                                                            value={false}
                                                            type={type}
                                                            id={`inline-${type}-2`}
                                                            onChange={handleChange}
                                                            name="deliberacaoComite"
                                                            defaultChecked={!Dem?.deliberacaoComite ? true : false}
                                                        />
                                                    </div>
                                                ))}

                                            </Row>
                                            <Row>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Descrição
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={<Tooltip id="button-tooltip">
                                                                    Editor de texto
                                                                </Tooltip>}
                                                            ><button type='button' className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => abrirEditor('descricao', Dem?.descricao)}><TbPencil /></button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <div
                                                            contentEditable="true"
                                                            onBlur={handleChangeEditor}
                                                            className="input-descricao"
                                                            dangerouslySetInnerHTML={{ __html: Dem?.descricao }}
                                                            id="descricao"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Resultado Esperado
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={<Tooltip id="button-tooltip">
                                                                    Editor de texto
                                                                </Tooltip>}
                                                            ><button type='button' className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => abrirEditor('resultadoEsperado', Dem?.resultadoEsperado)}><TbPencil /></button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <div
                                                            contentEditable="true"
                                                            onBlur={handleChangeEditor}
                                                            className="input-descricao"
                                                            dangerouslySetInnerHTML={{ __html: Dem?.resultadoEsperado }}
                                                            id="resultadoEsperado"
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Problema Identificado
                                                            <OverlayTrigger
                                                                placement="right"
                                                                overlay={<Tooltip id="button-tooltip">
                                                                    Editor de texto
                                                                </Tooltip>}
                                                            ><button type='button' className='btn-dem' style={{ color: `${corTerciaria}` }} onClick={() => abrirEditor('problemaIdentificadoCenarioAtual', Dem?.problemaIdentificadoCenarioAtual)}><TbPencil /></button>
                                                            </OverlayTrigger>
                                                        </Form.Label>
                                                        <div
                                                            contentEditable="true"
                                                            onBlur={handleChangeEditor}
                                                            className="input-descricao"
                                                            dangerouslySetInnerHTML={{ __html: Dem?.problemaIdentificadoCenarioAtual }}
                                                            id="problemaIdentificadoCenarioAtual"
                                                        />
                                                    </Form.Group>
                                                </Col>

                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 2 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Plano de Negócios</h1>
                                            <Row style={{ padding: '5px 0', borderBottom: '1px solid #ccc' }}>
                                                <Col md={4}><strong>Avaliação</strong></Col>
                                                <Col md={4}><strong>Indicador</strong></Col>
                                                <Col md={3}><strong>Valor</strong></Col>
                                                <Col md={1}></Col>
                                            </Row>
                                            {groupedData?.map((group, index) => (
                                                <div key={index}>
                                                    {group?.map((item, itemIndex) => (
                                                        <Row key={item.chave} style={{ padding: '5px 0', borderBottom: '1px solid #ccc' }}>
                                                            <Col md={4}>
                                                                {itemIndex === 0 && (
                                                                    <div>{item.planoNegocio.avaliacaoPlanoNegocio.descricao}</div>
                                                                )}
                                                            </Col>
                                                            <Col md={4}>
                                                                {item.planoNegocio.indicador}
                                                            </Col>
                                                            <Col md={3}>
                                                                {item.planoNegocio.tipo === 'DATA' ?
                                                                    <Form.Control type='date' value={item.valor} readOnly />
                                                                    :
                                                                    <Form.Control value={item.valor} readOnly />
                                                                }

                                                            </Col>
                                                            <Col md={1}>
                                                                <div className="div-button">
                                                                    <button type="button" className="btn-del" onClick={() => ExcluirPlanoDemanda(item.chave)}><TbTrash /></button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    ))}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                }
                                {activeStep === 3 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Áreas/Capacidades Atendidas</h1>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '25px' }}>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Área</Form.Label>
                                                        <Form.Select ref={areaRef} aria-label="Default select example" onChange={(e) => setAreaKey(e.target.value)}>
                                                            <option key={0} value="0">Selecione</option>
                                                            {AreaPorCiclo?.map((s) => {
                                                                return (
                                                                    <option key={s.id}
                                                                        value={s.key}
                                                                    >
                                                                        {s.descricao}</option>
                                                                )
                                                            })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Capacidade de Negócio</Form.Label>
                                                        <Form.Select ref={capRef} aria-label="Default select example" onChange={(e) => setCapacidadeKey(e.target.value)} name="capacidadeNegocio">
                                                            <option value="0">Selecione</option>
                                                            {CapacidadePorAreaSwot &&
                                                                CapacidadePorAreaSwot?.map((c) => {
                                                                    return (
                                                                        <option key={c.id} value={c.key}>{c.titulo}</option>
                                                                    )

                                                                })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <button type="button" className="btn-dash btn-criar btn-add" style={btnCriar} onClick={() => handleCheckCapacidade()}><FaPlus /></button>
                                            </div>
                                            <hr />
                                            <Row>
                                                <div style={{ minHeight: '30vh', width: '100%' }}>
                                                    {CapacidadeDemanda &&
                                                        CapacidadeDemanda?.map((cap, index) => {
                                                            return (
                                                                <div key={index}>
                                                                    <div key={index} className="content-comentario">
                                                                        <div className="div-comentario">
                                                                            <SlOptionsVertical style={{ marginRight: '15px' }} />
                                                                            <span><div dangerouslySetInnerHTML={{ __html: cap?.capacidadeNegocioDemanda?.area?.descricao || "" }} /></span>
                                                                        </div>
                                                                        <div className="div-comentario">
                                                                            <span><div dangerouslySetInnerHTML={{ __html: cap?.capacidadeNegocioDemanda?.titulo || "" }} /></span>
                                                                        </div>
                                                                        <div className="div-button">
                                                                            <button type="button" className="btn-del" onClick={() => ExcluirCapacidadeNegocio(cap.key)}><TbTrash /></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 4 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className='h1-modal'>Competências</h1>
                                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '25px' }}>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Competências</Form.Label>
                                                        <Form.Select ref={compRef} aria-label="Default select example" onChange={(e) => setCompetenciaKey(e.target.value)}>
                                                            <option value={0}>Selecione</option>
                                                            {Competencias &&
                                                                Competencias?.map((c) => {
                                                                    return (
                                                                        <option key={c.id} value={c.chave}>{c.descricao}</option>
                                                                    )

                                                                })}
                                                        </Form.Select>
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>Quantidade</Form.Label>
                                                        <Form.Control value={quantidade} onChange={(e) => setQuantidade(e.target.value)} />
                                                    </Form.Group>
                                                </Col>
                                                <button type="button" className="btn-dash btn-criar btn-add" style={btnCriar} onClick={() => handleCheckCompetencia()}><FaPlus /></button>
                                            </div>
                                            <hr />
                                            <Row>
                                                <div style={{ minHeight: '30vh', width: '100%' }}>
                                                    {CompetenciaDemanda.map((cap, index) => {
                                                        return (
                                                            <div key={index}>
                                                                <div key={index} className="content-comentario">
                                                                    <div className="div-comentario">
                                                                        <SlOptionsVertical style={{ marginRight: '15px' }} />
                                                                        <span><div dangerouslySetInnerHTML={{ __html: cap.competencia.descricao }} /></span>
                                                                    </div>
                                                                    <div className="div-comentario">
                                                                        <span><div dangerouslySetInnerHTML={{ __html: cap.quantidade }} /></span>
                                                                    </div>
                                                                    <div className="div-button">
                                                                        <button type="button" className="btn-del" onClick={() => ExcluirCompetenciaItem(cap.key)}><TbTrash /></button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                }
                                {activeStep === 5 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            <h1 className="h1-modal">GUT/BSC</h1>
                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <Card style={{ width: '50%' }}>
                                                    <Row style={{ alignItems: 'center' }}>
                                                        <Col>
                                                            <Form.Group>
                                                                <Form.Label>Gravidade</Form.Label>
                                                                <Form.Select aria-label="Default select example" defaultValue={Dem?.guT_Gravidade} onChange={handleChange} name="guT_Gravidade" className="mb-3">
                                                                    <option key={0} value="0">Selecione</option>
                                                                    <option key={1} value="1">Sem gravidade</option>
                                                                    <option key={2} value="2">Pouco grave</option>
                                                                    <option key={3} value="3">Grave</option>
                                                                    <option key={4} value="4">Muito grave</option>
                                                                    <option key={5} value="5">Extremamente grave</option>
                                                                </Form.Select>
                                                                <Form.Label>Urgência</Form.Label>
                                                                <Form.Select aria-label="Default select example" defaultValue={Dem?.guT_Urgencia} onChange={handleChange} name="guT_Urgencia" className="mb-3">
                                                                    <option key={0} value="0">Selecione</option>
                                                                    <option key={1} value="1">Longuíssimo prazo</option>
                                                                    <option key={2} value="2">Longo prazo</option>
                                                                    <option key={3} value="3">Médio prazo</option>
                                                                    <option key={4} value="4">Curto</option>
                                                                    <option key={5} value="5">Imediatamente</option>
                                                                </Form.Select>
                                                                <Form.Label>Tendência</Form.Label>
                                                                <Form.Select aria-label="Default select example" defaultValue={Dem?.guT_Tendencia} onChange={handleChange} name="guT_Tendencia">
                                                                    <option key={0} value="0">Selecione</option>
                                                                    <option key={1} value="1">Desaparece</option>
                                                                    <option key={2} value="2">Reduz-se ligeiramente</option>
                                                                    <option key={3} value="3">Permanece</option>
                                                                    <option key={4} value="4">Aumenta</option>
                                                                    <option key={5} value="5">Piora muito</option>
                                                                </Form.Select>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col>
                                                            <div className="container-gut mb-3">
                                                                <div>
                                                                    <p className='title-gut'>GUT</p>
                                                                </div>
                                                                <div>
                                                                    <p className='number-gut'>{Dem?.guT_Pontuacao.toString()}</p>
                                                                </div>
                                                            </div>
                                                            <div className="container-gut mb-3">
                                                                <div>
                                                                    <p className='title-gut'>GUT/SWOT</p>
                                                                </div>
                                                                <div>
                                                                    <p className='number-gut'>{Dem?.guT_Swot.toString()}</p>
                                                                </div>
                                                            </div>
                                                            <div className="container-gut">
                                                                <div>
                                                                    <p className='title-gut'>GUT/BSC</p>
                                                                </div>
                                                                <div>
                                                                    <p className='number-gut'>{Dem?.guT_BSC_Pontuacao.toFixed(2).toString()}</p>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Card>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {activeStep === 6 &&
                                    <div className="modal-swot">
                                        <div className="modal-body-swot">
                                            Adicione comentários ou links na sua demanda:
                                            <div>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Comentário</Form.Label>
                                                    <Form.Control as="textarea" rows={2} onChange={adicionarComentario} name="comentario" ref={comentarioRef} />
                                                </Form.Group>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarComentario()}><FaPlus style={{ marginRight: "5px" }} />Adicionar comentário</button>
                                                </div>
                                                Comentários adicionados
                                                <div className="secao-comentario">
                                                    {Comentario?.map((item) => {
                                                        return (
                                                            <div key={item.chaveComentario} className="content-comentario">
                                                                <div>
                                                                    {/* <p className="p-title">{item.nome}</p> */}
                                                                    <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                                </div>
                                                                <div className="div-comentario">
                                                                    <p className="p-title">Comentário:</p>
                                                                    <span><p dangerouslySetInnerHTML={{ __html: item.comentario }}></p></span>
                                                                </div>
                                                                <div className="div-button">
                                                                    <button type="button" className="btn-del" onClick={() => ExcluirComentario(item.chaveComentario)}><TbTrash /></button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                            <hr />
                                            <div>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Link</Form.Label>
                                                    <Form.Control onChange={adicionarLink} name="addLink" ref={linkRef} />
                                                </Form.Group>
                                                <Form.Group className="mb-3">
                                                    <Form.Label>Descrição</Form.Label>
                                                    <Form.Control as="textarea" rows={2} onChange={adicionarLink} name="descricaoLink" ref={descricaoRef} />
                                                </Form.Group>
                                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                    <button type="button" className="btn-dash btn-pesquisar" style={btnPesquisar} onClick={() => AdicionarLink()}><FaPlus style={{ marginRight: "5px" }} />Adicionar link</button>
                                                </div>
                                                Links adicionados
                                                <div className="secao-link">
                                                    {Link?.map((item) => {
                                                        return (
                                                            <div key={item.chaveLink} className="content-comentario">
                                                                <div>
                                                                    {/* <p className="p-title">{item.nome}</p> */}
                                                                    <p className="p-content">{moment(item.dataHora).format('L')} às {moment(item.dataHora).format('LT')}</p>
                                                                </div>
                                                                <div className="div-link">
                                                                    <p className="p-title">Link: </p>
                                                                    <p><a href={item.link} target="_blank">{item.link}</a></p>
                                                                </div>
                                                                <div className="div-comentario">
                                                                    <p className="p-title">Comentário: </p>
                                                                    <span><p >{item.descricao}</p></span>
                                                                </div>
                                                                <div className="div-button">
                                                                    <button type="button" className="btn-del" onClick={() => ExcluirLink(item.chaveLink)}><BsTrash /></button>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* <Tab eventKey="ciclos" title={<span>Ciclos</span>}>
                                    {CicloDem && CicloDem.length > 0 ? (
                                        CicloDem.map((item, index) => (
                                            <div key={index} style={{ borderBottom: '1px solid #ccc', padding: '10px 5px' }}>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Ciclo:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.cicloDescricao}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Ordem:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.cicloOrdem}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Data:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{moment(item.dataHora).format('lll')}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>Usuário:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.usuarioNome}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>GUT:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.guT_Pontuacao}</Form.Label>
                                                </Form.Group>
                                                <Form.Group>
                                                    <Form.Label style={{ fontWeight: 'bold' }}>GUT/BSC:</Form.Label>
                                                    <Form.Label style={{ marginLeft: '5px' }}>{item.guT_BSC_Pontuacao}</Form.Label>
                                                </Form.Group>
                                            </div>
                                        ))
                                    ) : (
                                        <p style={{ textAlign: 'center' }}>Essa demanda não está inserida em nenhum ciclo!</p>
                                    )}
                                </Tab> */}
                                {/* <Tab eventKey="share" title={<span>Compartilhar</span>}>
                                {ListaDemCompartilhada && ListaDemCompartilhada.length > 0 ? (
                                    ListaDemCompartilhada.map((item) => (
                                        <div key={item.id} style={{ borderBottom: '1px solid #ccc', padding: '10px 5px' }}>
                                            <Form.Group>
                                                <Form.Label style={{ fontWeight: 'bold' }}>Usuário:</Form.Label>
                                                <Form.Label style={{ marginLeft: '5px' }}>{item.usuario.nome}</Form.Label>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label style={{ fontWeight: 'bold' }}>Data de validade:</Form.Label>
                                                <Form.Label style={{ marginLeft: '5px' }}>{moment(item.dataValidade).format('lll')}</Form.Label>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label style={{ fontWeight: 'bold' }}>Quantidade de Leituras:</Form.Label>
                                                <Form.Label style={{ marginLeft: '5px' }}>{item.quantidadeLeituras}</Form.Label>
                                            </Form.Group>
                                        </div>
                                    ))
                                ) : (
                                    <p style={{ textAlign: 'center' }}>Essa demanda não foi compartilhada!</p>
                                )}
                            </Tab> */}
                            </div>
                        </div>
                        <React.Fragment>
                            <Box className="footer-modal-nova">
                                {activeStep === 0 ?
                                    <button
                                        type="button" className="btn-dash btn-ver" style={btnVer}
                                        onClick={() => fechar()}
                                        sx={{ mr: 1 }}
                                    >
                                        Cancelar
                                    </button>
                                    :
                                    <button
                                        type="button" className="btn-dash btn-ver" style={btnVer}
                                        onClick={handleBack}
                                        sx={{ mr: 1 }}
                                    >
                                        Voltar
                                    </button>
                                }
                                <Box sx={{ flex: '1 1 auto' }} />
                                {activeStep === steps.length - 1 ? (
                                    <button
                                        type="button"
                                        className="btn-dash btn-criar"
                                        style={btnCriar}
                                        onClick={() => EditarDemanda(priorizacao)}
                                    >
                                        Salvar
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className="btn-dash btn-criar"
                                        style={btnCriar}
                                        onClick={handleNext}
                                    >
                                        Próximo
                                    </button>
                                )}
                            </Box>
                        </React.Fragment>
                    </form>
                </div>
            </div>
        </Fragment>
    )

}


export default ModalEditarDemanda