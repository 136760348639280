import React, { useContext, useState } from 'react';
import { AuthContext } from "../../context/AuthContext";
import { ThemeProvider } from 'styled-components';
import { GlobalStyled } from '../../GlobalStyled/GlobalStyled';
import { Link, useNavigate } from 'react-router-dom';
import { IoLogOutOutline } from "react-icons/io5";
import { TbReplace } from "react-icons/tb";
import { RiEdit2Line } from "react-icons/ri";
//import imglogo from '../../images/smartDemand.png';
import { ligthTheme, darkTheme } from '../../GlobalStyled/themeBlue'
import Dropdown from 'react-bootstrap/Dropdown';
import Toggle from '../../components/botao/BotaoTrocaTema';
import Cookies from 'js-cookie'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { BiHomeAlt } from "react-icons/bi";
import { MdOutlineChevronRight } from "react-icons/md";
import { StyleContext } from '../../context/StyleContext';
import { AdminContext } from '../../context/AdminContext';

function HeaderAdmin({ pagina, subpagina }) {
    const navigate = useNavigate()
    const { header, logoBranca } = useContext(StyleContext)
    const { setAuthAdmin, Erro, Success } = useContext(AdminContext)

    const nome = Cookies.get("Username")
    const cargo = Cookies.get("Role")
    const [theme, setTheme] = useState(localStorage.getItem('theme') || 'light')

    const toggleTheme = () => {
        if (theme === "light") {
            localStorage.setItem('theme', 'dark');
            setTheme('dark');
        } else {
            localStorage.setItem('theme', 'light');
            setTheme('light');
        }
    };

    const sair = () => {
        Cookies.remove("TokenAdmin")
        Cookies.remove("Key")
        setAuthAdmin(false);
        navigate('/');
    }

    function obterIniciais(nome) {
        const palavras = nome.split(' ');

        let iniciais = palavras[0].charAt(0);
        if (palavras.length > 1) {
            iniciais += palavras[1].charAt(0);
        }

        return iniciais;
    }

    return (
        <>
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Erro &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Erro}
                </div>
            }
            <ThemeProvider theme={theme === "light" ? darkTheme : ligthTheme}>
                <GlobalStyled />
                <div style={header}>
                    <div style={{ display: 'flex', alignItems: 'baseline', marginTop: '12px' }}>
                        <Link to="/">
                            <img className='logoMenu' src={`${logoBranca}`} alt="Logo" />
                        </Link>
                    </div>
                    <div className='options-header'>
                        <Toggle toggleTheme={toggleTheme} />
                        <Dropdown>
                            <Dropdown.Toggle style={{ backgroundColor: "transparent", border: "none", padding: 0 }}>
                                <div className='usuario'>{obterIniciais(nome)}</div>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item>
                                    <div className='content-dropdown'>
                                        <div className='usuario'>{obterIniciais(nome)}</div>
                                        <div className='options-dropdown'>
                                            <span className='label-name'>{nome}</span>
                                            <span className='label-charge'>{cargo}</span>
                                        </div>
                                    </div>
                                </Dropdown.Item>
                                <hr />
                                <Dropdown.Item onClick={() => sair()}>
                                    <button className="btn-logout" ><IoLogOutOutline className='icon-logout' />Sair</button>
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </ThemeProvider>
        </>
    )
}

export default HeaderAdmin