import '../../App.css';
import React, { useContext, useEffect } from 'react';
import { ApiContext } from "../../context/ApiContext";
import Header from '../../components/header/Header';
import MenuLateral from '../../components/menu/MenuLateral'
import Footer from '../../components/footer/Footer'
import { ColumnDirective, ColumnsDirective, GridComponent, Inject, Page, Toolbar, Sort } from '@syncfusion/ej2-react-grids';
import { L10n } from '@syncfusion/ej2-base';
import { FaPlus } from 'react-icons/fa'
import { IoIosWarning, IoIosCheckmarkCircle } from "react-icons/io";
import { TbPencil, TbTrash } from "react-icons/tb";
import { StyleContext } from '../../context/StyleContext';
import ModalCadastrarPessoa from '../../components/modal/ModalCadastrarPessoa';
import ModalEditarPessoa from '../../components/modal/ModalEditarPessoa';
import ModalExcluirPessoa from '../../components/modal/ModalExcluirPessoa';

function Pessoa() {
    const { Success, Error, openModal, setOpenModal, Pessoas, ListaPessoa, loading, modalEditarPessoa, setModalEditarPessoa, setEdicaoPessoa, setPessoaAtualKey, modalExcluirPessoa, setModalExcluirPessoa } = useContext(ApiContext)
    const { h3Demanda, btnCriar, corTerciaria } = useContext(StyleContext)
    const toolbarOptions = ['Search'];

    L10n.load({
        'pt-BR': {
          grid: {
            EmptyRecord: 'Nenhum registro encontrado',
          },
        },
      });

    const EditPessoa = (props) => {
        setModalEditarPessoa(true)
        setPessoaAtualKey(props.chave)
        setEdicaoPessoa(props)
    }

    const DeletarPessoa = (props) => {
        setPessoaAtualKey(props.chave)
        setModalExcluirPessoa(true)
    }

    const gridTemplate = (props) => {
        return (<tr className='tr-ciclo'>
            <td className="td-ciclo">{props.nome}</td>
            <td className="td-ciclo">{props.cargo.descricao}</td>
            <td className='td-ciclo'>
                <button onClick={() => EditPessoa(props)} className='btn-dem' style={{ color: `${corTerciaria}` }} ><TbPencil /></button>
                <button onClick={() => DeletarPessoa(props)} className='btn-dem' style={{ color: `${corTerciaria}` }} ><TbTrash /></button>
            </td>
        </tr>);
    };

    useEffect(() => {
        ListaPessoa("")
    }, [])

    return (
        <div>
            <Header pagina={'Cadastros'} subpagina={'Cadastrar pessoas'}></Header>
            <MenuLateral></MenuLateral>
            {modalEditarPessoa &&
                <ModalEditarPessoa />
            }
            {modalExcluirPessoa &&
                <ModalExcluirPessoa />
            }
            {Success &&
                <div className='success'>
                    <IoIosCheckmarkCircle className='error-icon' />{Success}
                </div>
            }
            {Error &&
                <div className='error'>
                    <IoIosWarning className='error-icon' />{Error}
                </div>
            }
            <div className='container-principal'>
                <div className='demanda-header'>
                    {openModal &&
                        <ModalCadastrarPessoa closeModal={setOpenModal} />
                    }
                    <button className='btn-dash btn-criar' style={btnCriar} onClick={() => setOpenModal(true)}><FaPlus style={{ marginRight: "10px" }} />Cadastrar pessoa</button>
                </div>
                {loading ? (
                    <div className="loading-screen">
                        <div className="loader-container">
                            <div className="spinner"></div>
                        </div>
                    </div>
                ) : (
                    <div className='card card-table'>
                        <h3 className='h3-demanda' style={h3Demanda}>Pessoas cadastradas</h3>
                        <GridComponent locale='pt-BR' id="user" dataSource={Pessoas} rowTemplate={gridTemplate} toolbar={toolbarOptions} allowSorting={true} allowPaging={true} pageSettings={{ pageSize: 10, pageCount: 5 }}>
                            <ColumnsDirective>
                                <ColumnDirective field='nome' headerText='Nome' />
                                <ColumnDirective field='descricao' headerText='Cargo' />
                                <ColumnDirective headerText='Opções' />
                            </ColumnsDirective>
                            <Inject services={[Page, Sort, Toolbar]} />
                        </GridComponent>
                    </div>
                )}
            </div>
            <Footer></Footer>
        </div>
    );
}

export default Pessoa;